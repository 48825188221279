import React, { useState, useContext, useEffect } from "react";
// import Spinner from "react-bootstrap/Spinner";
import AppContextComponent from "./AppContext";
import { useTranslation, Trans } from "react-i18next";
import DOTS_IMG from "./../assets/img/anim_dots.gif";

const Mask = () => {
  const { t } = useTranslation();
  let [additionalClasses, setAdditionalClasses] = useState("");
  let [loadingText, setLoadingText] = useState("Processing");
  const { loadingState } = useContext(AppContextComponent);
  useEffect(() => {
    let text = loadingState?.text ?? t("processing");
    loadingState?.applyMask && setLoadingText(text);
    setAdditionalClasses(loadingState?.applyMask ? "" : "d-none");
  }, [loadingState]);
  return (
    <div className={`certif-global-mask ${additionalClasses}`}>
      <div className="visually-hidden1 certif-global-mask-loading-text">
        <span className="certif-global-mask-loading-text-message">
          <img src={DOTS_IMG} alt="Loading" />
          {loadingText}...
        </span>
      </div>
    </div>
  );
};

export default Mask;
