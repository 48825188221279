import { useState, useEffect, useRef, useContext } from "react";
import { useTranslation, Trans } from "react-i18next";

import { Modal } from "react-bootstrap";

import { Editor } from "primereact/editor";
import "primereact/resources/primereact.min.css";

// import { Editor } from "@tinymce/tinymce-react";
import Utils from "../../../controls/Utils";
import Error from "../../layout/body/Error";
import AppContextComponent from "../../../controls/AppContext";
import CertifAlert from "../../../controls/CertifAlert4AdminScreens";

function AddEditLookup({ props }) {
    let REQ_FIELDS = [
        "lookupName", //
        "value", //
        "valueInEnglish", //
        "language", //
    ];
    const fields4ErrorTemplate = {
        lookupName: "Lookup Name",
        value: "Lookup Value",
        valueInEnglish: "Value In English",
        language: "Language",
    };

    // console.log(props);
    let { setLoadingState, setPageInfo, setCanRedirectToLogin, setCanRedirectToError } = useContext(AppContextComponent);
    let { t } = useTranslation();
    let [isSuperAdmin, setIsSuperAdmin] = useState(Utils.isSuperAdminAccess());

    let [canRender, setCanRender] = useState(true);
    let [pageMode, setPageMode] = useState(props.mode);
    let [canContinue, setCanContinue] = useState(false);
    let [errorMessageKey, setErrorMessageKey] = useState("");
    let [errorFields, setErrorFields] = useState({});
    let [showModal, setShowModal] = useState(false);
    let [errorMessage, setErrorMessage] = useState('');
    let [modalErrorType, setModalErrorType] = useState('');
    let [pageModeStatus, setPageModeStatus] = useState(false);
    const anyCallback = () => {
        setLoadingState({
            applyMask: false,
        });
    };

    let formHeading = props.mode === "add" ? "Add Label" : "Edit Label";

    let deflookupName =
        props.mode === "add" ? "" : props?.templates?.[props.recordIndex]?.["lookupName"] || "";

    let [lookupName, setLookupName] = useState(deflookupName);

    let defvalue =
        props.mode === "add"
            ? ""
            : props?.templates?.[props.recordIndex]?.["value"] || "";

    let [value, setValue] = useState(
        defvalue,
    );

    let defvalueInEnglish =
        props.mode === "add"
            ? ""
            : props?.templates?.[props.recordIndex]?.["valueInEnglish"] || "";

    let [valueInEnglish, setvalueInEnglish] = useState(
        defvalueInEnglish,
    );

    let defCascadingOn =
        props.mode === "add"
            ? ""
            : props?.templates?.[props.recordIndex]?.["cascadingOn"] || "";

    let [cascadingOn, setCascadingOn] = useState(
        defCascadingOn,
    );

    let deflanguage =
        props.mode === "add"
            ? ""
            : props?.templates?.[props.recordIndex]?.["language"] || "";

    let [language, setLanguage] = useState(
        deflanguage,
    );

    const languageOptions = [
        {
            value: "",
            displayName: "Select",
        },
        {
            value: "en",
            displayName: "English",
        },
        {
            value: "fr",
            displayName: "French",
        },
    ];


    const executeCancel = (options = {}) => {
        props.setPageMode("list");
    };

    const submitRecord = () => {
        let mode = pageMode;
        setLoadingState({
            applyMask: true,
        });
        let tempTemplates = [...props.templates];
        let record = {};

        props.fields.forEach((field, index) => {
            let fieldEle = document.getElementById(field);
            let fieldValue = fieldEle ? fieldEle.value.trim() : '';
            record[field] = fieldValue;
        });

        console.log("New record");
        console.log(record);

        const success = (res) => {
            console.log(tempTemplates);
            console.log(res);

            mode === "add" && tempTemplates.push(res.data);
            mode === "edit" && (tempTemplates[props.recordIndex] = res.data);

            props.setTemplates(tempTemplates);
            setPageModeStatus(true);
            let statusMessage = res.data !== [] ? Utils.USER_INTERACTION_MESSAGES_4_ADMIN_SCREENS.save : "Ok";
            mode === "add" && (statusMessage = Utils.USER_INTERACTION_MESSAGES_4_ADMIN_SCREENS.save);
            mode === "edit" && (statusMessage = Utils.USER_INTERACTION_MESSAGES_4_ADMIN_SCREENS.update);
            setErrorMessage(statusMessage);
            setLoadingState({
                applyMask: false,
            });
            setModalErrorType("info");
            setShowModal(true);

            // props.setPageMode("list");
        };

        const fail = ({ error }) => {
            console.log(error);
            // cost errCode = error.error.
            let errCode = error?.response?.status;
            if (errCode === 400) {
                const errorMessage = error.response ? error.response.data.message :
                    Utils.USER_INTERACTION_MESSAGES_4_ADMIN_SCREENS.errorAlt;
                const updatedErrorMessage = errorMessage.slice(7);
                console.log(typeof (updatedErrorMessage));
                console.log(updatedErrorMessage);
                setErrorMessage(updatedErrorMessage);
                setPageModeStatus(false);
                setLoadingState({
                    applyMask: false,
                });
                // Display the error modal
                setModalErrorType('error');
                // setShowErrorModal(true);
                setShowModal(true);
            } else {
                console.log(error);
                Utils.handleDefaultError({
                    error,
                    setLoadingState,
                    setCanRedirectToLogin,
                    setCanRedirectToError,
                    setPageInfo,
                });
            }
        };

        mode === "add" && Utils.addLookup(record).then(success, fail);
        mode === "edit" &&
            Utils.editLookup(
                record,
                props.templates[props.recordIndex].id,
            ).then(success, fail);
    };

    const validateForm = () => {
        let tempErrorFields = {};

        REQ_FIELDS.forEach((fieldId) => {
            let field = document.getElementById(fieldId);
            if (field) {
                let fieldVal =
                    field.type === "checkbox" ? field.checked : field?.value?.trim();
                if (fieldVal.length === 0) {
                    if (!tempErrorFields[fieldId]) {
                        // tempErrorFields[fieldId] = errorMessages[fieldId][0];
                        tempErrorFields[fieldId] = Utils.ADMIN_SECTIONS_ERROR_TEMPLATE.replace('{}', fields4ErrorTemplate[fieldId]);
                    }
                }
            }
        });
        setErrorFields(tempErrorFields);
        return tempErrorFields;

    }

    const executeSave = (options = {}) => {
        let detailsObj = validateForm();
        // console.log(detailsObj);
        if (Utils.isObjectEmpty(detailsObj)) {
            submitRecord();
        }
    };

    const executeUpdate = (options = {}) => {
        let detailsObj = validateForm();
        // console.log(detailsObj);
        if (Utils.isObjectEmpty(detailsObj)) {
            submitRecord();
        }
    };

    const initiateDelete = (options = {}) => {
        setShowAlert(true);
    };

    let [showAlert, setShowAlert] = useState(false);

    const handleClose = (e) => {
        setShowAlert(false);
    };

    const executeDelete = (e) => {
        setLoadingState({
            applyMask: true,
        });
        let tempTemplates = [...props.templates];

        const delSuccess = (res) => {
            setPageModeStatus(false);
            tempTemplates.splice(props.recordIndex, 1);
            const statusMessage = res.data !== [] ? Utils.USER_INTERACTION_MESSAGES_4_ADMIN_SCREENS.delete : "Ok";
            setErrorMessage(statusMessage);
            setModalErrorType('info');
            // setShowErrorModal(true);
            setShowModal(true);
            setPageModeStatus(true);
            props.setTemplates(tempTemplates);
            setLoadingState({
                applyMask: false,
            });
            setShowAlert(false);
            // props.setPageMode("list");
        };

        const delFail = ({ error }) => {
            console.log(error);
            Utils.handleDefaultError({
                error,
                setLoadingState,
                setCanRedirectToLogin,
                setCanRedirectToError,
                setPageInfo,
            });
        };

        let recordIdToBeDelete = props.templates[props.recordIndex].id;
        // console.log(recordIdToBeDelete);
        Utils.deleteLookup(recordIdToBeDelete).then(delSuccess, delFail);
    };

    return (
        <>
            <Modal centered show={showAlert} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete confirmation?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>Are you sure to delete this item?</div>
                </Modal.Body>
                <Modal.Footer>
                    <div>
                        <div className="d-flex certif-delete-confirmation">
                            <button onClick={handleClose} className="btn btn-primary btn-sm">
                                No
                            </button>
                            <button
                                onClick={executeDelete}
                                className="btn btn-primary btn-sm"
                            >
                                Yes
                            </button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
            <CertifAlert
                props={{
                    showModal,
                    setShowModal,
                    modalType: modalErrorType,
                    bodyContent: errorMessage,
                    backdrop: "static",
                    keyboard: false,
                    pageModeStatus,
                    setPageModeProp: props.setPageMode,
                }}
            />
            {canRender && (
                <div>
                    <Error props={{ errorMessageKey }} />

                    <div className="table-responsive">
                        <table className="table table-borderless">
                            <thead className="certif-table-header">
                                <tr>
                                    <th className="certif-table-header-label">{formHeading}</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody className="certif-table-body">
                                <tr className="col-lg-12 col-md-12 col-sm-12">
                                    <td className="col-lg-6 col-md-6 col-sm-6">
                                        <label>
                                            Lookup Name
                                            <span className="certif-required-indicator">*</span>
                                        </label>
                                        <div>
                                            <input type="text" defaultValue={lookupName} id={"lookupName"} />
                                            {errorFields.lookupName && (<p className="error-message" style={{ color: "red" }}>
                                                {errorFields.lookupName}
                                            </p>)}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="2">
                                        <label>
                                            Lookup Value
                                            <span className="certif-required-indicator">*</span>
                                        </label>
                                        <div>
                                            <input type="text" defaultValue={value} id={"value"} />
                                            {errorFields.value && (<p className="error-message" style={{ color: "red" }}>
                                                {errorFields.value}
                                            </p>)}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="2">
                                        <label>
                                            Value In English
                                            <span className="certif-required-indicator">*</span>
                                        </label>
                                        <div>
                                            <input
                                                id="valueInEnglish"
                                                type="text"
                                                defaultValue={valueInEnglish}
                                                style={{ width: '100%' }}
                                            // className="certif-input-field certif-required-field"
                                            // onChange={emailSubjectHandler}
                                            />
                                            {errorFields.valueInEnglish && (<p className="error-message" style={{ color: "red" }}>
                                                {errorFields.valueInEnglish}
                                            </p>)}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="2">
                                        <label>
                                            Cascading On
                                            {/* <span className="certif-required-indicator">*</span> */}
                                        </label>
                                        <div>
                                            <input
                                                id="cascadingOn"
                                                type="text"
                                                defaultValue={cascadingOn}
                                                className="certif-input-field certif-required-field"
                                            // onChange={emailSubjectHandler}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="2">
                                        <label>
                                            Language
                                            <span className="certif-required-indicator">*</span>
                                        </label>
                                        <div>
                                            <select
                                                // onChange={onChangeLanguage}
                                                defaultValue={language}
                                                id={"language"}
                                            >
                                                {languageOptions?.map((lang, index) => (
                                                    <option value={lang.value} key={index}>
                                                        {lang.displayName}
                                                    </option>
                                                ))}
                                            </select>
                                            {errorFields.language && (<p className="error-message" style={{ color: "red" }}>
                                                {errorFields.language}
                                            </p>)}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot className="certif-table-footer">
                                <tr>
                                    <th>
                                        <div className="d-flex">
                                            {isSuperAdmin && props.mode === "edit" && (
                                                <button
                                                    className="btn btn-primary btn-sm"
                                                    onClick={initiateDelete}
                                                >
                                                    Delete
                                                </button>
                                            )}
                                        </div>
                                    </th>
                                    <th></th>
                                    <th>
                                        <div className="d-flex">
                                            <button
                                                className="btn btn-primary btn-sm"
                                                onClick={executeCancel}
                                            >
                                                {t("Cancel")}
                                            </button>

                                            {props.mode === "edit" && (
                                                <button
                                                    className="btn btn-primary btn-sm"
                                                    onClick={executeUpdate}
                                                >
                                                    Update
                                                </button>
                                            )}

                                            {props.mode === "add" && (
                                                <button
                                                    className="btn btn-primary btn-sm"
                                                    onClick={executeSave}
                                                >
                                                    Save
                                                </button>
                                            )}
                                        </div>
                                    </th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            )}
        </>
    );
}

export default AddEditLookup;
