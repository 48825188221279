import { useContext, useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import Utils from "../../../controls/Utils";

import ErrorImg from "./../../../assets/img/errorcross.png";

function ErrorMessage({ props = {} }) {
    let [clsName, setClsName] = useState("d-none");
    let [errorMessageKey, setErrorMessageKey] = useState("requiredWarning");
    let [errMsgArray, setErrMsgArray] = useState([]);
    let { t } = useTranslation();

    useEffect(() => {
        // console.log(props?.errorMessageKey);
        if (props?.errorMessageKey?.length) {
            setClsName("");
            setErrorMessageKey(props?.errorMessageKey);
        } else {
            setClsName("d-none");
            setErrorMessageKey("");
        }
    }, [props?.errorMessageKey]);

    useEffect(() => {
        // console.log(props?.errorMessageArray);
        if (props?.errorMessageArray?.length) {
            setClsName("");
            setErrMsgArray(props?.errorMessageArray);
        } else {
            setClsName("d-none");
            setErrMsgArray([]);
        }
    }, [props?.errorMessageArray]);

    return (
        <>
            <div className={`certif-error-wrapper d-flex ${clsName}`}>
                <div className="certif-error d-flex">
                    <img src={ErrorImg} alt="Error" />
                    <span className="certif-error-message">
                        {errorMessageKey}
                        {errMsgArray?.map((err, index) => (
                            <p key={index}>{err}</p>
                        ))}
                    </span>
                </div>
            </div>
        </>
    );
}

export default ErrorMessage;