import React, { useContext, useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import Utils from "../../../controls/Utils";

import ErrorImg from "./../../../assets/img/errorcross.png";

function Error({ props = {} }) {
  let [clsName, setClsName] = useState("d-none");
  let [errorMessageKey, setErrorMessageKey] = useState("requiredWarning");
  let [errMsgArray, setErrMsgArray] = useState([]);
  let [messages, setMessages] = useState({});
  let [errorTypeToFieldsMapping, setErrorTypeToFieldsMapping] = useState({});
  let [locLanguage, setLocLanguage] = useState('en');
  let { t } = useTranslation();

  useEffect(() => {
    // console.log(props?.errorMessageKey);
    if (props?.errorMessageKey?.length) {
      setClsName("");
      setErrorMessageKey(props?.errorMessageKey);
    } else {
      setClsName("d-none");
      setErrorMessageKey("");
    }
  }, [props?.errorMessageKey]);

  useEffect(() => {
    // console.log(props?.errorMessageArray);
    if (props?.errorMessageArray?.length) {
      setClsName("");
      setErrMsgArray(props?.errorMessageArray);
    } else {
      setClsName("d-none");
      setErrMsgArray([]);
    }
    // let session = Utils.getSession();
    // let messages = session.messages;
    // setMessages(messages);
    // setErrorTypeToFieldsMapping(Utils.ERROR_TYPE_TO_FIELDS_MAPPING);
    // setLocLanguage(Utils.getLocale());
  }, [props?.errorMessageArray]);

  useEffect(() => {
    let session = Utils.getSession();
    let messages = session.messages;
    setMessages(messages);
    setErrorTypeToFieldsMapping(Utils.ERROR_TYPE_TO_FIELDS_MAPPING);
    // setLocLanguage(Utils.getLocale());
  }, []);

  // const errorTypeToFieldsMapping = {
  //   empty: 'EMPTY_ERROR_FIELDS',
  //   invalidFormat: 'NON_EMPTY_ERROR_FIELDS',
  //   // ... add more mappings as needed
  // };

  // const userFriendlyMessages = {
  //   empty: `The field ${fieldId} should not be empty`,
  //   nonEmpty: `The field ${fieldId} should be filled`,
  //   // ... add more messages as needed
  // };

  const renderErrorMessages = (fieldId, errorType, typeIndex) => {
    // const errorMapping = errorType === 'empty' ? Utils.EMPTY_ERROR_FIELDS : Utils.NON_EMPTY_ERROR_FIELDS;
    const errorMapping = errorTypeToFieldsMapping[errorType]; // picking the respective error array based on it's type
    const messageCode = Utils[errorMapping][fieldId]; // based on the fieldId get the message code
    // ------------------- later purpose Code Start----------------- //
    // const messageCode = errorMapping ? errorMapping[fieldId] : null; // Incase if code is not found
    // let errorMessage;
    // if (messageCode) {
    //   errorMessage = messages[messageCode][selectedLanguage];
    // } else {
    //   // Use user-friendly message for unspecified error mappings
    //   errorMessage = userFriendlyMessages[errorType].replace('${fieldId}', fieldId);
    // }
    // ------------------- later purpose Code End ----------------- //
    let errorMessage = messages[messageCode][Utils.getLocale()]; //base on message code and locale get the error message

    return (
      <div className="d-flex" key={`${fieldId}-${errorType}-${typeIndex}`}>
        <img src={ErrorImg} alt="Error" />
        <p>{errorMessage}</p>
      </div>
    );
  };

  return (
    <>
      <div className={`certif-error-wrapper d-flex ${clsName}`}>
        <div className="certif-error d-flex">
          {/* <img src={ErrorImg} alt="Error" /> */}
          <span className="certif-error-message">
            {/* {t(errorMessageKey)}
            {errMsgArray?.map((err, index) => (
              <div className="d-flex" key={index}>
                <img src={ErrorImg} alt="Error" />
                <p key={index}>{err}</p>
              </div>
            ))} */}
            {errMsgArray?.map((errorField, index) =>
              errorField.errorTypes.map((errorType, typeIndex) => (
                renderErrorMessages(errorField.fieldId, errorType, typeIndex)
              ))
            )}
          </span>
        </div>
      </div>
    </>
  );
}

export default Error;
