import { useState, useEffect, useContext } from "react";
import { Routes, Route } from "react-router-dom";

import Utils from "../../../controls/Utils";
import MessagesPage from "../Messages/MessagesPage";
import ErrorPage from "../../utilpages/ErrorPage";

function AdminSection6Body() {
  let [canRender, setCanRender] = useState(false);

  useEffect(() => {
    setCanRender(true);
  }, []);

  return (
    <>
      {canRender && (
        <Routes>
          <Route path={`/`} exact element={<MessagesPage />}></Route>
          <Route
            path={Utils.APP_URLS.MESSAGE.replace(
              Utils.APP_URLS.ADMIN_PAGE_SECTION_6,
              "",
            )}
            element={<MessagesPage />}
          ></Route>
          <Route
            path={`*`}
            element={<ErrorPage props={{ error: 404 }} />}
          ></Route>
        </Routes>
      )}
    </>
  );
}

export default AdminSection6Body;
