import axios from "axios";
import Dropdown from "react-bootstrap/Dropdown";

import { useNavigate } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";

import APP_CONFIG from "../../../config/app.config";

import { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";

import AppContextComponent from "../../../controls/AppContext";
import Utils from "../../../controls/Utils";
import LogoImage from "./../../../assets/img/logo.png";
import appConfig from "../../../config/app.config";

function LandingHeader() {
  const { oktaAuth } = useOktaAuth();
  let navigate = useNavigate();
  let { setLoadingState, setPageInfo, readyToRender, setReadyToRender } =
    useContext(AppContextComponent);

  let [userName, setUserName] = useState("");
  let [showUserMenu, setShowUserMenu] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    let oktaAccessToken = Utils.getToken();
    if (oktaAccessToken && !Utils.isGuestAccess()) {
      // let url = APP_CONFIG.oidc.issuer + "/v1/userinfo";
      // axios({
      //   method: "post",
      //   url: url,
      //   headers: {
      //     // "Content-Type": "application/x-www-form-urlencoded"
      //     Authorization: `${oktaAccessToken}`,
      //   },
      // }).then(
      //   (res) => {
      //     let userInfo = res.data;
      //     // console.log(userInfo);
      //     let finalObj = {
      //       userInfo,
      //     };
      //     Utils.store(finalObj, setPageInfo);

      //     setShowUserMenu(true);
      //     setUserName(userInfo.name);
      //     // debugger;
      //   },
      //   (err) => {
      //     console.log(err);
      //     Utils.deleteSession();
      //     navigate(Utils.APP_URLS.LOGIN_PAGE);
      //   },
      // );

      let url = Utils.LIVE_REST_API.USER_INFO;
      axios({
        method: "GET",
        url: url,
        headers: {
          // "Content-Type": "application/x-www-form-urlencoded"
          Authorization: `${oktaAccessToken}`,
        },
      }).then(
        (res) => {
          let userInfo = res.data;
          // console.log(userInfo);
          let finalObj = {
            userInfo,
          };
          Utils.store(finalObj, setPageInfo);

          setShowUserMenu(true);
          setUserName(userInfo.name);
          setReadyToRender(readyToRender + 1);
        },
        (err) => {
          console.log(err);
          Utils.deleteSession();
          setReadyToRender(readyToRender + 1);
          navigate(Utils.APP_URLS.LOGIN_PAGE);
        },
      );
    } else {
      setReadyToRender(readyToRender + 1);
    }
  }, []);

  const doEditProfile = (e) => {
    e.preventDefault();
    let editUrl = appConfig.oidc.issuerUserSettings;
    window.open(editUrl, "_blank", "noreferrer");
    // navigate(editUrl);
  };

  const doLogout = (e) => {
    setLoadingState({
      applyMask: true,
    });
    // e.preventDefault();
    // let commonFn = (data) => {
    let commonFn = (data) => {
      Utils.deleteSession();
      setLoadingState({
        applyMask: true,
      });
      navigate(Utils.APP_URLS.LANDING_PAGE);
    };

    // oktaAuth.signOut().then(commonFn, commonFn);
    commonFn();
  };

  const gotoInternal = (e) => {
    navigate(Utils.APP_URLS.ADMIN_PAGE);
  };

  return (
    <div className="certif-header-wrapper">
      <div className="container d-flex flex-row">
        <div className="w-50 d-flex certif-header-section certif-header-left-section">
          <div className="certif-header-logo-wrapper">
            <div className="certif-header-logo">
              <img src={LogoImage} alt="logoimage" />
            </div>
          </div>
          <div className="certif-header-title-wrapper">
            <div className="certif-header-title">{t("headerTitle")}</div>
            <div className="certif-header-sub-title">{t("headerSubTitle")}</div>
          </div>
        </div>
        <div className="w-50 d-flex certif-header-section certif-header-right-section">
          {showUserMenu && (
            // <div className="certif-header-user-wrapper">
            //   <div className="certif-header-username d-flex">
            //     {userName}
            //     <div className="certif-header-user-menu"></div>
            //     <div className="certif-header-user-menu-dropdown">
            //       <div className="certif-header-user-menu-dropdown-item">
            //         Profile setting
            //       </div>

            //       <div className="certif-header-user-menu-dropdown-item">
            //         Signout
            //       </div>
            //     </div>
            //   </div>
            // </div>

            <div className="indi-user-profile-wrapper">
              <Dropdown align="end">
                <Dropdown.Toggle
                  id="dropdown-basic"
                  type="link"
                  className="indi-header-userprofile-btn"
                >
                  <div className="indi-header-user-profile-firstname">
                    {userName}
                    <div className="certif-header-user-menu"></div>
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {/* <Dropdown.Item
                    key="editProfile"
                    href="/settings"
                    onClick={doEditProfile}
                  >
                    Edit profile
                  </Dropdown.Item> */}
                  <Dropdown.Item key="logOut" href="/logout" onClick={doLogout}>
                    Log out
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
          {/* {!showUserMenu && (
            <>
              <div
                className="certif-header-login-hidden-link"
                onClick={gotoInternal}
              >
                login
              </div>
            </>
          )} */}
        </div>
      </div>
    </div>
  );
}

export default LandingHeader;
