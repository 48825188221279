// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.certif-terms-top {
  height: calc(100vh - 80px);
  padding: 14px 0;
  overflow: auto;
}

.certif-terms {
  width: 1000px;
  margin: 0 auto !important;
}
.certif-terms p {
  margin: 0px 0px 10px 0px;
  line-height: 1.6;
  padding: 4px 0;
  font-size: 12px;
  text-align: justify;
}

.certif-terms ul li {
  line-height: 1.6;
  padding: 4px 0;
  font-size: 12px;
  text-align: justify;
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/terms.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,yBAAyB;AAC3B;AACA;EACE,wBAAwB;EACxB,gBAAgB;EAChB,cAAc;EACd,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,cAAc;EACd,eAAe;EACf,mBAAmB;AACrB","sourcesContent":[".certif-terms-top {\n  height: calc(100vh - 80px);\n  padding: 14px 0;\n  overflow: auto;\n}\n\n.certif-terms {\n  width: 1000px;\n  margin: 0 auto !important;\n}\n.certif-terms p {\n  margin: 0px 0px 10px 0px;\n  line-height: 1.6;\n  padding: 4px 0;\n  font-size: 12px;\n  text-align: justify;\n}\n\n.certif-terms ul li {\n  line-height: 1.6;\n  padding: 4px 0;\n  font-size: 12px;\n  text-align: justify;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
