import { useState, useEffect, useRef, useContext } from "react";

import Utils from "../../../controls/Utils";
import AppContextComponent from "../../../controls/AppContext";
import NavEventMixin from "../../utilpages/NavEventMixin";

import MarketingContentList from "./MarketingContentList";
import AddEditMarketingContent from "./AddEditMarketingContent";

function MarketingContentPage() {
    let { setLoadingState, setPageInfo, setCanRedirectToLogin, setCanRedirectToError } = useContext(AppContextComponent);

    let [canRender, setCanRender] = useState(false);
    let [pageMode, setPageMode] = useState("list");
    let [languagesObj, setLanguagesObj] = useState({});
    let [languages, setLanguages] = useState([]);
    let [templates, setTemplates] = useState([]);
    let [fields, setFields] = useState([
        "language",
        "sellingOfficeId",
        "marketingContent",
    ]);
    let [recordIndex, setRecordIndex] = useState(-1);

    let tempNoOfRecords = 7;

    useEffect(() => {
        setLoadingState({
            applyMask: true,
        });
        const fetchSuccess = (res) => {
            let tempTemplates = [...res.data];
            setTemplates(tempTemplates);
            setCanRender(true);
            setLoadingState({
                applyMask: false,
            });
        };

        const fetchFail = ({ error }) => {
            console.log(error);
            Utils.handleDefaultError({
                error,
                setLoadingState,
                setCanRedirectToLogin,
                setCanRedirectToError,
                setPageInfo,
            });
        };

        Utils.fetchData("SELLING_OFFICE_MARKETING_CONTENT").then(fetchSuccess, fetchFail);
    }, []);

    useEffect(() => { }, [fields, templates, pageMode]);

    return (
        <>
            {canRender && (
                <div className="table-responsive">
                    {(pageMode === "add" || pageMode === "edit") && (
                        <NavEventMixin
                            props={{
                                pageMode,
                                setPageMode,
                            }}
                        />
                    )}
                    {pageMode === "list" && (
                        <MarketingContentList
                            props={{
                                fields,
                                templates,
                                setPageMode,
                                recordIndex,
                                setRecordIndex,
                            }}
                        />
                    )}
                    {pageMode === "add" && (
                        <AddEditMarketingContent
                            props={{
                                fields,
                                templates,
                                setTemplates,
                                setPageMode,
                                mode: "add",
                                tempNoOfRecords,
                            }}
                        />
                    )}
                    {pageMode === "edit" && (
                        <AddEditMarketingContent
                            props={{
                                fields,
                                templates,
                                setTemplates,
                                setPageMode,
                                recordIndex,
                                tempNoOfRecords,
                                setRecordIndex,
                                mode: "edit",
                            }}
                        />
                    )}
                </div>
            )}
        </>
    );
}

export default MarketingContentPage;
