import "./../assets/css/header.css";
import "./../assets/css/footer.css";
import "./../assets/css/terms.css";
import Header from "./layout/header/Header";
import Footer from "./layout/footer/Footer";

function TermsAndConditionsPage() {
  return (
    <div className="certif-domain container-fluid p-0">
      <div className="certif-terms">
        <p>
          International SOS ("Intl.SOS") provides a certain service, which may
          be a web-based service ("Web Service"). These Web Services are
          provided as a courtesy to and may only be used by eligible Intl.SOS
          Members as defined below. Should you have any concerns or questions
          about your use of the Web Service you may contact International SOS at
          the following e-mail address: CertificatesofAssistance@internationalsos.com
        </p>
        <p>
          <strong>1. </strong>
          <strong>Definitions</strong>
        </p>
        <p>
          The terms and conditions defined below shall have the following
          meaning between the parties:
        </p>
        <p>
          "Agreement" shall mean the Membership Agreement entered into by
          Intl.SOS and its Client to render assistance, travel security and/or
          information services.
        </p>
        <p>
          "Client" shall mean your employer or other such organization with whom
          Intl.SOS has entered into a Membership Agreement, and shall have the
          same meaning as "Subscriber" under the terms of such Membership
          Agreement.
        </p>
        <p>
          "Member" shall mean such Client's declared member pursuant to the
          terms and conditions of the Agreement and any additional such
          agreements as may govern the employment relationship between the
          Member and our Client.
        </p>
        <p>
          "Program Number": means the program number assigned by Intl.SOS to its
          Client. Such program number is typically located on the Certificate of
          Subscription, attached to the Agreement and the personal membership
          card granted to the Member by the Client.
        </p>
        <p>
          "Web Service": means the Intl.SOS designed and developed website,
          which allows Members to request a Certificate .This website may be
          accessed through the following URL: https://certificates.internationalsos.com.
        </p>
        <p>
          <strong>2. </strong>
          <strong>Acceptance</strong>
        </p>
        <p>
          PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING THIS WEB
          SERVICE.
        </p>
        <p>
          THIS WEB SERVICE IS MADE AVAILABLE TO MEMBERS ON THE BASIS OF THESE
          TERMS AND CONDITIONS. BY ACCESSING, BROWSING OR USING THIS WEB SERVICE
          YOU AGREE TO BE BOUND BY THESE TERMS AND CONDITIONS.
        </p>
        <p>
          Intl.SOS reserves the right, in its discretion to update or revise
          these Terms and conditions and to post such updated or revised Terms
          and Conditions on https://certificates.internationalsos.com. If you do not agree to these
          Terms and Conditions, please do not use this Web Service. Please check
          the Terms and Conditions periodically for changes. Your continued use
          of any Web Service following the posting of any changes to the Terms
          and conditions constitutes acceptance of those changes.
        </p>
        <p>
          <strong>3. </strong>
          <strong>Privacy</strong>
        </p>
        <p>
          To utilize the Web Service you may be providing Intl.SOS with personal
          information. Such personal information is subject to Intl.SOS' Privacy
          Policy, http://www.internationalsos.com/en/privacy.htm. To enable
          Intl.SOS to provide relevant information to you through the Web
          Service, the information that you provide should be true, accurate,
          current and complete. Intl.SOS shall not be required to independently
          verify the representations made by you in your use of the Web Service
          before enforcing any of its rights or your obligations under these
          Terms and Conditions. The computerized records and digital documents
          held in Intl.SOS's computer systems, under reasonable security
          conditions, will serve as proof of the information you have provided
          on the request form. You are responsible for providing, and if
          necessary updating, in a timely manner, your complete, current and
          accurate e-mail and postal address(es) information on the Web Service
          request form.&nbsp;&nbsp;&nbsp; You will be solely liable for any
          direct or indirect consequences of Certificate mailed to an incorrect
          address, if any, or e-mail address, as a result of your not having
          provided Intl.SOS the correct email or postal address information or
          informed Intl.SOS of any changes to said e-mail or postal address.
          Intl.SOS shall not be held liable for the delivery time of any
          Certificate sent by postal service or for the loss of any Certificate
          sent by postal service or courier.{" "}
        </p>
        <p>
          <strong>4. </strong>
          <strong>TERMS OF USE</strong>
        </p>
        <p>
          Intl.SOS hereby grants to Members a non-exclusive, non-transferable,
          and limited license to access and use the Web Service for the sole
          purpose of completing an on-line request form in order to generate a
          document confirming the existence of an Agreement ("Certificate"). You
          agree to use the Certificate for the sole purpose of obtaining
          permission from a visa-granting authority to enter a country other
          than your country of nationality. Your request shall entitle you to
          obtain an electronic version of a Certificate or an original
          Certificate of assistance via postal service or courier. Should you
          request a Certificate for submission to the Embassy of the Russian
          Federation such Certificate will be sent to you in an original form,
          via postal service or courier, at your expense to the postal address
          you provide.{" "}
        </p>
        <p>
          Intl.SOS reserves the right, in its sole discretion, to refuse to
          issue a Certificate or revoke your use of a Certificate or render any
          Certificate invalid for any reason or no reason. Should Intl.SOS
          determine, in its sole discretion, that the Program Number provided
          has been utilized by an unauthorized person, or by a Member to procure
          a Certificate on behalf of an unauthorized person, Intl.SOS reserves
          its right to take any and all necessary actions under law to enforce
          its rights including, but not limited to, informing the relevant
          visa-granting authority of revocation of the Certificate.
        </p>
        <p>
          Your license to use the Web Service shall terminate automatically in
          conjunction with the termination of the Agreement or any agreement
          governing your relationship with our Client which renders you
          ineligible as a Member. - Your possession of an assistance certificate
          produced pursuant to your use of the Web Service is not a guarantee of
          your eligibility for Membership under the Agreement. Such eligibility
          is governed solely by the terms and conditions of the Agreement.
        </p>
        <p>
          Intl.SOS reserves the right to suspend or terminate access to all or
          part of the Web Service, with no prior notice and for any reason or no
          reason, including but not limited to technical, security or legal
          reasons. Should Intl.SOS suspend or terminate access to the Web
          Service Intl.SOS may provide Certificates in response to a valid
          request via e-mail at: AmericasCertificates@internationalsos.com
        </p>
        <p>
          Intl.SOS reserves the right to make changes or upgrades and/or add or
          remove content at any time, if Intl.SOS deems so necessary, to the Web
          Service, the relevant HTML pages, databases, applications, and
          software.
        </p>
        <p>
          Intl.SOS reserves the right at any time and from time to time to
          modify or discontinue, temporarily or permanently, or impose any
          change in fees or charges for use of the Web Service with or without
          notice, or in accordance with the terms of the Agreement. The Web
          service provided by Intl.SOS will generate internet connectivity
          costs. You are responsible for assuming any internet or other
          telecommunications costs associated with accessing and using the Web
          Service.
        </p>
        <p>
          <strong>5.</strong>
          <strong>DISCLAIMER AND LIMITATION OF LIABILITY</strong>
        </p>
        <p>
          There is no guarantee or warranty, express or implied (a) of the
          completeness or accuracy of the data you access, including without
          limitation, implied warranties of satisfactory quality, fitness for a
          particular purpose, and (b) that access will be uninterrupted, timely,
          secure or error free, that defects will be corrected, or that the Web
          Service or the server that makes it available are free of viruses.You
          acknowledge that the Web Service is provided "as is" and "as
          available" for your use. Under no circumstances will Intl.SOS be
          liable for any loss or damage, including, without limitation, special,
          indirect, punitive or consequential loss or damage, loss of profits,
          howsoever arising. You agree that Intl.SOS shall not be responsible
          for unauthorized access to or alteration of your data, or any
          transactions entered into through the Web Service.
        </p>
        <p>You are hereby advised that:</p>
        <ul>
          <li>
            Intl.SOS does not guaranteed continuous access to the Web Service.
            Access may be interrupted for, website maintenance, content
            update, or any other technical difficulties;{" "}
          </li>
          <li>
            Intl.SOS does not guarantee a secure connection to the Web Service
            for the transmission, delivery, and processing of data, including
            transmission of personal information and/or any determination made
            pursuant to the Web Service;{" "}
          </li>
          <li>
            Intl.SOS does not guarantee that data, information and/or any
            other elements accessible through the Web Service will be
            transmitted.
          </li>
          <li>
            Intl.SOS does not guarantee your eligibility for any visa applied
            for with any visa-granting authority pursuant to your use of the
            Web Service, nor that the Certificate will meet the specific
            requirements of any specific visa granting authority, and will not
            be liable for any damages arising from a rejection of your visa
            application including but not limited to a rejection on the basis
            of insufficiency of the Certificate.{" "}
          </li>
        </ul>
        <p>
          <strong>6. SECURITY</strong>
        </p>
        <p>
          You are fully responsible for any information you provide or exchange
          on the Web Service. Therefore, you may not:
        </p>
        <p>- disrupt the use of any Member accessing the Web Service;</p>
        <p>
          - access the Member-Only Site using information of another Member;
        </p>
        <p>
          - undermine Intl.SOS's systems security or interfere with or interrupt
          the normal operations of this Web Service.{" "}
        </p>
        <p>
          Intl.SOS shall undertake reasonable administrative, physical and
          technical security measures to ensure the confidentiality, integrity
          and accessibility of the information collected, stored, transferred or
          disclosed by the Web Service.
        </p>
        <p>
          <strong>7. INTELLECTUAL PROPERTY</strong>
        </p>
        <p>
          The Web Service for issuing certificates of assistance is an original
          work, to which Intl.SOS holds full intellectual property rights.
          Likewise, all elements accessible through the Web Service, including
          but not limited to data, software and databases, belong exclusively to
          Intl.SOS. These elements shall not, under any circumstances, be
          assigned to you.
        </p>
        <p>
          Accordingly, you shall refrain from any use of the Web Service, which
          is not explicitly authorized herein. Any action or act that could
          prejudice, either directly or indirectly, the rights that Intl.SOS
          holds to the Web Service, including but not limited to misuse of or
          unauthorized access to data, databases, trademarks, trade names, logo,
          that appear in said Web Service, is strictly forbidden.
        </p>
        <p>
          <strong>8. </strong>
          <strong>GOVERNING LAW AND DISPUTE RESOLUTION</strong>
        </p>
        <p>
          These Terms and Conditions are governed by the laws of the country
          specified in the Agreement that makes you an eligible user of the Web
          Service.
        </p>
        <p>
          Any dispute arising out of or in connection with these Terms and
          Conditions, including any question regarding its existence, validity
          or termination, shall be referred to and finally resolved pursuant to
          the dispute resolution clause specified in the Agreement. If there is
          no applicable clause or valid Agreement, these Terms and Conditions
          shall be governed by and construed in accordance with the laws of
          England and Wales and any dispute shall be referred to and finally
          resolved by arbitration in England, conducted by the London Court of
          International Arbitration ("LCIA") in accordance with the Rules of the
          LCIA for the time being in force, which is deemed to be incorporated
          by reference in this Clause. The number of arbitrators shall be three,
          with each party appointing an arbitrator and the third arbitrator
          shall be appointed by the two arbitrators appointed by the parties.
          The language to be used in the arbitral proceedings shall be English.
        </p>
      </div>
    </div>
  );
}

export default TermsAndConditionsPage;
