import { useState, useEffect, useRef, useContext } from "react";

import RegionsList from "./RegionList";
import AddEditRegion from "./AddEditRegion";
import Utils from "../../../../controls/Utils";
import AppContextComponent from "../../../../controls/AppContext";
import NavEventMixin from "../../../utilpages/NavEventMixin";

function RegionsDialog({ props }) {
  let ctryCode = props.countryRecord.countryCode;
  let [countryCode, setCountryCode] = useState(ctryCode);
  let [languages, setLanguages] = useState([]);
  let [languagesObj, setLanguagesObj] = useState({});
  let { setLoadingState, setPageInfo, setCanRedirectToLogin, setCanRedirectToError } = useContext(AppContextComponent);

  let [canRender, setCanRender] = useState(false);
  let [pageMode, setPageMode] = useState("list");
  let [regions, setRegions] = useState([]);
  let [fields, setFields] = useState([
    "language",
    "regionCode",
    "regionName",
    "restricted",
    "countryCode",
  ]);
  let [recordIndex, setRecordIndex] = useState(-1);

  useEffect(() => {
    setLoadingState({
      applyMask: true,
    });
    const fail = ({ error }) => {
      console.log(error);
      Utils.handleDefaultError({
        error,
        setLoadingState,
        setCanRedirectToLogin,
        setCanRedirectToError,
        setPageInfo,
      });
    };
    const fetchRegions = () => {
      const regionsSuccess = (res) => {
        setRegions(res.data);
        setCanRender(true);
        setLoadingState({
          applyMask: false,
        });
      };
      Utils.getRegions(ctryCode).then(regionsSuccess, fail);
    };
    const langSuccess = (langs) => {
      setLanguages(langs);
      let langObj = {};
      langs.forEach((lang) => {
        langObj[lang.languageCode] = lang.languageText;
      });
      setLanguagesObj(langObj);
      fetchRegions();
    };
    Utils.getStoredLanguages().then(langSuccess, fail);
  }, []);

  useEffect(() => { }, [fields, regions, pageMode]);

  return (
    <>
      {canRender && (
        <div className="table-responsive">
          {(pageMode === "add" || pageMode === "edit") && (
            <NavEventMixin
              props={{
                pageMode,
                setPageMode,
              }}
            />
          )}
          {pageMode === "list" && (
            <RegionsList
              props={{
                fields,
                regions,
                setPageMode,
                recordIndex,
                countryCode,
                setRecordIndex,
                languagesObj,
              }}
            />
          )}
          {pageMode === "add" && (
            <AddEditRegion
              props={{
                fields,
                regions,
                countryCode,
                setRegions,
                setPageMode,
                mode: "add",
                languagesObj,
              }}
            />
          )}
          {pageMode === "edit" && (
            <AddEditRegion
              props={{
                fields,
                regions,
                setRegions,
                countryCode,
                setPageMode,
                recordIndex,
                setRecordIndex,
                mode: "edit",
                languagesObj,
              }}
            />
          )}
        </div>
      )}
    </>
  );
}

export default RegionsDialog;
