import { useState, useEffect, useRef, useContext } from "react";
import { Editor } from "primereact/editor";
import { useTranslation, Trans } from "react-i18next";

import { Modal } from "react-bootstrap";

import "primereact/resources/primereact.min.css";

// import { Editor } from "@tinymce/tinymce-react";
import Utils from "../../../controls/Utils";
import Error from "../../layout/body/Error";

import AppContextComponent from "../../../controls/AppContext";
import CertifAlert from "../../../controls/CertifAlert4AdminScreens";

function AddEditMarketingContent({ props }) {
    let REQ_FIELDS = [
        "language", //
        "sellingOfficeId", //
        "marketingContent", //
    ];
    const fields4ErrorTemplate = {
        language: "Language",
        sellingOfficeId: "Selling Office Name",
        marketingContent: "Template Content",
    };

    // console.log(props);
    let { setLoadingState, setPageInfo, setCanRedirectToLogin, setCanRedirectToError } = useContext(AppContextComponent);
    let { t } = useTranslation();
    let [isSuperAdmin, setIsSuperAdmin] = useState(Utils.isSuperAdminAccess());

    let [canRender, setCanRender] = useState(false);
    let [pageMode, setPageMode] = useState(props.mode);
    let [canContinue, setCanContinue] = useState(false);
    let [errorMessageKey, setErrorMessageKey] = useState("");
    let [errorFields, setErrorFields] = useState({});
    const editorRef = useRef(null);
    let [sellingOfficeIdOptions, setSellingOfficeIdOptions] = useState([]);
    let [showModal, setShowModal] = useState(false);
    let [errorMessage, setErrorMessage] = useState('');
    let [modalErrorType, setModalErrorType] = useState('');
    let [pageModeStatus, setPageModeStatus] = useState(false);

    useEffect(() => {
        setLoadingState({
            applyMask: true,
        });
        const commonFail = ({ error }) => {
            console.log(error);
            Utils.handleDefaultError({
                error,
                setLoadingState,
                setCanRedirectToLogin,
                setCanRedirectToError,
                setPageInfo,
            });
        };

        const sellingOfficeSuccess = (res) => {
            // console.log("Selling office id", res.data)
            res.data.sort((itemA, itemB) => {
                return itemA.sellingOfficeName.localeCompare(itemB.sellingOfficeName);
            });
            let selectOptions = [
                {
                    sellingOfficeId: "",
                    sellingOfficeName: "Select",
                },
                ...res.data,
            ];
            setSellingOfficeIdOptions(selectOptions);
            setCanRender(true);
            setLoadingState({
                applyMask: false,
            });
        };

        Utils.fetchData("SELLING_OFFICE").then(sellingOfficeSuccess, commonFail);
    }, []);

    const anyCallback = () => {
        setLoadingState({
            applyMask: false,
        });
    };

    let formHeading =
        props.mode === "add" ? "Add marketing content" : "Edit marketing content";

    let defLanguage =
        props.mode === "add" ? "" : props?.templates?.[props.recordIndex]?.["language"] || "";

    let [language, setLanguage] = useState(defLanguage);

    const languageOptions = [
        {
            value: "",
            displayName: "Select",
        },
        {
            value: "en",
            displayName: "English",
        },
        {
            value: "fr",
            displayName: "French",
        },
    ];


    let defMarketingContent =
        props.mode === "add" ? "" : props?.templates?.[props.recordIndex]?.["marketingContent"] || "";

    let [marketingContent, setMarketingContent] = useState(defMarketingContent);

    let defSellingOfficeId =
        props.mode === "add"
            ? ""
            : props?.templates?.[props.recordIndex]?.["sellingOfficeId"] || "";

    let [sellingOfficeId, setSellingOfficeId] = useState(defSellingOfficeId);


    const executeCancel = (options = {}) => {
        props.setPageMode("list");
    };

    const submitRecord = () => {
        let mode = pageMode;
        setLoadingState({
            applyMask: true,
        });
        let tempTemplates = [...props.templates];
        let record = {};

        props.fields.forEach((field, index) => {
            let fieldEle = document.getElementById(field);
            let fieldValue = '';

            if (fieldEle) {
                fieldValue =
                    field.type === "checkbox" ? fieldEle.checked : fieldEle.value?.trim() ?? "";
                field === "marketingContent" && (fieldValue = marketingContent);
            }
            record[field] = fieldValue;
        });

        const success = (res) => {
            // console.log(tempTemplates);
            // console.log(res);

            mode === "add" && tempTemplates.push(res.data);
            mode === "edit" && (tempTemplates[props.recordIndex] = res.data);

            props.setTemplates(tempTemplates);
            setPageModeStatus(true);
            let statusMessage = res.data !== [] ? Utils.USER_INTERACTION_MESSAGES_4_ADMIN_SCREENS.save : "Ok";
            mode === "add" && (statusMessage = Utils.USER_INTERACTION_MESSAGES_4_ADMIN_SCREENS.save);
            mode === "edit" && (statusMessage = Utils.USER_INTERACTION_MESSAGES_4_ADMIN_SCREENS.update);
            setErrorMessage(statusMessage);
            setLoadingState({
                applyMask: false,
            });
            setModalErrorType("info");
            setShowModal(true);

            // props.setPageMode("list");
        };

        const fail = ({ error }) => {
            console.log(error);
            // cost errCode = error.error.
            let errCode = error?.response?.status;
            if (errCode === 400) {
                const errorMessage = error.response ? error.response.data.message :
                    Utils.USER_INTERACTION_MESSAGES_4_ADMIN_SCREENS.errorAlt;
                const updatedErrorMessage = errorMessage.slice(7);
                console.log(typeof (updatedErrorMessage));
                console.log(updatedErrorMessage);
                setErrorMessage(updatedErrorMessage);
                setPageModeStatus(false);
                setLoadingState({
                    applyMask: false,
                });
                // Display the error modal
                setModalErrorType('error');
                // setShowErrorModal(true);
                setShowModal(true);
            } else {
                console.log(error);
                Utils.handleDefaultError({
                    error,
                    setLoadingState,
                    setCanRedirectToLogin,
                    setCanRedirectToError,
                    setPageInfo,
                });
            }
        };

        mode === "add" && Utils.addSellOfficMrktCont(record).then(success, fail);
        mode === "edit" &&
            Utils.editSellOfficMrktCont(record, props.templates[props.recordIndex].id).then(
                success,
                fail,
            );
    };

    const validateForm = () => {
        let tempErrorFields = {};

        REQ_FIELDS.forEach((fieldId) => {
            let field = document.getElementById(fieldId);
            let fieldVal = '';
            if (field) {
                fieldVal =
                    field.type === "checkbox" ? field.checked : field.value?.trim() ?? "";
                fieldId === "marketingContent" && (fieldVal = marketingContent);
                if (fieldVal.length === 0) {
                    if (!tempErrorFields[fieldId]) {
                        // tempErrorFields[fieldId] = errorMessages[fieldId][0];
                        tempErrorFields[fieldId] = Utils.ADMIN_SECTIONS_ERROR_TEMPLATE.replace('{}', fields4ErrorTemplate[fieldId]);
                    }
                }
            }
        });
        setErrorFields(tempErrorFields);
        return tempErrorFields;

    }

    const executeSave = (options = {}) => {
        let detailsObj = validateForm();
        // console.log(detailsObj);
        if (Utils.isObjectEmpty(detailsObj)) {
            submitRecord();
        }
    };

    const executeUpdate = (options = {}) => {
        let detailsObj = validateForm();
        // console.log(detailsObj);
        if (Utils.isObjectEmpty(detailsObj)) {
            submitRecord();
        }
    };

    const initiateDelete = (options = {}) => {
        setShowAlert(true);
    };

    let [showAlert, setShowAlert] = useState(false);

    const handleClose = (e) => {
        setShowAlert(false);
    };

    const executeDelete = (e) => {
        setLoadingState({
            applyMask: true,
        });
        let tempTemplates = [...props.templates];

        const delSuccess = (res) => {
            setPageModeStatus(false);
            tempTemplates.splice(props.recordIndex, 1);
            const statusMessage = res.data !== [] ? Utils.USER_INTERACTION_MESSAGES_4_ADMIN_SCREENS.delete : "Ok";
            setErrorMessage(statusMessage);
            setModalErrorType('info');
            // setShowErrorModal(true);
            setShowModal(true);
            setPageModeStatus(true);
            props.setTemplates(tempTemplates);
            setLoadingState({
                applyMask: false,
            });
            setShowAlert(false);
            // props.setPageMode("list");
        };

        const delFail = ({ error }) => {
            console.log(error);
            Utils.handleDefaultError({
                error,
                setLoadingState,
                setCanRedirectToLogin,
                setCanRedirectToError,
                setPageInfo,
            });
        };

        let recordIdToBeDelete = props.templates[props.recordIndex].id;
        // console.log(recordIdToBeDelete);
        Utils.deleteSellOfficMrktCont(recordIdToBeDelete).then(delSuccess, delFail);
    };

    return (
        <>
            <Modal centered show={showAlert} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete confirmation?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>Are you sure to delete this item?</div>
                </Modal.Body>
                <Modal.Footer>
                    <div>
                        <div className="d-flex certif-delete-confirmation">
                            <button onClick={handleClose} className="btn btn-primary btn-sm">
                                No
                            </button>
                            <button
                                onClick={executeDelete}
                                className="btn btn-primary btn-sm"
                            >
                                Yes
                            </button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
            <CertifAlert
                props={{
                    showModal,
                    setShowModal,
                    modalType: modalErrorType,
                    bodyContent: errorMessage,
                    backdrop: "static",
                    keyboard: false,
                    pageModeStatus,
                    setPageModeProp: props.setPageMode,
                }}
            />

            {canRender && (
                <div>
                    <Error props={{ errorMessageKey }} />

                    <div className="table-responsive">
                        <table className="table table-borderless">
                            <thead className="certif-table-header">
                                <tr>
                                    <th className="certif-table-header-label">{formHeading}</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody className="certif-table-body">
                                <tr className="col-lg-12 col-md-12 col-sm-12">
                                    <td className="col-lg-6 col-md-6 col-sm-6">
                                        <label>
                                            Language
                                            <span className="certif-required-indicator">*</span>
                                        </label>
                                        <div>
                                            <select
                                                // onChange={onChangeLanguage}
                                                defaultValue={language}
                                                id={"language"}
                                            >
                                                {languageOptions?.map((lang, index) => (
                                                    <option value={lang.value} key={index}>
                                                        {lang.displayName}
                                                    </option>
                                                ))}
                                            </select>
                                            {errorFields.language && (<p className="error-message" style={{ color: "red" }}>
                                                {errorFields.language}
                                            </p>)}
                                        </div>
                                    </td>
                                    <td className="col-lg-6 col-md-6 col-sm-6">
                                        <label>
                                            Selling Office Name
                                            <span className="certif-required-indicator">*</span>
                                        </label>

                                        <div>
                                            <select
                                                // onChange={onChangeSellingOffice}
                                                defaultValue={sellingOfficeId}
                                                id={"sellingOfficeId"}
                                            >
                                                {sellingOfficeIdOptions?.map((sellingOffice, index) => (
                                                    <option
                                                        value={sellingOffice.sellingOfficeId}
                                                        key={index}
                                                    >
                                                        {sellingOffice.sellingOfficeName}
                                                    </option>
                                                ))}
                                            </select>
                                            {errorFields.sellingOfficeId && (<p className="error-message" style={{ color: "red" }}>
                                                {errorFields.sellingOfficeId}
                                            </p>)}
                                        </div>
                                    </td>
                                </tr>
                                <tr className="col-lg-12 col-md-12 col-sm-12">
                                    <td colSpan="3">
                                        <label>
                                            Template Content
                                            <span className="certif-required-indicator">*</span>
                                        </label>
                                        <div>
                                            <Editor
                                                value={marketingContent}
                                                id="marketingContent"
                                                onTextChange={(e) => setMarketingContent(e.htmlValue)}
                                                style={{ height: "250px" }}
                                            />
                                            {errorFields.marketingContent && (<p className="error-message" style={{ color: "red" }}>
                                                {errorFields.marketingContent}
                                            </p>)}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot className="certif-table-footer">
                                <tr>
                                    <th>
                                        {/* <div className="d-flex">
                                            {isSuperAdmin && props.mode === "edit" && (
                                                <button
                                                    className="btn btn-primary btn-sm"
                                                    onClick={initiateDelete}
                                                >
                                                    Delete
                                                </button>
                                            )}
                                        </div> */}
                                    </th>
                                    <th></th>
                                    <th>
                                        <div className="d-flex">
                                            <button
                                                className="btn btn-primary btn-sm"
                                                onClick={executeCancel}
                                            >
                                                {t("Cancel")}
                                            </button>

                                            {/* {props.mode === "edit" && (
                                                <button
                                                    className="btn btn-primary btn-sm"
                                                    onClick={executeUpdate}
                                                >
                                                    Update
                                                </button>
                                            )} */}

                                            {props.mode === "add" && (
                                                <button
                                                    className="btn btn-primary btn-sm"
                                                    onClick={executeSave}
                                                >
                                                    Save
                                                </button>
                                            )}
                                        </div>
                                    </th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            )}
        </>
    );
}

export default AddEditMarketingContent;
