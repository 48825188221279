import { useState, useEffect, useRef, useContext } from "react";
import { Table } from "react-bootstrap";

import AppContextComponent from "../../../../controls/AppContext";
import Utils from "./../../../../controls/Utils";

import ARROW_UP_IMG from "./../../../../assets/img/arrow.up.svg";
import FILLED_ARROW_UP_IMG from "./../../../../assets/img/arrow.up.fill.svg";
import ARROW_DOWN_IMG from "./../../../../assets/img/arrow.down.svg";
import FILLED_ARROW_DOWN_IMG from "./../../../../assets/img/arrow.down.fill.svg";

function CountryLimitList({ props }) {
    // console.log("Props in CountryLimitList", props);
    let sellOffId = props.sellingOfficeId;
    let [sellingOfficeId, setSellingOfficeId] = useState(sellOffId);

    let [canRender, setCanRender] = useState(true);

    let [fields, setFields] = useState([]);
    let [templates, setTemplates] = useState([]);
    let [filteredTemplates, setFilteredTemplates] = useState([]);
    const SORTABLE_FIELDS = [
        "sellingOfficeRegion",
        "countryCode",
        "amount",
        "currency",
    ];

    useEffect(() => {
        setFields(props.fields ?? []);
        setTemplates(props.countryLimits ?? []);
        setFilteredTemplates(props.countryLimits ?? []);
    }, [props.fields, props.countryLimits]);

    const gotoAddPage = (e) => {
        props.setPageMode("add");
    };

    const gotoEditPage = (e) => {
        const dataId = e.target.getAttribute('data-id');
        // console.log(dataId);
        // console.log(typeof (dataId));

        const index = templates.findIndex((template) => template.id === Number(dataId));
        if (index !== -1) {
            props.setRecordIndex(index);
            props.setPageMode("edit");
        }
    };

    const filterTable = (e) => {
        Utils.filterTableData({
            event: e,
            templates,
            setTemplates,
            filteredTemplates,
            setFilteredTemplates,
        });
    };

    const sortTable = (e) => {
        Utils.sortTable({
            event: e,
            templates,
            setTemplates,
            filteredTemplates,
            setFilteredTemplates,
        });
    };

    return (
        <>
            {canRender && (
                <>
                    <div className="certif-right-search-wrapper">
                        <input
                            type="text"
                            // placeholder={`Search In Office Id ${sellingOfficeId}`}
                            placeholder={Utils.PLACEHOLDER_4_SEARCH_IN_ADMIN_SCREENS}
                            onChange={filterTable}
                            sort-type=""
                            sort-key=""
                            id="certif-country-limit-filter-id"
                        />
                    </div>
                    <div className="table-responsive">
                        <div className="certif-bar-wrapper">
                            <div className="certif-bar-left">Selling Office Id - {sellingOfficeId}</div>
                            <div className="certif-bar-right" onClick={gotoAddPage}>
                                +
                            </div>
                        </div>

                        <div className="d-flex certif-table-with-scrolls">
                            <Table stripped="" className="m-0" bordered hover size="md">
                                <thead>
                                    <tr>
                                        <th
                                            width=""
                                            id="sellingOfficeRegion_th"
                                            className="certif-table-sort-col-wrapper"
                                        >
                                            <span className="certif-table-sort-col-name">
                                                Selling Office Region
                                            </span>
                                            <span className="certif-table-sort-wrapper">
                                                <span
                                                    className="certif-table-sort-up"
                                                    sort-col-id="sellingOfficeRegion"
                                                    sort-col-interactive-id="certif-country-limit-filter-id"
                                                    sort-col-type="asc"
                                                    onClick={sortTable}
                                                ></span>
                                                <span
                                                    className="certif-table-sort-down"
                                                    sort-col-id="sellingOfficeRegion"
                                                    sort-col-interactive-id="certif-country-limit-filter-id"
                                                    sort-col-type="desc"
                                                    onClick={sortTable}
                                                ></span>
                                            </span>
                                        </th>
                                        <th
                                            width=""
                                            id="countryCode_th"
                                            className="certif-table-sort-col-wrapper"
                                        >
                                            <span className="certif-table-sort-col-name">
                                                Country Code
                                            </span>
                                            <span className="certif-table-sort-wrapper">
                                                <span
                                                    className="certif-table-sort-up"
                                                    sort-col-id="countryCode"
                                                    sort-col-interactive-id="certif-country-limit-filter-id"
                                                    sort-col-type="asc"
                                                    onClick={sortTable}
                                                ></span>
                                                <span
                                                    className="certif-table-sort-down"
                                                    sort-col-id="countryCode"
                                                    sort-col-interactive-id="certif-country-limit-filter-id"
                                                    sort-col-type="desc"
                                                    onClick={sortTable}
                                                ></span>
                                            </span>
                                        </th>
                                        <th
                                            width=""
                                            id="amount_th"
                                            className="certif-table-sort-col-wrapper"
                                        >
                                            <span className="certif-table-sort-col-name">
                                                Amount
                                            </span>
                                            <span className="certif-table-sort-wrapper">
                                                <span
                                                    className="certif-table-sort-up"
                                                    sort-col-id="amount"
                                                    sort-col-interactive-id="certif-country-limit-filter-id"
                                                    sort-col-type="asc"
                                                    onClick={sortTable}
                                                ></span>
                                                <span
                                                    className="certif-table-sort-down"
                                                    sort-col-id="amount"
                                                    sort-col-interactive-id="certif-country-limit-filter-id"
                                                    sort-col-type="desc"
                                                    onClick={sortTable}
                                                ></span>
                                            </span>
                                        </th>
                                        <th
                                            width=""
                                            id="currency_th"
                                            className="certif-table-sort-col-wrapper"
                                        >
                                            <span className="certif-table-sort-col-name">
                                                Currency
                                            </span>
                                            <span className="certif-table-sort-wrapper">
                                                <span
                                                    className="certif-table-sort-up"
                                                    sort-col-id="currency"
                                                    sort-col-interactive-id="certif-country-limit-filter-id"
                                                    sort-col-type="asc"
                                                    onClick={sortTable}
                                                ></span>
                                                <span
                                                    className="certif-table-sort-down"
                                                    sort-col-id="currency"
                                                    sort-col-interactive-id="certif-country-limit-filter-id"
                                                    sort-col-type="desc"
                                                    onClick={sortTable}
                                                ></span>
                                            </span>
                                        </th>
                                        {/* <th width="">Selling Office Region</th> */}
                                        {/* <th width="">Country Code</th> */}
                                        {/* <th width="">Amount</th> */}
                                        {/* <th width="">Currency</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredTemplates.map((template, templateIndex) => (
                                        <tr
                                            key={templateIndex}
                                            className="certif-admin-template-item-row"
                                        >
                                            {fields.map(
                                                (field, fieldIndex) =>
                                                    fieldIndex < 4 && (
                                                        <td
                                                            id={templateIndex}
                                                            key={fieldIndex}
                                                            data-id={template.id}
                                                            onClick={gotoEditPage}
                                                        >
                                                            {field === "sellingOfficeRegion" && <>{template[field]}</>}
                                                            {field === "countryCode" && (
                                                                <>{props.countryCodesObj[template[field]]}</>
                                                            )}
                                                            {field === "amount" && <>{template[field]}</>}
                                                            {field === "currency" && <>{props.currencyCodesObj[template[field]]}</>}
                                                        </td>
                                                    ),
                                            )}
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

export default CountryLimitList;
