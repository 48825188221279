import React, { useEffect, useState } from "react";

import Utils from "../../controls/Utils";
import { useOktaAuth } from "@okta/okta-react";

import { Container, Card, Form } from "react-bootstrap";
import "./../../assets/css/login.css";

const Login = () => {
  const { oktaAuth } = useOktaAuth();
  let [canRender, setCanRender] = useState(false);

  const login = async () => {
    oktaAuth
      .signInWithRedirect()
      .then((res) => {
        debugger;
        const { sessionToken } = res;
        if (res.status === "SUCCESS") {
          oktaAuth.token
            .getWithoutPrompt({
              responseType: "id_token",
              sessionToken,
            })
            .then((res) => {
              const { tokens } = res;
              console.log("tokens", tokens);

              oktaAuth.tokenManager.setTokens(tokens);
              // history.push('/dashboard');
            })
            .catch((err) => {
              console.log("err", err);
            });
        }
      })
      .catch((err) => console.log("Found an error", err));
  };

  const executeLogin = (e) => {
    login();
  };

  useEffect(() => {
    Utils.deleteSession();
    setCanRender(true);
  }, []);

  return (
    <>
      {canRender && (
        <Container className="">
          <div className="certif-login-form-wrapper">
            <div className="certif-login-form-headings text-left">
              <div className="certif-login-form-text-login">Log in</div>

              <div className="certif-login-form-text-desc">
                Click the below link to navigate to login page.
              </div>
            </div>

            <div className="certif-add-footer">
              <button
                type="button"
                className="btn btn-info"
                onClick={executeLogin}
              >
                Login with OKTA
              </button>
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

export default Login;
