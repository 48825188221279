import { useState, useEffect, useRef, useContext } from "react";
import { Editor } from "primereact/editor";
import { Modal } from "react-bootstrap";
import Utils from "../../../../controls/Utils";
import AppContextComponent from "../../../../controls/AppContext";

function AddEditMarketingContent({ props }) {
    // console.log(props);
    let { setLoadingState } = useContext(AppContextComponent);

    let [canRender, setCanRender] = useState(true);
    let [pageMode, setPageMode] = useState(props.mode);

    const anyCallback = () => {
        setLoadingState({
            applyMask: false,
        });
    };

    let formHeading = props.mode === "add" ? "Add Selling Office Marketing Content" : "Edit Selling Office Marketing Content";

    let defLanguage =
        props.mode === "add" ? "" : props.marktContents[props.recordIndex]["language"];

    let [language, setLanguage] = useState(defLanguage);


    let defMarketingContent =
        props.mode === "add" ? "" : props.marktContents[props.recordIndex]["marketingContent"];

    let [marketingContent, setMarketingContent] = useState(defMarketingContent);

    let defSellingOfficeId =
        props.mode === "add"
            ? props.sellingOfficeId
            : props.marktContents[props.recordIndex]["sellingOfficeId"];

    let [sellingOfficeId, setSellingOfficeId] = useState(defSellingOfficeId);

    const executeCancel = (options = {}) => {
        props.setPageMode("list");
    };


    const submitRecord = () => {
        let mode = pageMode;
        setLoadingState({
            applyMask: true,
        });
        let tempMarktContents = [...props.marktContents];
        let record = {};

        props.fields.forEach((field, index) => {
            let fieldEle = document.getElementById(field);
            let fieldValue = '';

            if (fieldEle) {
                if (fieldEle.type === 'checkbox') {
                    fieldValue = fieldEle.checked;
                } else {
                    fieldValue = field === "marketingContent" ? marketingContent : fieldEle.value.trim();
                }
            }

            record[field] = fieldValue;

        });
        // console.log("Marketing Content record --- ", record);

        const success = (res) => {
            // debugger;
            // console.log(tempMarktContents);
            // console.log(res);

            mode === "add" && tempMarktContents.push(res.data);
            mode === "edit" && (tempMarktContents[props.recordIndex] = res.data);

            props.setMarktContents(tempMarktContents);
            setLoadingState({
                applyMask: false,
            });

            props.setPageMode("list");
        };

        const fail = (err) => {
            console.log(err);
            setLoadingState({
                applyMask: false,
            });
        };
        // debugger;
        mode === "add" && Utils.addSellOfficMrktCont(record).then(success, fail);
        mode === "edit" &&
            Utils.editSellOfficMrktCont(
                record,
                props.marktContents[props.recordIndex].id,
            ).then(success, fail);
    };

    const executeSave = (options = {}) => {
        submitRecord();
    };

    const executeUpdate = (options = {}) => {
        submitRecord();
    };

    const onChangeLangDD = (e) => {
        // do nothing;
    };

    const initiateDelete = (options = {}) => {
        setShowAlert(true);
    };

    let [showAlert, setShowAlert] = useState(false);

    const handleClose = (e) => {
        setShowAlert(false);
    };

    const executeDelete = (e) => {
        setLoadingState({
            applyMask: true,
        });
        let tempMarktContents = [...props.marktContents];

        const delSuccess = (res) => {
            tempMarktContents.splice(props.recordIndex, 1);
            props.setMarktContents(tempMarktContents);
            setLoadingState({
                applyMask: false,
            });
            props.setPageMode("list");
        };

        const delFail = (err) => {
            console.log(err);
            setLoadingState({
                applyMask: false,
            });
        };

        let recordIdToBeDelete = props.marktContents[props.recordIndex].id;
        // console.log(recordIdToBeDelete);
        Utils.deleteSellOfficMrktCont(recordIdToBeDelete).then(delSuccess, delFail);
    };

    return (
        <>
            <Modal centered show={showAlert} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete confirmation?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>Are you sure to delete this item?</div>
                </Modal.Body>
                <Modal.Footer>
                    <div>
                        <div className="d-flex certif-delete-confirmation">
                            <button onClick={handleClose} className="btn btn-primary btn-sm">
                                No
                            </button>
                            <button
                                onClick={executeDelete}
                                className="btn btn-primary btn-sm"
                            >
                                Yes
                            </button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
            {canRender && (
                <div>
                    <div className="table-responsive">
                        <table className="table table-borderless">
                            <thead className="certif-table-header">
                                <tr>
                                    <th className="certif-table-header-label">{formHeading}</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody className="certif-table-body">
                                <tr className="col-lg-12 col-md-12 col-sm-12">
                                    <td className="col-lg-6 col-md-6 col-sm-6">
                                        <label>
                                            Language
                                            <span className="certif-required-indicator">*</span>
                                        </label>
                                        <div>
                                            <select
                                                onChange={onChangeLangDD}
                                                id={"language"}
                                                defaultValue={language}
                                            >
                                                {Object.keys(props.languagesObj)?.map(
                                                    (langKey, index) => (
                                                        <option value={langKey} key={index}>
                                                            {props.languagesObj[langKey]}
                                                        </option>
                                                    ),
                                                )}
                                            </select>
                                        </div>
                                    </td>
                                    <td className="col-lg-6 col-md-6 col-sm-6 d-none">
                                        <label>
                                            Selling Office Id
                                            <span className="certif-required-indicator">*</span>
                                        </label>
                                        <div>
                                            <input
                                                type="hidden"
                                                defaultValue={sellingOfficeId}
                                                id={"sellingOfficeId"}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr className="col-lg-12 col-md-12 col-sm-12">
                                    <td colSpan="3">
                                        <label>
                                            Template Content
                                            <span className="certif-required-indicator">*</span>
                                        </label>
                                        <div>
                                            <Editor
                                                value={marketingContent}
                                                id="marketingContent"
                                                onTextChange={(e) => setMarketingContent(e.htmlValue)}
                                                style={{ height: "250px" }}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot className="certif-table-footer">
                                <tr>
                                    <th>
                                        <div className="d-flex">
                                            {props.mode === "edit" && (
                                                <button
                                                    className="btn btn-primary btn-sm"
                                                    onClick={initiateDelete}
                                                >
                                                    Delete
                                                </button>
                                            )}
                                        </div>
                                    </th>
                                    <th></th>
                                    <th>
                                        <div className="d-flex">
                                            <button
                                                className="btn btn-primary btn-sm"
                                                onClick={executeCancel}
                                            >
                                                Cancel
                                            </button>

                                            {props.mode === "edit" && (
                                                <button
                                                    className="btn btn-primary btn-sm"
                                                    onClick={executeUpdate}
                                                >
                                                    Update
                                                </button>
                                            )}

                                            {props.mode === "add" && (
                                                <button
                                                    className="btn btn-primary btn-sm"
                                                    onClick={executeSave}
                                                >
                                                    Save
                                                </button>
                                            )}
                                        </div>
                                    </th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            )}
        </>
    );
}

export default AddEditMarketingContent;
