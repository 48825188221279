import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";

import CloseImg from "./../assets/img/close.png";
import InfoImg from "./../assets/img/info.png";
import ErrorImg from "./../assets/img/errorcross.png";
import Utils from "./Utils";

function CertifAlert({ props }) {
  let [modalCanOpen, setModalCanOpen] = useState(false);
  let [modalType, setModalType] = useState("info");
  let [imgType, setImgType] = useState(InfoImg);
  let [bodyContent, setBodyContent] = useState('');
  let [messages, setMessages] = useState({});
  let [errorTypeToFieldsMapping, setErrorTypeToFieldsMapping] = useState({});

  const handleClose = (e) => {
    setModalCanOpen(false);
    if (props.isLinkClick) {
      props.setIsLinkClick(false);
    };
    props.setShowModal(false);
  };

  useEffect(() => {
    let session = Utils.getSession();
    let messages = session.messages;
    setMessages(messages);
    setErrorTypeToFieldsMapping(Utils.ERROR_TYPE_TO_FIELDS_MAPPING);
  }, []);

  useEffect(() => {
    if (props.showModal) {
      let type = props?.modalType ?? "info";
      setModalType(type);
      if (type === "info") {
        setImgType(InfoImg);
      } else {
        setImgType(ErrorImg);
      }
      setModalCanOpen(true);
      if (props?.expiryDateFlag) {
        const errorMapping = errorTypeToFieldsMapping['invalidFormat'];
        const messageCode = Utils[errorMapping]['expiryDate'];
        let errorMessage = messages[messageCode][Utils.getLocale()]
        setBodyContent(errorMessage);
      } else {
        setBodyContent(props?.bodyContent ?? '');
      }
    }
  }, [props.showModal]);

  return (
    <>
      <Modal
        className={`certif-home-custom-modal certif-home-custom-modal-${modalType}`}
        centered
        backdrop={props?.expiryDateFlag ? "static" : false}
        keyboard={props?.expiryDateFlag}
        show={modalCanOpen}
        onHide={handleClose}
      >
        <Modal.Body>
          <div className="d-flex certif-home-modal-body">
            <div
              className="d-flex certif-home-modal-body-row-1"
              onClick={handleClose}
            >
              <img src={CloseImg} alt="Close" />
            </div>
            <div className="d-flex certif-home-modal-body-row-2">
              <div className="certif-home-modal-body-row-21">
                <img src={imgType} alt="" />
              </div>
              <div className="certif-home-modal-body-row-22">
                {/* {bodyContent} */}
                <span dangerouslySetInnerHTML={{ __html: bodyContent }}></span>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CertifAlert;
