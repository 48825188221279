import { useState } from "react";
import { Routes, Route } from "react-router-dom";

import Navigation from "./navigation/Navigation";
import Footer from "./footer/Footer";
import Body from "./body/Body";
import Utils from "../../controls/Utils";
import Login from "./../login/Login";
import LoginCallback from "./../login/LoginCallback";

function LandingBody() {
  return (
    <>
      <Navigation />
      <div className="certif-body">
        <Routes>
          <Route
            path={Utils.APP_URLS.LOGIN_PAGE}
            exact
            element={<Login />}
          ></Route>
          <Route
            path={Utils.APP_URLS.LOGIN_CALLBACK_PAGE}
            exact
            element={<LoginCallback />}
          ></Route>
          <Route path={`/*`} exact element={<Body />}></Route>
        </Routes>
      </div>
      <Footer />
    </>
  );
}

export default LandingBody;
