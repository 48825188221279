import { useState, useEffect, useRef, useContext } from "react";

import Table from "react-bootstrap/Table";

import Utils from "../../../controls/Utils";

import ARROW_UP_IMG from "./../../../assets/img/arrow.up.svg";
import FILLED_ARROW_UP_IMG from "./../../../assets/img/arrow.up.fill.svg";
import ARROW_DOWN_IMG from "./../../../assets/img/arrow.down.svg";
import FILLED_ARROW_DOWN_IMG from "./../../../assets/img/arrow.down.fill.svg";

function TemplateList({ props }) {
  const submitHandler = (e) => { };

  // let fields = props.fields;
  // let templates = props.templates;

  let [fields, setFields] = useState([]);
  let [templates, setTemplates] = useState([]);
  let [filteredTemplates, setFilteredTemplates] = useState([]);
  const SORTABLE_FIELDS = [
    "name",
    "legalEntity",
    "language",
  ];

  useEffect(() => {
    setFields(props.fields);
    setTemplates(props.templates);
    setFilteredTemplates(props.templates);
  }, [props.fields, props.templates]);

  const getLegalEntityName4Id = (legalEntyId) => {
    const legEnty = props.legalEntityDDOptions?.find(entity => entity.entityId === legalEntyId);
    if (!legEnty) {
      return legalEntyId;
    }
    // return legalEntyId;
    return legEnty.entityName;
  }

  const gotoAddPage = (e) => {
    props.setPageMode("add");
  };

  const gotoEditPage = (e) => {
    const dataId = e.target.getAttribute('data-id');
    console.log(dataId);
    console.log(typeof (dataId));

    const index = templates.findIndex((template) => template.id === Number(dataId));
    if (index !== -1) {
      props.setRecordIndex(index);
      props.setPageMode("edit");
    }
  };

  const filterTable = (e) => {
    Utils.filterTableData({
      event: e,
      templates,
      setTemplates,
      filteredTemplates,
      setFilteredTemplates,
    });
  };

  const sortTable = (e) => {
    Utils.sortTable({
      event: e,
      templates,
      setTemplates,
      filteredTemplates,
      setFilteredTemplates,
    });
  };

  return (
    <>
      <div className="certif-right-search-wrapper">
        <input
          type="text"
          placeholder={Utils.PLACEHOLDER_4_SEARCH_IN_ADMIN_SCREENS}
          onChange={filterTable}
          sort-type=""
          sort-key=""
          id="certif-email-template-filter-id"
        />
      </div>
      <div className="table-responsive certif-table-responsive-with-search">
        <div className="certif-bar-wrapper">
          <div className="certif-bar-left">Email Template</div>
          <div className="certif-bar-right" onClick={gotoAddPage}>
            +
          </div>
        </div>

        <div className="d-flex certif-table-with-scrolls">
          <Table stripped="" className="m-0" bordered hover size="md">
            <thead>
              <tr>
                <th
                  width=""
                  id="name_th"
                  className="certif-table-sort-col-wrapper"
                >
                  <span className="certif-table-sort-col-name">
                    Template Name
                  </span>
                  <span className="certif-table-sort-wrapper">
                    <span
                      className="certif-table-sort-up"
                      sort-col-id="name"
                      sort-col-interactive-id="certif-email-template-filter-id"
                      sort-col-type="asc"
                      onClick={sortTable}
                    ></span>
                    <span
                      className="certif-table-sort-down"
                      sort-col-id="name"
                      sort-col-interactive-id="certif-email-template-filter-id"
                      sort-col-type="desc"
                      onClick={sortTable}
                    ></span>
                  </span>
                </th>
                <th
                  width=""
                  id="legalEntity_th"
                  className="certif-table-sort-col-wrapper"
                >
                  <span className="certif-table-sort-col-name">
                    Legal Entity Name
                  </span>
                  <span className="certif-table-sort-wrapper">
                    <span
                      className="certif-table-sort-up"
                      sort-col-id="legalEntity"
                      sort-col-interactive-id="certif-email-template-filter-id"
                      sort-col-type="asc"
                      onClick={sortTable}
                    ></span>
                    <span
                      className="certif-table-sort-down"
                      sort-col-id="legalEntity"
                      sort-col-interactive-id="certif-email-template-filter-id"
                      sort-col-type="desc"
                      onClick={sortTable}
                    ></span>
                  </span>
                </th>
                <th
                  width=""
                  id="language_th"
                  className="certif-table-sort-col-wrapper"
                >
                  <span className="certif-table-sort-col-name">
                    Language
                  </span>
                  <span className="certif-table-sort-wrapper">
                    <span
                      className="certif-table-sort-up"
                      sort-col-id="language"
                      sort-col-interactive-id="certif-email-template-filter-id"
                      sort-col-type="asc"
                      onClick={sortTable}
                    ></span>
                    <span
                      className="certif-table-sort-down"
                      sort-col-id="language"
                      sort-col-interactive-id="certif-email-template-filter-id"
                      sort-col-type="desc"
                      onClick={sortTable}
                    ></span>
                  </span>
                </th>
                {/* <th width="">Template name</th> */}
                {/* <th width="">Legal entity</th> */}
                {/* <th width="">Language</th> */}
              </tr>
            </thead>
            <tbody>
              {filteredTemplates.map((template, templateIndex) => (
                <tr
                  key={templateIndex}
                  className="certif-admin-template-item-row"
                >
                  {fields.map(
                    (field, fieldIndex) =>
                      fieldIndex < 3 && (
                        <td
                          id={templateIndex}
                          key={fieldIndex}
                          data-id={template.id}
                          onClick={gotoEditPage}
                        >
                          {field === "name" && <>{template[field]}</>}
                          {field === "legalEntity" && <>{getLegalEntityName4Id(template[field])}</>}
                          {/* {field === "sellingOffice" && <>{template[field]}</>} */}
                          {field === "language" && <>{template[field]}</>}
                          {/* {template[field] === true && <><img src={YesImg} alt="Yes" /></>}
                          {template[field] === false && <><img src={NoImg} alt="No" /></>} */}
                          {/* {template[field] !== false && template[field] !== true && <>{template[field]}</>} */}
                        </td>
                      ),
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>

        <div className="certif-bar-wrapper">
          <div className="certif-bar-right-buttons">
            {/* <button className="btn btn-primary btn-sm" onClick={submitHandler}>
              Save
            </button> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default TemplateList;
