import { useEffect, useContext, useState } from "react";

import DatePicker, { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";

import "react-datepicker/dist/react-datepicker.css";

import { useTranslation, Trans } from "react-i18next";
import Ribbon from "./Ribbon";
import CertifAlert from "../../../controls/CertifAlert";

import QuestionImg from "./../../../assets/img/icon-question.png";
import RemoveImg from "./../../../assets/img/icon-del.png";
import TickImg from "./../../../assets/img/yes.png";
import CalImg from "./../../../assets/img/icon-cal.png";
import DeleteImg from "./../../../assets/img/bin.png";
import Utils from "../../../controls/Utils";
import Error from "./Error";
import ErrorImg from "./../../../assets/img/errorcross.png";
import AppContextComponent from "../../../controls/AppContext";

function CertificateInformation() {
  let ALL_FIELDS = [
    "company",
    "subsidiary",
    "subsidiaryCheck",
    "empTitle",
    "empFirstName", //
    "empLastName", //
    "empEmail", //
    "language", //
    "managerApproved", //
    "destinationCountry", //
    "startDate", //
    "endDate", //
    "originalRequested",
    "bnfTitle",
    "bnfFirstName",
    "bnfLastName",
    "bnfEmail",
    "bnfRelation",
    "courierType",
    "courierServiceProvider",
    "courierAccountNumber",
    "noOfOrigCertificates",
    "sdCompanyOrOrganisation",
    "sdTitle", //
    "sdFirstName", //
    "sdLastName", //
    "sdAddress", //
    "sdCity", //
    "sdZipCode", //
    "sdPhone",
  ];

  let REQ_FIELDS = [
    "empFirstName", //
    "empLastName", //
    "empEmail", //
    "language", //
    "managerApproved", //
    "destinationCountry", //
    "startDate", //
    "endDate", //
    "bnfFirstName", //
    "bnfLastName", //
    "bnfEmail", //
    "bnfRelation", //
    "sdFirstName", //
    "sdLastName", //
    "sdPhone", //
  ];

  const MAX_RECORDS_ALLOWED = Utils.MAX_RECORDS_ALLOWED;

  let {
    setLoadingState,
    pageInfo,
    setPageInfo,
    setCanRedirectToLogin,
    setCanRedirectToError,
  } = useContext(AppContextComponent);
  let { t } = useTranslation();

  let active = Utils.PAGES[2];
  // let existingValues = Utils.getSession()[active] ?? {};
  // console.log(existingValues);

  let [company, setCompany] = useState(pageInfo.company);
  let [language, setLanguage] = useState("");
  let [startDate, setStartDate] = useState(null);
  let [endDate, setEndDate] = useState(null);
  let [destinationCountry, setDestinationCountry] = useState("");
  let [destinationCountryLabel, setDestinationCountryLabel] = useState("");
  let [empEmail, setEmpEmail] = useState("");
  let [emailError, setEmailError] = useState("");
  let [bnfEmailError, setBntEmailError] = useState("");
  let [empTitle, setEmpTitle] = useState("");
  let [empFirstName, setEmpFirstName] = useState("");
  let [empLastName, setEmpLastName] = useState("");
  let [originalRequested, setOriginalRequested] = useState(false);
  let [subsidiary, setSubsidiary] = useState("");
  let [subsidiaryCheck, setSubsidiaryCheck] = useState("");
  let [managerApproved, setManagerApproved] = useState("");

  let [bnfTitle, setBnfTitle] = useState("");
  let [bnfFirstName, setBnfFirstName] = useState("");
  let [bnfLastName, setBnfLastName] = useState("");
  let [bnfEmail, setBnfEmail] = useState("");
  let [bnfRelation, setBnfRelation] = useState("");

  let [courierType, setCourierType] = useState("mail");
  let [courierServiceProvider, setCourierServiceProvider] = useState("");
  let [courierAccountNumber, setCourierAccountNumber] = useState("");
  let [sdCompanyOrOrganisation, setSdCompanyOrOrganisation] = useState("");
  let [noOfOrigCertificates, setNoOfOrigCertificates] = useState(1);
  let [sdTitle, setSdTitle] = useState("");
  let [sdFirstName, setSdFirstName] = useState("");
  let [sdLastName, setSdLastName] = useState("");
  let [sdAddress, setSdAddress] = useState("");
  let [sdCity, setSdCity] = useState("");
  let [sdZipCode, setSdZipCode] = useState("");
  let [sdPhone, setSdPhone] = useState("");
  let [mailingDiv, setMailingDiv] = useState("d-none");
  let tempCanShow = pageInfo?.general_information?.beneficiary === "Dependant";
  let [canShowBeneficiaryTable, setCanShowBeneficiaryTable] =
    useState(tempCanShow);

  let [certInfoTableHeading1, setCertInfoTableHeading1] = useState("");
  let [certInfoBNtableHeading, setCertInfoBNtableHeading] = useState("");

  let [travelDetailsArray, setTravelDetailsArray] = useState([]);

  let [currentLocale, setCurrentLocale] = useState(pageInfo.locale);

  registerLocale("fr", fr);

  const handleDateImgClick = (e) => {
    let idVal = e.currentTarget.getAttribute("data-target");
    let targetElement = document.getElementById(idVal);
    targetElement.focus();
  };

  let [showTravelDetailsTable, setShowTravelDetailsTable] = useState(false);
  let [errorMessageKey, setErrorMessageKey] = useState("");
  let [canContinue, setCanContinue] = useState(false);

  const updateTravelDetailsArray = (detialsArray) => {
    setTravelDetailsArray(detialsArray);
    setShowTravelDetailsTable(detialsArray.length > 0);
    resetTravelDetailsForm(true);
  };

  let [formObj, setFormObj] = useState({});
  let [errorFields, setErrorFields] = useState([]);
  let [recordsCount, setRecordsCount] = useState(0);
  let [expiryDateFlag, setExpiryDateFlag] = useState(false);

  const validateForm = () => {
    document
      .querySelectorAll(".certif-required-field-border")
      .forEach((element) => {
        element.classList.remove("certif-required-field-border");
      });

    let tempErrorFields = {};

    //certInfoDesc
    REQ_FIELDS.forEach((fieldId) => {
      let field = document.getElementById(fieldId);
      // console.log('what field is returning', field);
      if (field) {
        let fieldVal =
          field.type === "checkbox" ? field.checked : field?.value?.trim();

        let validateFlag = true;
        field.type === "email" && (validateFlag = validateEmail(fieldVal));

        let phNumValidateFlag = true;
        field.id === "sdPhone" &&
          (phNumValidateFlag = validatePhoneNumber(fieldVal));

        // let destinationCountryFlag = true;
        // field.id === "destinationCountry" && field.selectedIndex !== 0 && (destinationCountryFlag = countryValidation(field.selectedIndex));

        if (fieldVal.length === 0 || !validateFlag || !phNumValidateFlag) {
          if (!tempErrorFields[fieldId]) {
            tempErrorFields[fieldId] = {
              fieldId,
              errorTypes: [], // Initialize an array for error types
            };
          }

          if (fieldVal.length === 0) {
            tempErrorFields[fieldId].errorTypes.push("empty");
          }

          if (fieldVal.length !== 0 && !validateFlag) {
            tempErrorFields[fieldId].errorTypes.push("invalidFormat");
          }

          if (fieldVal.length !== 0 && !phNumValidateFlag) {
            tempErrorFields[fieldId].errorTypes.push("invalidFormat");
          }
        }

        // fieldVal.length === 0 && errorFields.push(fieldId);
      }
    });

    // Date Fields Validations
    if (startDate && endDate) {
      // 1. Check weather start end date is greater than Start Date!
      let fieldId = "endDate";
      if (new Date(startDate) > new Date(endDate)) {
        if (!tempErrorFields[fieldId]) {
          tempErrorFields[fieldId] = {
            fieldId,
            errorTypes: [], // Initialize an array for error types
          };
        }
        tempErrorFields[fieldId].errorTypes.push("invalidFormat");
      }
    }

    if (subsidiaryCheck) {
      let fieldId = "subsidiary";
      let field = document.getElementById(fieldId);
      if (field) {
        let fieldVal =
          field.type === "checkbox" ? field.checked : field?.value?.trim();

        if (fieldVal.length === 0) {
          if (!tempErrorFields[fieldId]) {
            tempErrorFields[fieldId] = {
              fieldId,
              errorTypes: [], // Initialize an array for error types
            };
          }
        }
        if (fieldVal.length === 0) {
          tempErrorFields[fieldId].errorTypes.push("empty");
        }
      }
    }

    if (sdZipCode.length !== 0 && !validateZipCode(sdZipCode)) {
      let fieldId = "sdZipCode";
      if (!tempErrorFields[fieldId]) {
        tempErrorFields[fieldId] = {
          fieldId,
          errorTypes: [], // Initialize an array for error types
        };
      }
      tempErrorFields[fieldId].errorTypes.push("invalidFormat");
    }

    if (canShowRegions) {
      if (
        document.querySelectorAll(
          'input[type="checkbox"].certif-region-cb:checked',
        ).length === 0
      ) {
        if (!tempErrorFields["certif-regions-wrapper"]) {
          tempErrorFields["certif-regions-wrapper"] = {
            fieldId: "certif-regions-wrapper",
            errorTypes: ["empty"],
          };
        }
      }
    }

    let highRiskCountryAccepted = false;

    if (canShowRegions || canShowWarning) {
      let riskCBEle = document.getElementById("understandRisk");
      highRiskCountryAccepted = riskCBEle.checked;
      if (!highRiskCountryAccepted) {
        if (!tempErrorFields["understandRisk"]) {
          tempErrorFields["understandRisk"] = {
            fieldId: "understandRisk",
            errorTypes: ["empty"],
          };
        }
      }
    }
    // Convert the errorFieldMap object to an array of error objects
    let errorFields = Object.values(tempErrorFields);
    // console.log("final errorObject::: ", errorFields);
    setErrorFields(errorFields);

    if (errorFields.length) {
      errorFields.forEach((errorObject) => {
        const errorFieldId = errorObject.fieldId;
        document
          .getElementById(errorFieldId)
          .classList.add("certif-required-field-border");
      });
      setErrorMessageKey("certInfoDesc");
      return {};
    } else {
      setErrorMessageKey("");
      setErrorFields([]);
      setShowModal(false);
      setExpiryDateFlag(false);
      let outputObj = {};
      outputObj[active] = {};

      let details = {
        destinationCountry: outputObj[active].destinationCountry,
        startDate: outputObj[active].startDate,
        endDate: outputObj[active].endDate,
        originalRequested: outputObj[active].originalRequested,
        highRiskCountryAccepted,
      };

      ALL_FIELDS.forEach((fieldId) => {
        let field = document.getElementById(fieldId);

        if (fieldId === "courierType") {
          details[fieldId] = courierType;
        } else if (field) {
          let fieldVal =
            field.type === "checkbox" ? field.checked : field?.value?.trim();
          details[fieldId] = fieldVal;
        }
      });

      let rgns = document.getElementsByClassName("certif-regions-wrapper");
      let rgnAry = [];

      if (rgns.length) {
        let cbEles = rgns[0].querySelectorAll(
          ".certif-input-field.certif-input-field-region",
        );
        cbEles.forEach((cb) => {
          // console.log(cb.checked);
          if (cb.checked) {
            rgnAry.push(cb.getAttribute('data-value'));
          }
        });
        console.log(rgnAry);
      }
      details["destinationRegions"] = rgnAry;
      // code for expiry date validation
      let expDatefield = document?.getElementById('endDate');
      let expDateValue = expDatefield?.value?.trim();
      console.log(pageInfo.expiryDate);
      if (!isInternalUser && validateExpiryDate(expDateValue)) {
        setModalType("info");
        setShowModal(true);
        setExpiryDateFlag(true);
      }
      // console.log(details);
      // return {};
      return details;
    }
  };

  const validateCreateForm = () => {
    setCanContinue(false);
    let detailsObj = validateForm();
    if (!Utils.isObjectEmpty(detailsObj)) {
      let tempTravelDetailsArray = [...travelDetailsArray];
      let session = Utils.getSession();
      let detialsArray = tempTravelDetailsArray;
      detailsObj["selectionDetails"] = session.general_information;
      detialsArray.push(detailsObj);

      updateTravelDetailsArray(detialsArray);
      setCanContinue(true);
    }
  };

  const resetTravelDetailsForm = () => {
    let cb1 = document.getElementById("managerApproved");
    cb1.click();

    let ctry = document.getElementById("destinationCountry");
    ctry.value = "";

    let dtFrom = document.getElementById("startDate");
    dtFrom.selected = "";

    let dtTo = document.getElementById("endDate");
    dtTo.selected = "";

    let orig = document.getElementById("originalRequested");
    orig.checked && orig.click();
  };

  const enableOrDisableValidateBtn = () => {
    let managerApprovedEle = document.getElementById("managerApproved");
    let validateBtnEle = document.getElementById("validateFormButton");
    // if (managerApprovedEle.checked) {
    //   validateBtnEle?.removeAttribute("disabled");
    // } else {
    //   validateBtnEle?.setAttribute("disabled", "");
    // }
    let destinationCountryEle = document.getElementById("destinationCountry");
    let destCntryFlag = false;
    destinationCountryEle.selectedIndex !== 0 &&
      (destCntryFlag = countryValidation(destinationCountryEle.selectedIndex));
    if (managerApprovedEle.checked && destCntryFlag) {
      validateBtnEle?.removeAttribute("disabled");
    } else {
      validateBtnEle?.setAttribute("disabled", "");
    }
    let understandRiskEle = document.getElementById("understandRisk");

    if (managerApprovedEle.checked && understandRiskEle && destCntryFlag) {
      if (understandRiskEle.checked) {
        validateBtnEle?.removeAttribute("disabled");
      } else {
        validateBtnEle?.setAttribute("disabled", "");
      }
    }
  };

  const managerApprovedHandler = (e) => {
    enableOrDisableValidateBtn();
    // setStartDate(null);
    // setEndDate(null);
    // document.getElementById("destinationCountry").value = "";
  };

  let [countries, setCountries] = useState([]);
  let [titles, setTitles] = useState([]);
  let [courSerProvdList, setCourSerProvdList] = useState([]);
  let [relationShipList, setRelationShipList] = useState([]);

  let [canShowWarning, setCanShowWarning] = useState(false);
  let [canShowRegions, setCanShowRegions] = useState(false);
  let [regions, setRegions] = useState([]);
  let [selectedRegions, setSelectedRegions] = useState([]);

  useEffect(() => {
    setLoadingState({
      applyMask: true,
    });
    const commonFail = ({ error }) => {
      Utils.handleDefaultError({
        error,
        setLoadingState,
        setCanRedirectToLogin,
        setCanRedirectToError,
        setPageInfo,
      });
    };
    const titlesSuccess = (res) => {
      res.data.sort((itemA, itemB) => {
        return itemA.displayName.localeCompare(itemB.displayName);
      });
      let selectOptions = [
        {
          value: "",
          displayName: t("select"),
        },
        ...res.data,
      ];
      setTitles(selectOptions);
      setLoadingState({
        applyMask: false,
      });
    };
    const countriesSuccess = (res) => {
      // console.log(res.data);
      res.data.sort((itemA, itemB) => {
        return itemA.countryName.localeCompare(itemB.countryName);
      });
      // console.log(res);
      setCountries(res.data);
      let session = Utils.getSession();
      if (session?.countries && session?.countries[currentLocale]) {
        // do-nothing
      } else {
        session.countries = {};
        session.countries[currentLocale] = res;
        Utils.store(session, setPageInfo);
      }
      Utils.fetchDataByLocale("GET_TITLES").then(titlesSuccess, commonFail);
    };

    const countriesFail = ({ error }) => {
      Utils.handleDefaultError({
        error,
        setLoadingState,
        setCanRedirectToLogin,
        setCanRedirectToError,
        setPageInfo,
      });
    };

    Utils.getCountries().then(countriesSuccess, countriesFail);

    let validateBtnEle = document.getElementById("validateFormButton");
    let continueBtnEle = document.getElementById("continueBtn");

    setTimeout(function () {
      !isInternalUser && validateBtnEle?.setAttribute("disabled", "");
      continueBtnEle?.setAttribute("disabled", "");
    }, 1);
  }, []);
  useEffect(() => {
    const commonFail = ({ error }) => {
      Utils.handleDefaultError({
        error,
        setLoadingState,
        setCanRedirectToLogin,
        setCanRedirectToError,
        setPageInfo,
      });
    };
    const courierServiceProviderSuccess = (res) => {
      // console.log("courierService result -- ", res.data);
      res.data.sort((itemA, itemB) => {
        return itemA.serviceProviderName.localeCompare(
          itemB.serviceProviderName,
        );
      });
      setCourSerProvdList(res.data);
      setLoadingState({
        applyMask: false,
      });
    };
    if (originalRequested && courSerProvdList.length === 0) {
      setLoadingState({
        applyMask: true,
      });
      Utils.fetchData("COURIER_SERVICE_PROVIDER").then(
        courierServiceProviderSuccess,
        commonFail,
      );
    }
  }, [originalRequested, courSerProvdList]);
  useEffect(() => {
    const commonFail = ({ error }) => {
      Utils.handleDefaultError({
        error,
        setLoadingState,
        setCanRedirectToLogin,
        setCanRedirectToError,
        setPageInfo,
      });
    };
    const relationSuccess = (res) => {
      // console.log("relation result -- ", res.data);
      res.data.sort((itemA, itemB) => {
        return itemA.displayName.localeCompare(itemB.displayName);
      });
      let selectOptions = [
        {
          value: "",
          displayName: t("select"),
        },
        ...res.data,
      ];
      setRelationShipList(selectOptions);
      setLoadingState({
        applyMask: false,
      });
    };
    if (canShowBeneficiaryTable && relationShipList.length === 0) {
      setLoadingState({
        applyMask: true,
      });
      Utils.fetchDataByLocale("GET_PROFILE").then(relationSuccess, commonFail);
    }
  }, [canShowBeneficiaryTable, relationShipList]);

  const deleteTravelEntry = (e) => {
    let dataIndex = parseInt(e.currentTarget.getAttribute("data-index"), 10);
    // console.log(e.currentTarget);

    let detialsArray = [...travelDetailsArray];
    detialsArray.splice(dataIndex, 1);
    // console.log(detialsArray);
    // debugger;
    updateTravelDetailsArray(detialsArray);
    getActiveRecordsCount();
  };

  const submitCertificateInformationForm = (e) => {
    if (canContinue) {
      if (recordsCount <= 0) {
        e.preventDefault();
        // do-nothing
      } else {
        let finalObj = { ...formObj };
        let travelDetailsAry = [...travelDetailsArray];
        finalObj[active] = finalObj[active] ?? {};
        finalObj[active]["travelDetailsArray"] = travelDetailsAry;

        finalObj["path"] = Utils.PAGES[3];
        finalObj["newRecord"] = true;

        // console.log(finalObj);
        // debugger;
        Utils.store(finalObj, setPageInfo);
      }
    }
  };

  const updateCertificateInformationForm = (e) => {
    let finalObj = validateForm();
    // console.log(finalObj);

    // if (Utils.isObjectEmpty(finalObj) || errorMessageKey.length) {
    if (Utils.isObjectEmpty(finalObj)) {
      e.preventDefault();
      // do-nothing
    } else {
      let tempPageInfo = { ...pageInfo };

      tempPageInfo["path"] = Utils.PAGES[3];
      delete tempPageInfo.edit;

      let index1 = pageInfo.edit.index1;
      let index = pageInfo.edit.index;

      let currentRecord =
        tempPageInfo.requests[index1].certificate_information
          .travelDetailsArray[index];
      finalObj["selectionDetails"] = currentRecord.selectionDetails;
      tempPageInfo.requests[index1].certificate_information.travelDetailsArray[
        index
      ] = finalObj;
      // tempPageInfo.requests[index1].certificate_information.travelDetailsArray.splice(index+1, 1);
      tempPageInfo.edit = {};
      // console.log(tempPageInfo);
      // debugger;
      // return false;
      Utils.store(tempPageInfo, setPageInfo, true);
    }
  };

  const goBackToAnotherPage = (pageNum, clearEdit = false) => {
    let finalObj = { path: Utils.PAGES[pageNum] };
    if (clearEdit) {
      finalObj["edit"] = {};
    }
    Utils.store(finalObj, setPageInfo);
  };

  const backToGeneralInformation = (e) => {
    goBackToAnotherPage(1);
  };

  const backToConfirmation = (e) => {
    goBackToAnotherPage(3, true);
  };

  let [isEditMode, setIsEditMode] = useState(pageInfo.editMode ?? false);
  let [isInternalUser, setIsInternalUser] = useState(!Utils.isGuestAccess());

  useEffect(() => {
    if (pageInfo.general_information) {
      setCertInfoTableHeading1(
        pageInfo.general_information.requester +
        "_" +
        pageInfo.general_information.beneficiary,
      );
      setCertInfoBNtableHeading(
        pageInfo.general_information.requester +
        "_" +
        pageInfo.general_information.beneficiary +
        "_bn",
      );
    } else if (!pageInfo.requests) {
      //console.log('executing this !countries.length')
      setCertInfoTableHeading1(
        pageInfo.general_information.requester +
        "_" +
        pageInfo.general_information.beneficiary,
      );
      setCertInfoBNtableHeading(
        pageInfo.general_information.requester +
        "_" +
        pageInfo.general_information.beneficiary +
        "_bn",
      );
    }

    if (countries.length) {
      // console.log(pageInfo);
      let isEdit = !Utils.isObjectEmpty(pageInfo.edit);
      setIsEditMode(isEdit);
      //setIsEditMode(pageInfo.editMode ?? false);
      if (isEdit) {
        let currentRecord =
          pageInfo.requests[pageInfo.edit.index1].certificate_information
            .travelDetailsArray[pageInfo.edit.index];
        setCertInfoTableHeading1(
          currentRecord.selectionDetails.requester +
          "_" +
          currentRecord.selectionDetails.beneficiary,
        );
        setCertInfoBNtableHeading(
          currentRecord.selectionDetails.requester +
          "_" +
          currentRecord.selectionDetails.beneficiary +
          "_bn",
        );
        setCompany(currentRecord.company);
        setLanguage(currentRecord.language);
        setStartDate(currentRecord.startDate);
        setEndDate(currentRecord.endDate);
        setDestinationCountry(currentRecord.destinationCountry);

        updateRegions({
          cValue: currentRecord.destinationCountry,
          isEditMode: true,
          selectedRegionsArray: currentRecord.destinationRegions,
          isCountryCertificateAllowed: true,
          highRiskCountryAccepted: currentRecord.highRiskCountryAccepted ?? false
        });

        setEmpEmail(currentRecord.empEmail);
        setEmpTitle(currentRecord.empTitle || "");
        setEmpFirstName(currentRecord.empFirstName);
        setEmpLastName(currentRecord.empLastName);
        setOriginalRequested(currentRecord.originalRequested);
        setSubsidiary(currentRecord.subsidiary);
        let subsideryCheck = currentRecord.subsidiaryCheck;
        let subsideryCheckValue =
          subsideryCheck === true || subsideryCheck === "on";
        // console.log(subsideryCheckValue);
        setSubsidiaryCheck(subsideryCheckValue);
        setManagerApproved("on");
        // console.log(currentRecord);
        let hasBNTable =
          currentRecord.selectionDetails.beneficiary === "Dependant";
        setCanShowBeneficiaryTable(hasBNTable);
        if (hasBNTable) {
          setBnfTitle(currentRecord.bnfTitle);
          setBnfFirstName(currentRecord.bnfFirstName);
          setBnfLastName(currentRecord.bnfLastName);
          setBnfEmail(currentRecord.bnfEmail);
          setBnfRelation(currentRecord.bnfRelation);
        }
        // debugger;

        let subsidaryCBEle = document.getElementById("subsidiaryCheck");
        subsidaryCBEle.checked = subsideryCheckValue;

        let travellingCBEle = document.getElementById("managerApproved");
        travellingCBEle.checked = true;
        travellingCBEle.setAttribute("disabled", "");

        if (
          currentRecord.originalRequested === "on" ||
          currentRecord.originalRequested === true
        ) {
          let origRequestCBEle = document.getElementById("originalRequested");
          origRequestCBEle.checked = true;

          console.log(currentRecord);
          let cType = currentRecord?.courierType ?? "mail";
          updateCourierType(cType);

          let mailingDivClass = cType === "mail" ? "d-none" : "";
          setTimeout(() => {
            // console.log(mailingDivClass);
            setMailingDiv(mailingDivClass);
          }, 100);

          setCourierServiceProvider(currentRecord.courierServiceProvider);
          setCourierAccountNumber(currentRecord.courierAccountNumber);
          setSdCompanyOrOrganisation(currentRecord.sdCompanyOrOrganisation);
          setNoOfOrigCertificates(currentRecord.noOfOrigCertificates);
          setSdTitle(currentRecord.sdTitle);
          setSdFirstName(currentRecord.sdFirstName);
          setSdLastName(currentRecord.sdLastName);
          setSdAddress(currentRecord.sdAddress);
          setSdCity(currentRecord.sdCity);
          setSdZipCode(currentRecord.sdZipCode);
          setSdPhone(currentRecord.sdPhone);
          setMailingDiv(currentRecord.mailingDiv);
          setOriginalRequested(currentRecord.originalRequested);

          // debugger;
        }
      } else {
        if (isInternalUser) {
          setManagerApproved("on");
          let travellingCBEle = document.getElementById("managerApproved");
          travellingCBEle.checked = true;
          travellingCBEle.setAttribute("disabled", "");
        }
      }
    }
  }, [countries]);

  const getActiveRecordsCount = () => {
    let count = 0;
    pageInfo.requests?.forEach((record) => {
      count += record.certificate_information?.travelDetailsArray?.length ?? 0;
    });
    count += travelDetailsArray?.length ?? 0;
    setRecordsCount(count);
  };

  // useEffect(() => {
  //   let count = 0;
  //   pageInfo.requests?.forEach((record) => {
  //     count += record.certificate_information?.travelDetailsArray?.length ?? 0;
  //   });
  //   count += travelDetailsArray?.length ?? 0;
  //   setRecordsCount(count);
  // }, [pageInfo.requests, travelDetailsArray]);

  // useEffect(() => {
  //   let continueBtnEle = document.getElementById("continueBtn");
  //   if (canContinue) {
  //     continueBtnEle.removeAttribute("disabled");
  //   } else {
  //     continueBtnEle.setAttribute("disabled", "");
  //   }
  // }, [canContinue]);

  useEffect(() => {
    let continueBtnEle = document.getElementById("continueBtn");
    if (continueBtnEle) {
      let count = 0;
      pageInfo.requests?.forEach((record) => {
        count +=
          record.certificate_information?.travelDetailsArray?.length ?? 0;
      });
      count += travelDetailsArray?.length ?? 0;
      setRecordsCount(count);
      // Calculate canContinue based on count
      const newCanContinue = count > 0;
      // Only update canContinue if it has changed
      if (newCanContinue !== canContinue) {
        setCanContinue(newCanContinue);
      }
      if (newCanContinue) {
        continueBtnEle.removeAttribute("disabled");
      } else {
        continueBtnEle.setAttribute("disabled", "");
      }
    }
  }, [canContinue, pageInfo.requests, travelDetailsArray]);

  const changeDateFieldFromInput = (dt) => {
    changeDateInput(dt, setStartDate);
  };

  const changeDateFieldToInput = (dt) => {
    changeDateInput(dt, setEndDate);
  };

  const changeDateInput = (date, setFun) => {
    if (!date) {
      setFun("");
      return;
    }
    let month = (date.getMonth() + 1 + "").padStart(2, 0);
    let dt = (date.getDate() + "").padStart(2, 0);
    let year = date.getFullYear();
    let dateValue = month + "/" + dt + "/" + year;
    // console.log(dateValue);
    setFun(dateValue);
  };

  const changeOrigRequestHandler = (e) => {
    let val = e.currentTarget.checked;
    setOriginalRequested(val);
    resetShippingDetails(val);
  };

  const resetShippingDetails = (val) => {
    setCourierType("mail");

    setCourierServiceProvider("");
    setCourierAccountNumber("");
    setSdCompanyOrOrganisation("");
    setNoOfOrigCertificates(1);
    setSdTitle("");
    setSdFirstName("");
    setSdLastName("");
    setSdAddress("");
    setSdCity("");
    setSdZipCode("");
    setSdPhone("");
    setMailingDiv("d-none");
  };

  const updateCourierType = (val) => {
    setCourierType(val);
    setCourierServiceProvider("");
    setCourierAccountNumber("");
    if (val === "courier") {
      setMailingDiv("");
    } else {
      setMailingDiv("d-none");
    }
  };

  const onChangeCourierType = (e) => {
    let val = e.currentTarget.value;
    updateCourierType(val);
  };

  useEffect(() => { }, [courierType]);

  const changeCertificateCount = (val) => {
    let intVal = parseInt(noOfOrigCertificates, 10);
    setNoOfOrigCertificates(intVal + val);
  };

  const incrementCertificateCount = (e) => {
    changeCertificateCount(1);
  };

  const decrementCertificateCount = (e) => {
    noOfOrigCertificates > 0 && changeCertificateCount(-1);
  };

  const validateZipCode = (zipCode) => {
    const isValidZipCode = /^[0-9]+$/.test(zipCode);
    return isValidZipCode;
  };

  const validatePhoneNumber = (phNumber) => {
    const isValidPhNum = /^(\+\d+)?[\d-]+$/.test(phNumber);
    return isValidPhNum;
  };

  const getDateObjectFromString = (dateString) => {
    // It presumes date object is always of the format -> DD/MM/YYYY
    const [day, month, year] = dateString.split('/').map(Number);
    return new Date(year, month - 1, day);
  }

  const validateExpiryDate = (travelEndDate) => {
    return new Date(travelEndDate) > getDateObjectFromString(pageInfo?.expiryDate);

  }

  const validateEmail = (email) => {
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    return isValidEmail;
  };
  const handleEmailBlur = (e) => {
    const inputEmail = e.currentTarget.value;
    const isValidEmail = validateEmail(inputEmail);

    if (isValidEmail) {
      setEmailError("");
    } else {
      setEmailError(t("emailError"));
    }
  };
  const handleBnfEmailBlur = (e) => {
    const inputEmail = e.currentTarget.value;
    const isValidEmail = validateEmail(inputEmail);
    if (isValidEmail) {
      setBntEmailError("");
    } else {
      setBntEmailError(t("emailError"));
    }
  };

  const updateRegions = ({
    cValue,
    isEditMode = false,
    selectedRegionsArray = [],
    isCountryCertificateAllowed = false,
    highRiskCountryAccepted = false,
  }) => {
    setSelectedRegions([]);
    setCanShowRegions(false);
    setRegions([]);

    let regns = [];
    isCountryCertificateAllowed &&
      countries.forEach((c) => {
        if (cValue === c.countryCode) {
          regns = [...c.regions];
        }
      });

    if (regns.length) {
      setRegions(regns);
      setCanShowRegions(true);
      if (isEditMode) {
        setTimeout(() => {
          regns.forEach((rgn) => {
            if (selectedRegionsArray.indexOf(rgn.regionName) !== -1) {
              document.getElementById(
                rgn.regionName.toLowerCase().replaceAll(" ", ""),
              ).checked = true;
            }
          });
          if (highRiskCountryAccepted) {
            let understandRiskEle = document.getElementById("understandRisk");
            understandRiskEle.checked = highRiskCountryAccepted;
          }
        }, 200);
      }
    } else {
    }
  };

  const countryValidation = (selectedCountryIndex) => {
    // countries
    // console.log("selected/field Index", selectedCountryIndex);
    // console.log("what object is selected", countries[selectedCountryIndex - 1])
    return countries[selectedCountryIndex - 1].certGenerationAllowed;
  };

  const isRiskyCountry = (selectedCountryIndex) => {
    return countries[selectedCountryIndex - 1].highRiskCountry;
  };

  const onChangeCountryUpdate = (
    cValue,
    isCountryCertificateAllowed = false,
  ) => {
    setLoadingState({
      applyMask: false,
    });
    setDestinationCountry(cValue);
    updateRegions({ cValue, isCountryCertificateAllowed });
  };

  let [showModal, setShowModal] = useState(false);
  let [bodyContent, setBodyContent] = useState("");
  let [modalType, setModalType] = useState("info");

  let [ctryValue, setCtryValue] = useState("");
  let [isCtryAllowed, setIsCtryAllowed] = useState(false);
  let [showWarningFlag, setShowWarningFlag] = useState(false);

  const onChangeCountry = (e) => {
    // console.log("countries list", countries);
    setLoadingState({
      applyMask: true,
    });
    // first get the selected country item
    let selctedCountryIndex = e.target.selectedIndex;
    // console.log("selected index", selctedCountryIndex);
    let cValue = e.currentTarget.value;
    setCtryValue(cValue);
    setDestinationCountry(cValue);
    let isCountryCertificateAllowed = false;
    let isRisky = false;
    if (selctedCountryIndex !== 0) {
      isCountryCertificateAllowed = countryValidation(selctedCountryIndex);
      isRisky = isRiskyCountry(selctedCountryIndex);
    }

    setIsCtryAllowed(isCountryCertificateAllowed);

    if (selctedCountryIndex !== 0 && !isCountryCertificateAllowed) {
      const errorFromAPI =
        countries[selctedCountryIndex - 1]?.errorMessage || "";
      setLoadingState({
        applyMask: false,
      });
      if (errorFromAPI) {
        // console.log(errorFromAPI);
        setBodyContent(errorFromAPI);
        let user = Utils.getUserInfo();
        let roles = user?.certifRoles ?? [];
        // console.log(roles);
        setModalType(roles.length > 0 ? "info" : "error");
        setShowModal(true);
      }
      // console.log(errorFromAPI);
      disableRestRiskyFields(true);
    } else {
      disableRestRiskyFields(false);
    }
    setCanShowWarning(isRisky && isCountryCertificateAllowed);
    setShowWarningFlag(!showWarningFlag);
  };

  useEffect(() => {
    // debugger;
    if (isEditMode) {
      enableOrDisableUpdateBtn();
    } else {
      enableOrDisableValidateBtn();
    }
    onChangeCountryUpdate(ctryValue, isCtryAllowed);
  }, [showWarningFlag]);

  const getCountryName = (countryCode) => {
    const country = countries.find(
      (country) => country.countryCode === countryCode,
    );
    return country.countryName;
  };

  const enableOrDisableUpdateBtn = () => {
    let updateBtnEle = document.getElementById("updateBtn");
    let destinationCountryEle = document.getElementById("destinationCountry");
    let destCntryFlag = false;
    destinationCountryEle.selectedIndex !== 0 &&
      (destCntryFlag = countryValidation(destinationCountryEle.selectedIndex));
    if (destCntryFlag) {
      updateBtnEle?.removeAttribute("disabled");
    } else {
      updateBtnEle?.setAttribute("disabled", "");
    }
  };

  const disableRestRiskyFields = (disable = false) => {
    const DISABLED_FIELDS_IDS = ["originalRequested", "endDate", "startDate"];
    DISABLED_FIELDS_IDS.forEach((eleId) => {
      let ele = document.getElementById(eleId);
      if (!disable) {
        ele?.removeAttribute("disabled");
      } else {
        ele?.setAttribute("disabled", "");
      }
    });
  };

  const onZoneSelection = (e) => {
    let cbEle = e.currentTarget;
    let selectedRegionValue = cbEle.id;
    let isChecked = cbEle.checked;
    console.log(selectedRegionValue);
    console.log(isChecked);
    let selectedTempRegions = [...selectedRegions];
    let idx = selectedTempRegions.indexOf(selectedRegionValue);

    if (idx >= 0) {
      selectedTempRegions.splice(idx, 1);
    }

    if (isChecked) {
      let restricted = false;
      let message = "";

      regions.map((region) => {
        let rgnName = region.regionName.toLowerCase().replaceAll(" ", "");
        let selRgnName = selectedRegionValue.toLowerCase().replaceAll(" ", "");

        if (rgnName === selRgnName) {
          restricted = region.restricted;
          message = region.message;
        }
      });

      if (restricted) {
        cbEle.checked = false;
        setBodyContent(message ?? "restricted");
        setModalType("error");
        setShowModal(true);
      } else {
        selectedTempRegions.push(selectedRegionValue);
      }
    }

    console.log(selectedTempRegions);
    setSelectedRegions(selectedTempRegions);
  };

  const onChangeUnderstandRisk = (e) => {
    enableOrDisableValidateBtn();
  };

  return (
    <>
      {/* Error Modal */}
      <CertifAlert
        props={{
          showModal,
          setShowModal,
          modalType,
          bodyContent,
          expiryDateFlag,
        }}
      />
      <div>
        <div className="certif-intro-title">{t("certInfo")}</div>
        <p>{t("certInfoDesc")}</p>

        <Ribbon props={{ active }} />

        <Error props={{ errorMessageArray: errorFields }} />
        <div className="table-responsive">
          <table className="table table-borderless m-0">
            <thead className="certif-table-header">
              <tr>
                <th className="certif-table-header-label" colSpan="3">
                  {/* {t("certInfoTableHeading1")} */}
                  {t(certInfoTableHeading1)}
                </th>
              </tr>
            </thead>
            <tbody
              className={`certif-table-body 
            ${recordsCount >= MAX_RECORDS_ALLOWED && !isEditMode
                  ? "certif-certif-infor-page-prevent-user-pointer"
                  : ""
                }`}
            >
              <tr>
                <td>
                  <label>{t("companyOrOrganisation")}</label>
                  <div>
                    <input
                      type="text"
                      id={"company"}
                      defaultValue={company}
                      readOnly
                    />
                  </div>
                </td>
                <td>
                  <label>{t("subsidiary")}</label>
                  <div>
                    <input
                      type="text"
                      id={"subsidiary"}
                      defaultValue={subsidiary}
                      onChange={(e) => setSubsidiary(e.currentTarget.value)}
                      className="certif-input-field certif-required-field"
                    />
                  </div>
                </td>
                <td>
                  <div className="certif-flex-check-label-wrapper">
                    <input
                      type="checkbox"
                      id={"subsidiaryCheck"}
                      className="certif-input-field"
                      value={subsidiaryCheck}
                      onChange={(e) => setSubsidiaryCheck(e.target.checked)}
                    />
                    <label>{t("subsidiaryCheck")}</label>
                  </div>
                </td>
              </tr>

              <tr>
                <td className="row">
                  <div className="col-sm-3">
                    <label>{t("title")}</label>
                    <div>
                      <select
                        id={"empTitle"}
                        className="certif-input-field"
                        value={empTitle}
                        onChange={(e) => setEmpTitle(e.currentTarget.value)}
                      >
                        {titles?.map((titleItem, index) => (
                          <option key={index} value={titleItem.value}>
                            {titleItem.displayName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="col-sm-9">
                    <label>
                      {t("firstName")}{" "}
                      <span className="certif-required-indicator">*</span>
                    </label>
                    <div>
                      <input
                        type="text"
                        id={"empFirstName"}
                        defaultValue={empFirstName}
                        className="certif-input-field certif-required-field"
                        onChange={(e) => setEmpFirstName(e.currentTarget.value)}
                      />
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <label>
                      {t("lastName")}{" "}
                      <span className="certif-required-indicator">*</span>
                    </label>
                    <div>
                      <input
                        type="text"
                        id={"empLastName"}
                        defaultValue={empLastName}
                        className="certif-input-field certif-required-field"
                        onChange={(e) => setEmpLastName(e.currentTarget.value)}
                      />
                    </div>
                  </div>
                </td>
                <td></td>
              </tr>

              <tr>
                <td>
                  <div>
                    <label>
                      {t("emailAddress")}{" "}
                      <span className="certif-required-indicator">*</span>
                    </label>
                    <div>
                      <input
                        type="email"
                        id={"empEmail"}
                        defaultValue={empEmail}
                        className="certif-input-field certif-required-field"
                        onBlur={handleEmailBlur}
                      />
                      <p className="error-message" style={{ color: "red" }}>
                        {emailError}
                      </p>
                    </div>
                  </div>
                </td>
                {!canShowBeneficiaryTable && (
                  <td>
                    <div>
                      <label>
                        {t("certificateLanguage")}{" "}
                        <span className="certif-required-indicator">*</span>
                      </label>
                      <div>
                        <select
                          id={"language"}
                          className="certif-input-field certif-required-field"
                          value={language}
                          onChange={(e) => setLanguage(e.currentTarget.value)}
                        >
                          <option value="">{t("select")}</option>
                          <option value="en">{t("enLabel")}</option>
                          <option value="fr">{t("frLabel")}</option>
                        </select>
                      </div>
                    </div>
                  </td>
                )}
                <td></td>
              </tr>
            </tbody>

            {canShowBeneficiaryTable && (
              <thead className={`certif-table-header`}>
                <tr>
                  <th className="certif-table-header-label" colSpan="3">
                    <div className="d-flex">
                      {/* {t("certInfoTableHeading22")} */}
                      {/* Your Beneficiary */}
                      {/* t(certInfoBNtableHeading) */}
                      {t(certInfoBNtableHeading)}
                    </div>
                  </th>
                </tr>
              </thead>
            )}
            {canShowBeneficiaryTable && (
              <tbody
                className={`certif-table-body certif-table-body-no-bottom-border 
              ${recordsCount >= MAX_RECORDS_ALLOWED && !isEditMode
                    ? "certif-certif-infor-page-prevent-user-pointer"
                    : ""
                  }`}
              >
                <tr>
                  <td className="row">
                    <div className="col-sm-3">
                      <label>{t("title")}</label>
                      <div>
                        <select
                          id={"bnfTitle"}
                          className="certif-input-field"
                          defaultValue={bnfTitle}
                          onChange={(e) => setBnfTitle(e.currentTarget.value)}
                        >
                          {titles?.map((titleItem, index) => (
                            <option key={index} value={titleItem.value}>
                              {titleItem.displayName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-sm-9">
                      <label>
                        {t("firstName")}{" "}
                        <span className="certif-required-indicator">*</span>
                      </label>
                      <div>
                        <input
                          type="text"
                          id={"bnfFirstName"}
                          defaultValue={bnfFirstName}
                          className="certif-input-field certif-required-field"
                          onChange={(e) =>
                            setBnfFirstName(e.currentTarget.value)
                          }
                        />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      <label>
                        {t("lastName")}{" "}
                        <span className="certif-required-indicator">*</span>
                      </label>
                      <div>
                        <input
                          type="text"
                          id={"bnfLastName"}
                          defaultValue={bnfLastName}
                          className="certif-input-field certif-required-field"
                          onChange={(e) =>
                            setBnfLastName(e.currentTarget.value)
                          }
                        />
                      </div>
                    </div>
                  </td>
                  <td></td>
                </tr>

                <tr>
                  <td>
                    <div>
                      <label>
                        {t("emailAddress")}{" "}
                        <span className="certif-required-indicator">*</span>
                      </label>
                      <div>
                        <input
                          type="email"
                          id={"bnfEmail"}
                          defaultValue={bnfEmail}
                          className="certif-input-field certif-required-field"
                          onBlur={handleBnfEmailBlur}
                          onChange={(e) => setBnfEmail(e.currentTarget.value)}
                        />
                        <p className="error-message" style={{ color: "red" }}>
                          {bnfEmailError}
                        </p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      <label>
                        {t("profile")}{" "}
                        <span className="certif-required-indicator">*</span>
                      </label>
                      <div>
                        <select
                          id={"bnfRelation"}
                          className="certif-input-field certif-required-field"
                          value={bnfRelation}
                          onChange={(e) =>
                            setBnfRelation(e.currentTarget.value)
                          }
                        >
                          {relationShipList?.map((relationItem, index) => (
                            <option key={index} value={relationItem.value}>
                              {relationItem.displayName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>
                    <div>
                      <label>
                        {t("certificateLanguage")}{" "}
                        <span className="certif-required-indicator">*</span>
                      </label>
                      <div>
                        <select
                          id={"language"}
                          className="certif-input-field certif-required-field"
                          value={language}
                          onChange={(e) => setLanguage(e.currentTarget.value)}
                        >
                          <option value="">{t("select")}</option>
                          <option value="en">{t("enLabel")}</option>
                          <option value="fr">{t("frLabel")}</option>
                        </select>
                      </div>
                    </div>
                  </td>
                  <td></td>
                </tr>
              </tbody>
            )}

            <thead className="certif-table-header">
              <tr>
                <th className="certif-table-header-label" colSpan="3">
                  {t("certInfoTableHeading2")}
                </th>
              </tr>
            </thead>
            <tbody
              className={`certif-table-body certif-table-body-no-bottom-border 
            ${recordsCount >= MAX_RECORDS_ALLOWED && !isEditMode
                  ? "certif-certif-infor-page-prevent-user-pointer"
                  : ""
                }`}
            >
              <tr>
                <td colSpan="3">
                  <div
                    className={`certif-flex-check-label-wrapper
                  ${isInternalUser
                        ? "certif-certif-infor-page-managerapproved-display-none"
                        : ""
                      }`}
                  >
                    <input
                      type="checkbox"
                      id={"managerApproved"}
                      className="certif-input-field"
                      onChange={managerApprovedHandler}
                    />
                    <label>{t("travellingCheck")}</label>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <label>
                      {t("destinationCountry")}{" "}
                      <span className="certif-required-indicator">*</span>
                    </label>
                    <div>
                      <select
                        id={"destinationCountry"}
                        value={destinationCountry}
                        className="certif-input-field certif-required-field"
                        onChange={onChangeCountry}
                      // onChange={(e) =>
                      //   setDestinationCountry(e.currentTarget.value)
                      // }
                      >
                        <option value="">{t("select")}</option>

                        {countries?.map((country, index) => (
                          <option key={index} value={country.countryCode}>
                            {country.countryName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </td>
                <td className="row">
                  <div className="col-sm-6">
                    <label>
                      {t("travelDateFrom")}{" "}
                      <span className="certif-required-indicator">*</span>
                    </label>
                    <div className="d-flex">
                      <DatePicker
                        className="certif-input-field certif-required-field"
                        id={"startDate"}
                        selected={!!startDate ? new Date(startDate) : null}
                        onChange={changeDateFieldFromInput}
                        name="startDate"
                        locale={currentLocale}
                        dateFormat="MM/dd/yyyy"
                        minDate={new Date()}
                        maxDate={new Date().setFullYear(
                          new Date().getFullYear() + 1,
                        )}
                      />
                      <img
                        onClick={handleDateImgClick}
                        data-target={"startDate"}
                        className="certif-cal-img"
                        src={CalImg}
                        alt="Date from"
                      />
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <label>
                      {t("travelDateTo")}{" "}
                      <span className="certif-required-indicator">*</span>
                    </label>
                    <div className="d-flex">
                      <DatePicker
                        className="certif-input-field certif-required-field"
                        id={"endDate"}
                        selected={!!endDate ? new Date(endDate) : null}
                        onChange={changeDateFieldToInput}
                        locale={currentLocale}
                        dateFormat="MM/dd/yyyy"
                        minDate={startDate ? new Date(startDate) : new Date()}
                        maxDate={new Date(startDate).setFullYear(
                          new Date(startDate).getFullYear() + 1,
                        )}
                        disabled={!startDate}
                      />
                      <img
                        onClick={handleDateImgClick}
                        data-target={"endDate"}
                        className="certif-cal-img"
                        src={CalImg}
                        alt="Date to"
                      />
                    </div>
                  </div>
                </td>
                <td>
                  <label></label>
                  <div className="certif-flex-check-label-wrapper">
                    <input
                      type="checkbox"
                      id={"originalRequested"}
                      className="certif-input-field"
                      onChange={changeOrigRequestHandler}
                    />
                    <label>{t("originalRequested")}</label>
                    <img
                      src={QuestionImg}
                      alt="help"
                      title={t("OriginalRequestedHelpText")}
                    />
                  </div>
                </td>
              </tr>

              {canShowRegions && (
                <tr>
                  <td>
                    <div
                      className="certif-regions-wrapper"
                      id="certif-regions-wrapper"
                    >
                      <label>
                        {"Regions"}{" "}
                        <span className="certif-required-indicator">*</span>
                      </label>
                      <div className="certif-regions-wrapper">
                        {regions?.map((region, index) => (
                          <div className="certif-region-item" key={index}>
                            <input
                              type="checkbox"
                              id={region.regionName
                                .toLowerCase()
                                .replaceAll(" ", "")}
                              data-value={region.regionValue}
                              className="certif-input-field certif-input-field-region certif-region-cb"
                              // defaultChecked={subsidiaryCheck}
                              onChange={onZoneSelection}
                            />
                            <label>{region.regionName}</label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </td>
                </tr>
              )}

              {(canShowRegions || canShowWarning) && (
                <tr className="certif-tr-rel-wrapper">
                  <td className="certif-td-abs-wrapper">
                    <div className="certif-understand-risk-wrapper">
                      <input
                        type="checkbox"
                        id={"understandRisk"}
                        className="certif-input-field certif-input-field-region certif-required-field"
                        // defaultChecked={subsidiaryCheck}
                        onChange={onChangeUnderstandRisk}
                      />
                      <label>
                        I understand that the location I am travelling to has
                        certain security risks. Before travelling, I will call
                        an International SOS Assistance Centre for pre-trip
                        advice.
                      </label>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>

            {originalRequested && (
              <thead className={`certif-table-header`}>
                <tr>
                  <th className="certif-table-header-label" colSpan="3">
                    <div className="d-flex">
                      {t("certInfoTableHeading22")}
                      <img
                        src={QuestionImg}
                        alt="help"
                        title={t("ShippingHelpText")}
                        className="certif-shipping-details-help-img"
                      />
                    </div>
                  </th>
                </tr>
              </thead>
            )}
            {originalRequested && (
              <tbody
                className={`certif-table-body certif-table-body-no-bottom-border 
              ${recordsCount >= MAX_RECORDS_ALLOWED && !isEditMode
                    ? "certif-certif-infor-page-prevent-user-pointer"
                    : ""
                  }`}
              >
                <tr>
                  <td className="row certif-radio-wrapper-row">
                    <div className="d-flex">
                      <div className="certif-radio-wrapper">
                        <div className="d-flex">
                          <input
                            type="radio"
                            className="certif-input-field"
                            value={"mail"}
                            checked={courierType === "mail"}
                            name={"courierType"}
                            onChange={onChangeCourierType}
                          />

                          <label>{t("byMail")}</label>
                        </div>
                      </div>

                      <div className="certif-radio-wrapper">
                        <div className="d-flex">
                          <input
                            type="radio"
                            className="certif-input-field"
                            value={"courier"}
                            checked={courierType === "courier"}
                            name={"courierType"}
                            onChange={onChangeCourierType}
                          />

                          <label>{t("byCourier")}</label>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td></td>
                </tr>

                <tr className={mailingDiv}>
                  <td>
                    <div>
                      <label>{t("CourierServiceProvider")} </label>
                      <div>
                        <select
                          id={"courierServiceProvider"}
                          className="certif-input-field certif-required-field"
                          value={courierServiceProvider}
                          onChange={(e) =>
                            setCourierServiceProvider(e.currentTarget.value)
                          }
                        >
                          <option value="">{t("select")}</option>
                          {courSerProvdList?.map((courSerProvdItem, index) => (
                            <option
                              key={index}
                              value={courSerProvdItem.serviceProviderName}
                            >
                              {courSerProvdItem.serviceProviderName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      <label>{t("CourierAccountNumber")} </label>
                      <div>
                        <input
                          type="text"
                          id={"courierAccountNumber"}
                          defaultValue={courierAccountNumber}
                          className="certif-input-field certif-required-field"
                          onChange={(e) =>
                            setCourierAccountNumber(e.currentTarget.value)
                          }
                        />
                      </div>
                    </div>
                  </td>
                  <td></td>
                </tr>

                <tr>
                  <td>
                    <div>
                      <label>{t("NumberOfOriginalCertificates")} </label>
                      <div className="d-flex">
                        <input
                          type="text"
                          id={"noOfOrigCertificates"}
                          value={noOfOrigCertificates}
                          className="certif-input-field certif-required-field"
                          readOnly
                        />
                        <div className="certif-up-down-wrapper">
                          <div onClick={incrementCertificateCount}></div>
                          <div onClick={decrementCertificateCount}></div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      <label>{t("companyOrOrganisation")} </label>
                      <div>
                        <input
                          type="text"
                          id={"sdCompanyOrOrganisation"}
                          value={sdCompanyOrOrganisation}
                          className="certif-input-field certif-required-field"
                          onChange={(e) =>
                            setSdCompanyOrOrganisation(e.currentTarget.value)
                          }
                        />
                      </div>
                    </div>
                  </td>
                  <td></td>
                </tr>

                <tr>
                  <td className="row">
                    <div className="col-sm-3">
                      <label>{t("title")}</label>
                      <div>
                        <select
                          id={"sdTitle"}
                          className="certif-input-field"
                          value={sdTitle}
                          onChange={(e) => setSdTitle(e.currentTarget.value)}
                        >
                          {titles?.map((titleItem, index) => (
                            <option key={index} value={titleItem.value}>
                              {titleItem.displayName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-sm-9">
                      <label>
                        {t("firstName")}{" "}
                        <span className="certif-required-indicator">*</span>
                      </label>
                      <div>
                        <input
                          type="text"
                          id={"sdFirstName"}
                          value={sdFirstName}
                          className="certif-input-field certif-required-field"
                          onChange={(e) =>
                            setSdFirstName(e.currentTarget.value)
                          }
                        />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      <label>
                        {t("lastName")}{" "}
                        <span className="certif-required-indicator">*</span>
                      </label>
                      <div>
                        <input
                          type="text"
                          id={"sdLastName"}
                          value={sdLastName}
                          className="certif-input-field certif-required-field"
                          onChange={(e) => setSdLastName(e.currentTarget.value)}
                        />
                      </div>
                    </div>
                  </td>
                  <td></td>
                </tr>

                <tr>
                  <td>
                    <div>
                      <label>{t("Address")} </label>
                      <div className="d-flex">
                        <textarea
                          type="text"
                          id={"sdAddress"}
                          value={sdAddress}
                          className="certif-input-field certif-textarea-input-field "
                          onChange={(e) => setSdAddress(e.currentTarget.value)}
                        ></textarea>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      <label>{t("City")} </label>
                      <div>
                        <input
                          type="text"
                          id={"sdCity"}
                          value={sdCity}
                          className="certif-input-field certif-required-field"
                          onChange={(e) => setSdCity(e.currentTarget.value)}
                        />
                      </div>
                    </div>
                  </td>
                  <td></td>
                </tr>

                <tr>
                  <td>
                    <div>
                      <label>{t("ZipCode")} </label>
                      <div className="d-flex">
                        <input
                          type="text"
                          id={"sdZipCode"}
                          value={sdZipCode}
                          className="certif-input-field certif-required-field"
                          onChange={(e) => setSdZipCode(e.currentTarget.value)}
                        />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      <label>
                        {t("PhoneNumber")}{" "}
                        <span className="certif-required-indicator">*</span>
                      </label>

                      <div>
                        <input
                          type="text"
                          id={"sdPhone"}
                          value={sdPhone}
                          className="certif-input-field certif-required-field"
                          onChange={(e) => setSdPhone(e.currentTarget.value)}
                        />
                      </div>
                    </div>
                  </td>
                  <td></td>
                </tr>
              </tbody>
            )}

            <tbody className="certif-table-body certif-table-body-no-bottom-border">
              {(!isEditMode || isInternalUser) && (
                <tr>
                  <td></td>
                  <td></td>
                  <td>
                    <button
                      id={"validateFormButton"}
                      className="btn btn-secondery btn-sm"
                      onClick={validateCreateForm}
                    >
                      {t("validate")}
                    </button>
                  </td>
                </tr>
              )}
            </tbody>

            {showTravelDetailsTable && (
              <>
                {recordsCount >= MAX_RECORDS_ALLOWED && !isEditMode && (
                  // <Error props={{
                  //   errorMessageArray: {
                  //     fieldId: "maxCertificates",
                  //     errorTypes: ["invalidFormat"],
                  //   }
                  // }} />
                  <div
                    className={`certif-error-wrapper d-flex 
                  ${recordsCount >= MAX_RECORDS_ALLOWED && !isEditMode
                        ? ""
                        : "d-none"
                      }`}
                  >
                    <div className="certif-error d-flex">
                      {/* <img src={ErrorImg} alt="Error" /> */}
                      <span className="certif-error-message">
                        {
                          "Maximum ten certificates can be generated in one request"
                        }
                      </span>
                    </div>
                  </div>
                )}
                <thead className="certif-table-header">
                  <tr>
                    <th className="certif-table-header-label" colSpan="3">
                      {t("certInfoTableHeading3")}
                    </th>
                  </tr>
                </thead>
              </>
            )}
          </table>
          {showTravelDetailsTable && (
            <table className="table table-borderless certif-inner-table m-0">
              <thead className="certif-travel-details-table-header">
                <tr>
                  <th>{t("destinationCountry")}</th>
                  <th>{t("travelDateFrom")}</th>
                  <th>{t("travelDateTo")}</th>
                  <th>{t("originalRequested")}</th>
                  <th>{t("delete")}</th>
                </tr>
              </thead>
              <tbody>
                {travelDetailsArray?.map((detail, index) => (
                  <tr key={index}>
                    <td>{getCountryName(detail.destinationCountry)}</td>
                    <td>{detail.startDate}</td>
                    <td>{detail.endDate}</td>
                    <td>
                      {detail.originalRequested === true && (
                        <img src={TickImg} alt="Requested on" />
                      )}
                      {detail.originalRequested === false && (
                        <img src={RemoveImg} alt="Requested off" />
                      )}
                    </td>
                    <td>
                      <img
                        role="button"
                        onClick={deleteTravelEntry}
                        className="certif-travel-entry-delete"
                        src={DeleteImg}
                        alt="delete"
                        data-index={index}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          {!isEditMode && (
            <table className="table table-borderless m-0">
              <tfoot className="certif-table-footer">
                <tr>
                  <th colSpan="3">
                    <button
                      id={"continueBtn"}
                      className="btn btn-primary btn-sm"
                      onClick={submitCertificateInformationForm}
                    >
                      {t("continue")}
                    </button>
                    <button
                      className="btn btn-secondery btn-sm certif-align-btn-left"
                      onClick={backToGeneralInformation}
                    >
                      {t("back")}
                    </button>
                  </th>
                </tr>
              </tfoot>
            </table>
          )}

          {isEditMode && (
            <table className="table table-borderless m-0">
              <tfoot className="certif-table-footer">
                <tr>
                  <th colSpan="3">
                    <button
                      id={"updateBtn"}
                      className="btn btn-primary btn-sm"
                      onClick={updateCertificateInformationForm}
                    >
                      {t("updateLabel")}
                    </button>
                    <button
                      className="btn btn-secondery btn-sm certif-align-btn-left"
                      onClick={backToConfirmation}
                    >
                      {t("cancelLabel")}
                    </button>
                  </th>
                </tr>
              </tfoot>
            </table>
          )}
        </div>
      </div>
    </>
  );
}

export default CertificateInformation;
