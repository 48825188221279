import { useState, useEffect, useContext } from "react";
import { Routes, Route } from "react-router-dom";

import CurrencyPage from "../Currency/CurrencyPage";
import CourierServicePage from "../CourierServiceProvider/CourierServicePage";
import Utils from "../../../controls/Utils";
import ErrorPage from "../../utilpages/ErrorPage";

function AdminSection5Body() {
  let [canRender, setCanRender] = useState(false);

  useEffect(() => {
    setCanRender(true);
  }, []);
  console.log(
    Utils.APP_URLS.CURRENCY_MANAGEMENT.replace(
      Utils.APP_URLS.ADMIN_PAGE_SECTION_5,
      "",
    ),
  );
  return (
    <>
      {canRender && (
        <Routes>
          <Route path={`/`} exact element={<CurrencyPage />}></Route>
          <Route
            path={Utils.APP_URLS.CURRENCY_MANAGEMENT.replace(
              Utils.APP_URLS.ADMIN_PAGE_SECTION_5,
              "",
            )}
            element={<CurrencyPage />}
          ></Route>
          <Route
            path={Utils.APP_URLS.COURIER_SERVICE_PROVIDER.replace(
              Utils.APP_URLS.ADMIN_PAGE_SECTION_5,
              "",
            )}
            element={<CourierServicePage />}
          ></Route>
          <Route
            path={`*`}
            element={<ErrorPage props={{ error: 404 }} />}
          ></Route>
        </Routes>
      )}
    </>
  );
}

export default AdminSection5Body;
