import { useState, useEffect, useRef, useContext } from "react";
import { Table } from "react-bootstrap";

import AppContextComponent from "../../../../controls/AppContext";
import Utils from "./../../../../controls/Utils";

import ARROW_UP_IMG from "./../../../../assets/img/arrow.up.svg";
import FILLED_ARROW_UP_IMG from "./../../../../assets/img/arrow.up.fill.svg";
import ARROW_DOWN_IMG from "./../../../../assets/img/arrow.down.svg";
import FILLED_ARROW_DOWN_IMG from "./../../../../assets/img/arrow.down.fill.svg";

function RegionList({ props }) {
  // console.log(props);
  let ctryCode = props.countryCode;
  let [countryCode, setCountryCode] = useState(ctryCode);

  let [canRender, setCanRender] = useState(true);

  let [fields, setFields] = useState([]);
  let [templates, setTemplates] = useState([]);
  let [filteredTemplates, setFilteredTemplates] = useState([]);
  const SORTABLE_FIELDS = [
    "language",
    "regionCode",
    "regionName",
  ];
  useEffect(() => {
    setFields(props.fields ?? []);
    setTemplates(props.regions ?? []);
    setFilteredTemplates(props.regions ?? []);
  }, [props.fields, props.regions]);

  const gotoAddPage = (e) => {
    props.setPageMode("add");
  };

  const gotoEditPage = (e) => {
    const dataId = e.target.getAttribute('data-id');
    // console.log(dataId);
    // console.log(typeof (dataId));

    const index = templates.findIndex((template) => template.id === Number(dataId));
    if (index !== -1) {
      props.setRecordIndex(index);
      props.setPageMode("edit");
    }
  };

  const filterTable = (e) => {
    Utils.filterTableData({
      event: e,
      templates,
      setTemplates,
      filteredTemplates,
      setFilteredTemplates,
    });
  };

  const sortTable = (e) => {
    Utils.sortTable({
      event: e,
      templates,
      setTemplates,
      filteredTemplates,
      setFilteredTemplates,
    });
  };

  return (
    <>
      {canRender && (
        <>
          <div className="certif-right-search-wrapper">
            <input
              type="text"
              // placeholder={`Search ${countryCode} Regions`}
              placeholder={Utils.PLACEHOLDER_4_SEARCH_IN_ADMIN_SCREENS}
              onChange={filterTable}
              sort-type=""
              sort-key=""
              id="certif-regions-filter-id"
            />
          </div>
          <div className="table-responsive certif-table-responsive-with-search">
            <div className="certif-bar-wrapper">
              <div className="certif-bar-left">Regions - {countryCode}</div>
              <div className="certif-bar-right" onClick={gotoAddPage}>
                +
              </div>
            </div>

            <div className="d-flex certif-table-with-scrolls">
              <Table stripped="" className="m-0" bordered hover size="md">
                <thead>
                  <tr>
                    <th
                      width=""
                      id="language_th"
                      className="certif-table-sort-col-wrapper"
                    >
                      <span className="certif-table-sort-col-name">
                        Language
                      </span>
                      <span className="certif-table-sort-wrapper">
                        <span
                          className="certif-table-sort-up"
                          sort-col-id="language"
                          sort-col-interactive-id="certif-regions-filter-id"
                          sort-col-type="asc"
                          onClick={sortTable}
                        ></span>
                        <span
                          className="certif-table-sort-down"
                          sort-col-id="language"
                          sort-col-interactive-id="certif-regions-filter-id"
                          sort-col-type="desc"
                          onClick={sortTable}
                        ></span>
                      </span>
                    </th>
                    <th
                      width=""
                      id="regionCode_th"
                      className="certif-table-sort-col-wrapper"
                    >
                      <span className="certif-table-sort-col-name">
                        Region code
                      </span>
                      <span className="certif-table-sort-wrapper">
                        <span
                          className="certif-table-sort-up"
                          sort-col-id="regionCode"
                          sort-col-interactive-id="certif-regions-filter-id"
                          sort-col-type="asc"
                          onClick={sortTable}
                        ></span>
                        <span
                          className="certif-table-sort-down"
                          sort-col-id="regionCode"
                          sort-col-interactive-id="certif-regions-filter-id"
                          sort-col-type="desc"
                          onClick={sortTable}
                        ></span>
                      </span>
                    </th>
                    <th
                      width=""
                      id="regionName_th"
                      className="certif-table-sort-col-wrapper"
                    >
                      <span className="certif-table-sort-col-name">
                        Region Name
                      </span>
                      <span className="certif-table-sort-wrapper">
                        <span
                          className="certif-table-sort-up"
                          sort-col-id="regionName"
                          sort-col-interactive-id="certif-regions-filter-id"
                          sort-col-type="asc"
                          onClick={sortTable}
                        ></span>
                        <span
                          className="certif-table-sort-down"
                          sort-col-id="regionName"
                          sort-col-interactive-id="certif-regions-filter-id"
                          sort-col-type="desc"
                          onClick={sortTable}
                        ></span>
                      </span>
                    </th>
                    {/* <th width="">Language</th> */}
                    {/* <th width="">Region code</th> */}
                    {/* <th width="">Region Name</th> */}
                    <th width="">isRestricted?</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredTemplates?.map((template, templateIndex) => (
                    <tr
                      key={templateIndex}
                      className="certif-admin-template-item-row"
                    >
                      {fields.map(
                        (field, fieldIndex) =>
                          fieldIndex < 4 && (
                            <td
                              id={templateIndex}
                              key={fieldIndex}
                              data-id={template.id}
                              onClick={gotoEditPage}
                            >
                              {field === "language" && (
                                <>{props.languagesObj[template[field]]}</>
                              )}
                              {field === "regionCode" && <>{template[field]}</>}
                              {field === "regionName" && <>{template[field]}</>}
                              {field === "restricted" && (
                                <>{template[field] ? "Yes" : "No"}</>
                              )}
                            </td>
                          ),
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default RegionList;
