import { useState, useEffect, useContext } from "react";
import { Routes, Route } from "react-router-dom";

import AdminEmailTemplatePage from "./../AdminEmailTemplatePage";
import LegalEntityPage from "../LegalEntity/LegalEntityPage";
import ErrorPage from "../../utilpages/ErrorPage";

import Utils from "../../../controls/Utils";

function AdminSection1Body() {
  let [canRender, setCanRender] = useState(false);

  useEffect(() => {
    setCanRender(true);
  }, []);

  let legalEntityPath = Utils.APP_URLS.LEGAL_ENTITY.replace(
    Utils.APP_URLS.ADMIN_PAGE_SECTION_1,
    "",
  );

  let templatePath = Utils.APP_URLS.EMAIL_TEMPLATE.replace(
    Utils.APP_URLS.ADMIN_PAGE_SECTION_1,
    "",
  );

  return (
    <>
      {canRender && (
        <Routes>
          <Route path={`/`} exact element={<LegalEntityPage />}></Route>
          <Route
            path={legalEntityPath}
            element={<LegalEntityPage />}
          ></Route>
          <Route
            path={templatePath}
            element={<AdminEmailTemplatePage />}
          ></Route>
          <Route path={`*`} element={<ErrorPage props={{error: 404}}/>}></Route>
        </Routes>
      )}
    </>
  );
}

export default AdminSection1Body;
