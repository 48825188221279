// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.certif-error-form-wrapper {
    color: #2f4694;
    font-size: 16px;
}

.certif-error-form-text-error {
	line-height: 1.4;
	color: #0072C6;
    font-size: 22px;
	font-weight: 300;
}

.certif-add-footer {
    margin-top: 15px;
}

.certif-add-footer button {
    color: #60464675;
}

.certif-add-footer button:focus,
.certif-add-footer button:hover {
    color: #604646;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/error.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,eAAe;AACnB;;AAEA;CACC,gBAAgB;CAChB,cAAc;IACX,eAAe;CAClB,gBAAgB;AACjB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;;IAEI,cAAc;AAClB","sourcesContent":[".certif-error-form-wrapper {\n    color: #2f4694;\n    font-size: 16px;\n}\n\n.certif-error-form-text-error {\n\tline-height: 1.4;\n\tcolor: #0072C6;\n    font-size: 22px;\n\tfont-weight: 300;\n}\n\n.certif-add-footer {\n    margin-top: 15px;\n}\n\n.certif-add-footer button {\n    color: #60464675;\n}\n\n.certif-add-footer button:focus,\n.certif-add-footer button:hover {\n    color: #604646;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
