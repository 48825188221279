// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.certif-footer-wrapper {
    font-size: 12px;
}

.certif-footer-wrapper a,
.certif-footer-wrapper a:hover {
    color: #000;
    text-decoration: none;
}


.certif-footer-wrapper a:hover {
    text-decoration: underline;
}

.certif-footer-rightside-link:last-child {
    margin-left: 15px;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/footer.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;;IAEI,WAAW;IACX,qBAAqB;AACzB;;;AAGA;IACI,0BAA0B;AAC9B;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".certif-footer-wrapper {\n    font-size: 12px;\n}\n\n.certif-footer-wrapper a,\n.certif-footer-wrapper a:hover {\n    color: #000;\n    text-decoration: none;\n}\n\n\n.certif-footer-wrapper a:hover {\n    text-decoration: underline;\n}\n\n.certif-footer-rightside-link:last-child {\n    margin-left: 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
