import { useState, useEffect, useContext } from "react";
import { Routes, Route } from "react-router-dom";

import Utils from "../../../controls/Utils";
import UserManagementPage from "../User/UserManagementPage";
import ErrorPage from "../../utilpages/ErrorPage";

function AdminSection8Body() {
  let [canRender, setCanRender] = useState(false);

  useEffect(() => {
    setCanRender(true);
  }, []);

  return (
    <>
      {canRender && (
        <Routes>
          <Route path={`/`} exact element={<UserManagementPage />}></Route>
          <Route
            path={Utils.APP_URLS.USER_MANAGEMENT.replace(
              Utils.APP_URLS.ADMIN_PAGE_SECTION_8,
              "",
            )}
            element={<UserManagementPage />}
          ></Route>
          <Route
            path={`*`}
            element={<ErrorPage props={{ error: 404 }} />}
          ></Route>
        </Routes>
      )}
    </>
  );
}

export default AdminSection8Body;
