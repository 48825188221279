import { useState, useEffect, useContext } from "react";
import { Routes, Route } from "react-router-dom";

import Utils from "../../../controls/Utils";
import SellingOfficePage from "../SellingOffice/SellingOfficePage";
import ErrorPage from "../../utilpages/ErrorPage";

function AdminSection10Body() {
  let [canRender, setCanRender] = useState(false);

  useEffect(() => {
    setCanRender(true);
  }, []);

  return (
    <>
      {canRender && (
        <Routes>
          <Route path={`/`} exact element={<SellingOfficePage />}></Route>
          <Route
            path={Utils.APP_URLS.SELLING_OFFICE_MANAGEMENT.replace(
              Utils.APP_URLS.ADMIN_PAGE_SECTION_10,
              "",
            )}
            element={<SellingOfficePage />}
          ></Route>
          <Route
            path={`*`}
            element={<ErrorPage props={{ error: 404 }} />}
          ></Route>
        </Routes>
      )}
    </>
  );
}

export default AdminSection10Body;
