import { useState, useEffect, useRef, useContext } from "react";
import { Table, Modal } from "react-bootstrap";

import DetailsDialog from "./Details/DetailsDialog";
import EditImg from "./../../../assets/img/icon-modify.png";

import Utils from "../../../controls/Utils";

import ARROW_UP_IMG from "./../../../assets/img/arrow.up.svg";
import FILLED_ARROW_UP_IMG from "./../../../assets/img/arrow.up.fill.svg";
import ARROW_DOWN_IMG from "./../../../assets/img/arrow.down.svg";
import FILLED_ARROW_DOWN_IMG from "./../../../assets/img/arrow.down.fill.svg";

function MessagesList({ props }) {
    const submitHandler = (e) => { };

    let [fields, setFields] = useState([]);
    let [templates, setTemplates] = useState([]);
    let [filteredTemplates, setFilteredTemplates] = useState([]);
    const SORTABLE_FIELDS = [
        "messageCode",
        "description",
    ];

    useEffect(() => {
        setFields(props.fields);
        setTemplates(props.templates);
        setFilteredTemplates(props.templates);
    }, [props.fields, props.templates]);

    const gotoAddPage = (e) => {
        props.setPageMode("add");
    };

    let [addModalCanOpen, setAddModalCanOpen] = useState(false);
    let [showDetailsTable, setShowDetailsTable] = useState(false);
    let [modalTitle, setModalTitle] = useState("");
    let [messagesRecord, setMessagesRecord] = useState({});

    const gotoEditPage = (e) => {
        let dataKey = e.currentTarget.getAttribute("data-key");
        // console.log(dataKey);
        if (dataKey === "details") {
            let currentRecord = filteredTemplates[parseInt(e.currentTarget.id, 10)];
            // console.log(currentRecord);
            setMessagesRecord(currentRecord);
            setModalTitle("Message code - " + currentRecord.messageCode);
            setShowDetailsTable(true);
            // debugger;
            setAddModalCanOpen(true);
        } else {
            const dataId = e.target.getAttribute('data-id');
            // console.log(dataId);
            // console.log(typeof (dataId));

            const index = templates.findIndex((template) => template.id === Number(dataId));
            if (index !== -1) {
                props.setRecordIndex(index);
                props.setPageMode("edit");
            }
        }
    };

    const handleClose = (e) => {
        if (showDetailsTable) {
            setShowDetailsTable(false);
        }
        setAddModalCanOpen(false);
    };

    const filterTable = (e) => {
        Utils.filterTableData({
            event: e,
            templates,
            setTemplates,
            filteredTemplates,
            setFilteredTemplates,
        });
    };

    const sortTable = (e) => {
        Utils.sortTable({
            event: e,
            templates,
            setTemplates,
            filteredTemplates,
            setFilteredTemplates,
        });
    };

    return (
        <>
            <Modal centered show={addModalCanOpen} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {showDetailsTable && <DetailsDialog props={{ messagesRecord }} />}
                </Modal.Body>
            </Modal>
            <div className="certif-right-search-wrapper">
                <input
                    type="text"
                    placeholder={Utils.PLACEHOLDER_4_SEARCH_IN_ADMIN_SCREENS}
                    onChange={filterTable}
                    sort-type=""
                    sort-key=""
                    id="certif-messages-filter-id"
                />
            </div>
            <div className="table-responsive certif-table-responsive-with-search">
                <div className="certif-bar-wrapper">
                    <div className="certif-bar-left">Messages Management</div>
                    <div className="certif-bar-right" onClick={gotoAddPage}>
                        +
                    </div>
                </div>

                <div className="d-flex certif-table-with-scrolls">
                    <Table stripped="" className="m-0" bordered hover size="md">
                        <thead>
                            <tr>
                                <th
                                    width=""
                                    id="messageCode_th"
                                    className="certif-table-sort-col-wrapper"
                                >
                                    <span className="certif-table-sort-col-name">
                                        Message Code
                                    </span>
                                    <span className="certif-table-sort-wrapper">
                                        <span
                                            className="certif-table-sort-up"
                                            sort-col-id="messageCode"
                                            sort-col-interactive-id="certif-messages-filter-id"
                                            sort-col-type="asc"
                                            onClick={sortTable}
                                        ></span>
                                        <span
                                            className="certif-table-sort-down"
                                            sort-col-id="messageCode"
                                            sort-col-interactive-id="certif-messages-filter-id"
                                            sort-col-type="desc"
                                            onClick={sortTable}
                                        ></span>
                                    </span>
                                </th>
                                <th
                                    width=""
                                    id="description_th"
                                    className="certif-table-sort-col-wrapper"
                                >
                                    <span className="certif-table-sort-col-name">
                                        Description
                                    </span>
                                    <span className="certif-table-sort-wrapper">
                                        <span
                                            className="certif-table-sort-up"
                                            sort-col-id="description"
                                            sort-col-interactive-id="certif-messages-filter-id"
                                            sort-col-type="asc"
                                            onClick={sortTable}
                                        ></span>
                                        <span
                                            className="certif-table-sort-down"
                                            sort-col-id="description"
                                            sort-col-interactive-id="certif-messages-filter-id"
                                            sort-col-type="desc"
                                            onClick={sortTable}
                                        ></span>
                                    </span>
                                </th>
                                {/* <th width="">Message code</th> */}
                                {/* <th width="">Description</th> */}
                                <th width="">Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredTemplates.map((template, templateIndex) => (
                                <tr
                                    key={templateIndex}
                                    className="certif-admin-template-item-row"
                                >
                                    {fields.map((field, fieldIndex) => (
                                        <td
                                            id={templateIndex}
                                            key={fieldIndex}
                                            data-id={template.id}
                                            onClick={gotoEditPage}
                                            data-key={field === "details" ? field : ""}
                                        // className={field === "details" ? "certif-table-center-col" : ""}
                                        >
                                            {field === "messageCode" && <>{template[field]}</>}
                                            {field === "description" && <>{template[field]}</>}
                                            {field === "details" && (
                                                <span className="">
                                                    <img src={EditImg} alt="Edit" />
                                                </span>
                                            )}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>

                <div className="certif-bar-wrapper">
                    <div className="certif-bar-right-buttons">
                        {/* <button className="btn btn-primary btn-sm" onClick={submitHandler}>
              Save
            </button> */}
                    </div>
                </div>
            </div>
        </>
    );
}

export default MessagesList;
