import { useState, useEffect, useRef, useContext } from "react";
import { useTranslation, Trans } from "react-i18next";

import Utils from "../../../controls/Utils";
import AppContextComponent from "../../../controls/AppContext";
import NavEventMixin from "../../utilpages/NavEventMixin";

import LegalEntityList from "./LegalEntityList";
import AddEditLegalEntity from "./AddEditLegalEntity";

function LegalEntityPage() {
  let {
    setPageInfo,
    setLoadingState,
    setCanRedirectToLogin,
    setCanRedirectToError,
  } = useContext(AppContextComponent);

  let [canRender, setCanRender] = useState(false);
  let [pageMode, setPageMode] = useState("list");
  let [templates, setTemplates] = useState([]);
  let [fields, setFields] = useState([
    "entityId",
    "name",
    "sellingOfficeId",
    "building",
    "streetName",
    "letterHead",
    "cityName",
    "countryName",
    "postalCode",
    "email",
    "contactNumber",
    "gmName",
  ]);
  let [recordIndex, setRecordIndex] = useState(-1);
  let [sellingOfficeOptions, setSellingOfficeOptions] = useState([]);

  let tempNoOfRecords = 7;

  useEffect(() => {
    setLoadingState({
      applyMask: true,
    });
    const fetchSuccess = (res) => {
      let tempTemplates = [...res.data];
      setTemplates(tempTemplates);
      setCanRender(true);
      setLoadingState({
        applyMask: false,
      });
    };

    const fetchFail = ({ error }) => {
      Utils.handleDefaultError({
        error,
        setLoadingState,
        setCanRedirectToLogin,
        setCanRedirectToError,
        setPageInfo,
      });
    };

    const sellingOfficeDropdownSuccess = (res) => {
      res.data.sort((itemA, itemB) => {
        return itemA.sellingOfficeName.localeCompare(itemB.sellingOfficeName);
      });
      let selectOptions = [
        {
          sellingOfficeId: "",
          sellingOfficeName: "Select",
        },
        ...res.data,
      ];
      setSellingOfficeOptions(selectOptions);
      Utils.getLegalEntityTemplates().then(fetchSuccess, fetchFail);
    }

    Utils.fetchData("SELLING_OFFICE").then(sellingOfficeDropdownSuccess, fetchFail);
  }, []);

  useEffect(() => { }, [fields, templates, pageMode]);

  return (
    <>
      {canRender && (
        <div className="table-responsive">
          {(pageMode === "add" || pageMode === "edit") && (
            <NavEventMixin
              props={{
                pageMode,
                setPageMode,
              }}
            />
          )}
          {pageMode === "list" && (
            <LegalEntityList
              props={{
                fields,
                templates,
                setPageMode,
                recordIndex,
                setRecordIndex,
                sellingOfficeOptions,
              }}
            />
          )}
          {pageMode === "add" && (
            <AddEditLegalEntity
              props={{
                fields,
                templates,
                setTemplates,
                setPageMode,
                mode: "add",
                tempNoOfRecords,
                sellingOfficeOptions,
              }}
            />
          )}
          {pageMode === "edit" && (
            <AddEditLegalEntity
              props={{
                fields,
                templates,
                setTemplates,
                setPageMode,
                recordIndex,
                tempNoOfRecords,
                setRecordIndex,
                mode: "edit",
                sellingOfficeOptions,
              }}
            />
          )}
        </div>
      )}
    </>
  );
}

export default LegalEntityPage;
