import { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Security } from "@okta/okta-react";
import config from "./config/app.config";
import "./App.scss";
import MainPage from "./pages/MainPage";

const oktaAuth = new OktaAuth(config.oidc);

function App() {
  useEffect(() => {
    document.body.classList.add("indi-app");
  });
  const restoreOriginalUri = "/";
  return (
    <Security
      oktaAuth={oktaAuth}
      restoreOriginalUri={restoreOriginalUri}
    >
      <BrowserRouter basename="/">
        <MainPage />
      </BrowserRouter>
    </Security>
  );
}

export default App;
