// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.certif-nav-items-wrapper {
    /* background: #fff; */
    padding: 8px 16px;
    align-self: center;
}

.certif-nav-items-wrapper.certif-active-nav-item {
    background: #fff;
}

.certif-arrow-up {
    display: none;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #00519D;
}

.certif-active-nav-item .certif-arrow-up {
    display: block;
}

.certif-set-buttom {
    position: relative;
    top: 8px;
    left: calc(50% - 8px);
}

.certif-nav-item {
    font-size: 13px;
    line-height: 20px;
    vertical-align: middle;
    /* margin-top: 4px; */
    margin-top: 0;
}

.certif-active-nav-item .certif-nav-item {
    top: 4px;
    position: relative;
}

.certif-nav-items-wrapper {
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/navigation.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,QAAQ;IACR,SAAS;IACT,kCAAkC;IAClC,mCAAmC;IACnC,gCAAgC;AACpC;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,qBAAqB;AACzB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,sBAAsB;IACtB,qBAAqB;IACrB,aAAa;AACjB;;AAEA;IACI,QAAQ;IACR,kBAAkB;AACtB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".certif-nav-items-wrapper {\n    /* background: #fff; */\n    padding: 8px 16px;\n    align-self: center;\n}\n\n.certif-nav-items-wrapper.certif-active-nav-item {\n    background: #fff;\n}\n\n.certif-arrow-up {\n    display: none;\n    width: 0;\n    height: 0;\n    border-left: 8px solid transparent;\n    border-right: 8px solid transparent;\n    border-bottom: 8px solid #00519D;\n}\n\n.certif-active-nav-item .certif-arrow-up {\n    display: block;\n}\n\n.certif-set-buttom {\n    position: relative;\n    top: 8px;\n    left: calc(50% - 8px);\n}\n\n.certif-nav-item {\n    font-size: 13px;\n    line-height: 20px;\n    vertical-align: middle;\n    /* margin-top: 4px; */\n    margin-top: 0;\n}\n\n.certif-active-nav-item .certif-nav-item {\n    top: 4px;\n    position: relative;\n}\n\n.certif-nav-items-wrapper {\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
