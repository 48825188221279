import { useState, useEffect, useRef, useContext } from "react";
import { Table } from "react-bootstrap";

import Utils from "../../../controls/Utils";
import AppContextComponent from "../../../controls/AppContext";

import ARROW_UP_IMG from "./../../../assets/img/arrow.up.svg";
import FILLED_ARROW_UP_IMG from "./../../../assets/img/arrow.up.fill.svg";
import ARROW_DOWN_IMG from "./../../../assets/img/arrow.down.svg";
import FILLED_ARROW_DOWN_IMG from "./../../../assets/img/arrow.down.fill.svg";

function MarketingContentList({ props }) {
    // let fields = props.fields;
    // let templates = props.templates;

    let { setLoadingState, setCanRedirectToLogin, setCanRedirectToError, setPageInfo } = useContext(AppContextComponent);
    let [fields, setFields] = useState([]);
    let [templates, setTemplates] = useState([]);
    let [filteredTemplates, setFilteredTemplates] = useState([]);
    let [sellingOfficeIdOptions, setSellingOfficeIdOptions] = useState([]);
    let [canRender, setCanRender] = useState(false);
    const SORTABLE_FIELDS = [
        "language",
        "sellingOfficeId",
    ];

    useEffect(() => {
        setFields(props.fields);
        setTemplates(props.templates);
        setFilteredTemplates(props.templates);
    }, [props.fields, props.templates]);

    useEffect(() => {
        setLoadingState({
            applyMask: true,
        });
        const commonFail = ({ error }) => {
            Utils.handleDefaultError({
                error,
                setLoadingState,
                setCanRedirectToLogin,
                setCanRedirectToError,
                setPageInfo,
            });
        };

        const sellingOfficeSuccess = (res) => {
            // console.log("Selling office id", res.data)
            setSellingOfficeIdOptions(res.data);
            setCanRender(true);
            setLoadingState({
                applyMask: false,
            });
        };

        Utils.fetchData("SELLING_OFFICE").then(sellingOfficeSuccess, commonFail);
    }, []);

    const findSellingNameById = (selinOffcId) => {
        const selinOffc = sellingOfficeIdOptions.find(office => office.sellingOfficeId === selinOffcId);
        return selinOffc.sellingOfficeName;
    }

    const gotoAddPage = (e) => {
        props.setPageMode("add");
    };

    const gotoEditPage = (e) => {
        const dataId = e.target.getAttribute('data-id');
        // console.log(dataId);
        // console.log(typeof (dataId));

        const index = templates.findIndex((template) => template.id === Number(dataId));
        if (index !== -1) {
            props.setRecordIndex(index);
            props.setPageMode("edit");
        }
    };

    const filterTable = (e) => {
        Utils.filterTableData({
            event: e,
            templates,
            setTemplates,
            filteredTemplates,
            setFilteredTemplates,
        });
    };

    const sortTable = (e) => {
        Utils.sortTable({
            event: e,
            templates,
            setTemplates,
            filteredTemplates,
            setFilteredTemplates,
        });
    };

    return (
        <>
            {canRender && (
                <>
                    <div className="certif-right-search-wrapper">
                        <input
                            type="text"
                            placeholder={Utils.PLACEHOLDER_4_SEARCH_IN_ADMIN_SCREENS}
                            onChange={filterTable}
                            sort-type=""
                            sort-key=""
                            id="certif-marketing-content-filter-id"
                        />
                    </div >
                    <div className="table-responsive certif-table-responsive-with-search">
                        <div className="certif-bar-wrapper">
                            <div className="certif-bar-left">Marketing Content</div>
                            <div className="certif-bar-right" onClick={gotoAddPage}>
                                +
                            </div>
                        </div>

                        <div className="d-flex certif-table-with-scrolls">
                            <Table stripped="" className="m-0" bordered hover size="md">
                                <thead>
                                    <tr>
                                        <th
                                            width=""
                                            id="language_th"
                                            className="certif-table-sort-col-wrapper"
                                        >
                                            <span className="certif-table-sort-col-name">
                                                Language
                                            </span>
                                            <span className="certif-table-sort-wrapper">
                                                <span
                                                    className="certif-table-sort-up"
                                                    sort-col-id="language"
                                                    sort-col-interactive-id="certif-marketing-content-filter-id"
                                                    sort-col-type="asc"
                                                    onClick={sortTable}
                                                ></span>
                                                <span
                                                    className="certif-table-sort-down"
                                                    sort-col-id="language"
                                                    sort-col-interactive-id="certif-marketing-content-filter-id"
                                                    sort-col-type="desc"
                                                    onClick={sortTable}
                                                ></span>
                                            </span>
                                        </th>
                                        <th
                                            width=""
                                            id="sellingOfficeId_th"
                                            className="certif-table-sort-col-wrapper"
                                        >
                                            <span className="certif-table-sort-col-name">
                                                Selling Office Name
                                            </span>
                                            <span className="certif-table-sort-wrapper">
                                                <span
                                                    className="certif-table-sort-up"
                                                    sort-col-id="sellingOfficeId"
                                                    sort-col-interactive-id="certif-marketing-content-filter-id"
                                                    sort-col-type="asc"
                                                    onClick={sortTable}
                                                ></span>
                                                <span
                                                    className="certif-table-sort-down"
                                                    sort-col-id="sellingOfficeId"
                                                    sort-col-interactive-id="certif-marketing-content-filter-id"
                                                    sort-col-type="desc"
                                                    onClick={sortTable}
                                                ></span>
                                            </span>
                                        </th>
                                        {/* <th width="">Language</th> */}
                                        {/* <th width="">Selling Office Id</th> */}
                                        <th width="">Marketing Content</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredTemplates.map((template, templateIndex) => (
                                        <tr
                                            key={templateIndex}
                                            className="certif-admin-template-item-row"
                                        >
                                            {
                                                fields.map(
                                                    (field, fieldIndex) =>
                                                        fieldIndex < 3 && (
                                                            <td
                                                                id={templateIndex}
                                                                key={fieldIndex}
                                                                data-id={template.id}
                                                                onClick={gotoEditPage}
                                                            >
                                                                {field === 'language' && <>{template[field]}</>}
                                                                {field === 'sellingOfficeId' && <>{findSellingNameById(template[field])}</>}
                                                                {field === 'marketingContent' && <>{template[field]}</>}
                                                            </td>
                                                        ),
                                                )
                                            }
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>

                        <div className="certif-bar-wrapper">
                            <div className="certif-bar-right-buttons">
                                {/* <button className="btn btn-primary btn-sm" onClick={submitHandler}>
                                    Save
                                </button> */}
                            </div>
                        </div>
                    </div >
                </>
            )};
        </>
    );
}

export default MarketingContentList;
