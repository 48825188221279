import { useState, useEffect, useContext } from "react";
import { useLocation, NavLink } from "react-router-dom";
import { useNavigate } from "react-router";

import AdminSection2Body from "./AdminSection2Body";
import Utils from "../../../controls/Utils";

function AdminSection2() {
  const location = useLocation();
  const ACTIVE_CLASSNAME = "active";

  const isAtRootUrl =
    location?.pathname === Utils.APP_URLS.ADMIN_PAGE_SECTION_2 ||
    location?.pathname === Utils.APP_URLS.LABELS_TEMPLATE;

  let [canRender, setCanRender] = useState(false);

  useEffect(() => {
    setCanRender(true);
  }, []);

  const navigationItemKeys = [
    "labelsManagement",
  ];

  const navLinkValues = {
    labelsManagement: {
      label: "Label Management",
      id: "LABELS_MANAGEMENT",
      url: Utils.APP_URLS.LABELS_MANAGEMENT,
    },
  };

  return (
    <>
      {canRender && (
        <div className="container certif-admin-container">
          <div className="d-flex certif-admin-section-wrapper">
            {navigationItemKeys.map((navKey, index, src) => (
              <div
                className={`certif-admin-section-item certif-admin-section-item-${navKey}`}
                key={index}
              >
                <NavLink
                  className={({ isActive }) => {
                    if (isAtRootUrl) {
                      isActive = index === 0;
                    }
                    return isActive ? ACTIVE_CLASSNAME : "";
                  }}
                  to={navLinkValues[navKey].url}
                  id={navKey}
                >
                  {({ isActive }) => (
                    <>
                      <div className="certif-admin-section-nav-items">
                        <div className="certif-admin-section-nav-item">
                          <div>{navLinkValues[navKey].label}</div>
                          <div className="certif-arrow-up certif-set-buttom"></div>
                        </div>
                      </div>
                    </>
                  )}
                </NavLink>
              </div>
            ))}
          </div>

          <div className="certif-admin-section-container-body">
            <AdminSection2Body />
          </div>
        </div>

      )}
    </>
  );
}

export default AdminSection2;
