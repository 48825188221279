import { useState, useEffect, useRef, useContext } from "react";
import { useTranslation, Trans } from "react-i18next";

import { Editor } from "primereact/editor";
import "primereact/resources/primereact.min.css";

// import { Editor } from "@tinymce/tinymce-react";
import Utils from "../../../controls/Utils";
import Error from "../../layout/body/Error";
import AppContextComponent from "../../../controls/AppContext";
import CertifAlert from "../../../controls/CertifAlert4AdminScreens";

function AddEditSellingOffice({ props }) {
    let REQ_FIELDS = [
        "email", //
    ];
    const fields4ErrorTemplate = {
        email: "Email",
    };

    // console.log(props);
    let { setLoadingState, setPageInfo, setCanRedirectToLogin, setCanRedirectToError } = useContext(AppContextComponent);
    let { t } = useTranslation();
    let [isSuperAdmin, setIsSuperAdmin] = useState(Utils.isSuperAdminAccess());

    let [canRender, setCanRender] = useState(false);
    let [pageMode, setPageMode] = useState(props.mode);
    let [canContinue, setCanContinue] = useState(false);
    let [sellingOfficeList, setSellingOfficeList] = useState([]);
    let [errorMessageKey, setErrorMessageKey] = useState("");
    let [errorFields, setErrorFields] = useState({});
    let [showModal, setShowModal] = useState(false);
    let [errorMessage, setErrorMessage] = useState('');
    let [modalErrorType, setModalErrorType] = useState('');
    let [pageModeStatus, setPageModeStatus] = useState(false);
    let [emailError, setEmailError] = useState('');

    const anyCallback = () => {
        setLoadingState({
            applyMask: false,
        });
    };

    useEffect(() => {
        setLoadingState({
            applyMask: true,
        });

        const commonFail = ({ error }) => {
            console.log(error);
            Utils.handleDefaultError({
                error,
                setLoadingState,
                setCanRedirectToLogin,
                setCanRedirectToError,
                setPageInfo,
            });
        };

        const sellingOfficeSuccess = (res) => {
            setSellingOfficeList(res.data);
            setCanRender(true);
            anyCallback();
        };

        Utils.fetchData("SELLING_OFFICE").then(sellingOfficeSuccess, commonFail);
    }, []);


    let formHeading = props.mode === "add" ? "Add Selling Office Details" : "Edit Selling Office Details";

    let defSellingOfficeId =
        props.mode === "add" ? "" : props?.templates?.[props.recordIndex]?.["sellingOfficeId"] || "";

    let [sellingOfficeId, setSellingOfficeId] = useState(defSellingOfficeId);

    let defSellingOfficeName =
        props.mode === "add" ? "" : props?.templates?.[props.recordIndex]?.["sellingOfficeName"] || "";

    let [sellingOfficeName, setSellingOfficeName] = useState(defSellingOfficeName);

    let defSellingOfficeRegion =
        props.mode === "add" ? "" : props?.templates?.[props.recordIndex]?.["sellingOfficeRegion"] || "";

    let [sellingOfficeRegion, setSellingOfficeRegion] = useState(defSellingOfficeRegion);

    let defSellingOfficeActive =
        props.mode === "add"
            ? ""
            : props?.templates?.[props.recordIndex]?.["sellingOfficeActive"] || "";

    let [sellingOfficeActive, setSellingOfficeActive] = useState(defSellingOfficeActive);

    let defEmail =
        props.mode === "add" ? "" : props?.templates?.[props.recordIndex]?.["email"] || "";

    let [email, setEmail] = useState(defEmail);




    const executeCancel = (options = {}) => {
        props.setPageMode("list");
    };

    const submitRecord = () => {
        let mode = pageMode;
        setLoadingState({
            applyMask: true,
        });
        let tempTemplates = [...props.templates];
        let record = {};

        props.fields.forEach((field, index) => {
            let fieldEle = document.getElementById(field);

            let fieldValue = '';

            if (fieldEle) {
                if (fieldEle.type === 'checkbox') {
                    fieldValue = fieldEle.checked;
                } else {
                    fieldValue = fieldEle.value.trim();
                }
            }
            record[field] = fieldValue;
        });

        console.log("New record");
        console.log(record);

        const success = (res) => {
            // console.log(tempTemplates);
            // console.log(res);

            mode === "add" && tempTemplates.push(res.data);
            mode === "edit" && (tempTemplates[props.recordIndex] = res.data);

            props.setTemplates(tempTemplates);
            setPageModeStatus(true);
            let statusMessage = res.data !== [] ? Utils.USER_INTERACTION_MESSAGES_4_ADMIN_SCREENS.save : "ok";
            mode === "add" && (statusMessage = Utils.USER_INTERACTION_MESSAGES_4_ADMIN_SCREENS.save);
            mode === "edit" && (statusMessage = Utils.USER_INTERACTION_MESSAGES_4_ADMIN_SCREENS.update);
            setErrorMessage(statusMessage);
            setLoadingState({
                applyMask: false,
            });
            setModalErrorType("info");
            setShowModal(true);

            // props.setPageMode("list");
        };

        const fail = ({ error }) => {
            console.log(error);
            // cost errCode = error.error.
            let errCode = error?.response?.status;
            if (errCode === 400) {
                const errorMessage = error.response ? error.response.data.message :
                    Utils.USER_INTERACTION_MESSAGES_4_ADMIN_SCREENS.errorAlt;
                const updatedErrorMessage = errorMessage.slice(7);
                console.log(typeof (updatedErrorMessage));
                console.log(updatedErrorMessage);
                setErrorMessage(updatedErrorMessage);
                setPageModeStatus(false);
                setLoadingState({
                    applyMask: false,
                });
                // Display the error modal
                setModalErrorType('error');
                // setShowErrorModal(true);
                setShowModal(true);
            } else {
                console.log(error);
                Utils.handleDefaultError({
                    error,
                    setLoadingState,
                    setCanRedirectToLogin,
                    setCanRedirectToError,
                    setPageInfo,
                });
            }
        };

        mode === "add" && Utils.addSellingOfficeDetails(record).then(success, fail);
        mode === "edit" &&
            Utils.editSellingOfficeDetails(
                record,
                props.templates[props.recordIndex].id,
            ).then(success, fail);
    };

    const validateForm = () => {
        let tempErrorFields = {};
        setEmailError("");

        REQ_FIELDS.forEach((fieldId) => {
            let field = document.getElementById(fieldId);
            let fieldVal = '';
            if (field) {
                fieldVal =
                    field.type === "checkbox" ? field.checked : field.value?.trim() ?? "";
                // if (fieldVal.length === 0) {
                //     if (!tempErrorFields[fieldId]) {
                //         // tempErrorFields[fieldId] = errorMessages[fieldId][0];
                //         tempErrorFields[fieldId] = Utils.ADMIN_SECTIONS_ERROR_TEMPLATE.replace('{}', fields4ErrorTemplate[fieldId]);
                //     }
                // }
                if (fieldId === "email" && fieldVal.length !== 0 && !validateEmail(fieldVal) && !tempErrorFields['emailErrorField']) {
                    tempErrorFields['emailErrorField'] = Utils.ADMIN_SECTIONS_ERROR_TEMPLATE.replace('{}', fields4ErrorTemplate[fieldId]);
                    setEmailError("Please enter Email Address in correct format.");
                }
            }
        });
        setErrorFields(tempErrorFields);
        return tempErrorFields;

    }

    const validateEmail = (email) => {
        const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
        return isValidEmail;
    };

    // const executeSave = (options = {}) => {
    //     submitRecord();
    // };

    const executeUpdate = (options = {}) => {
        let detailsObj = validateForm();
        // console.log(detailsObj);
        if (Utils.isObjectEmpty(detailsObj)) {
            submitRecord();
        }
    };


    return (
        <>
            {canRender && (
                <div>
                    {/* <Error props={{ errorMessageKey }} /> */}
                    <CertifAlert
                        props={{
                            showModal,
                            setShowModal,
                            modalType: modalErrorType,
                            bodyContent: errorMessage,
                            backdrop: "static",
                            keyboard: false,
                            pageModeStatus,
                            setPageModeProp: props.setPageMode,
                        }}
                    />

                    <div className="table-responsive">
                        <table className="table table-borderless">
                            <thead className="certif-table-header">
                                <tr>
                                    <th className="certif-table-header-label">{formHeading}</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody className="certif-table-body">
                                <tr className="col-lg-12 col-md-12 col-sm-12 certif-add-edit-selling-office-read-only">
                                    <td className="col-lg-6 col-md-6 col-sm-6">
                                        <label>
                                            Selling Office Id
                                            <span className="certif-required-indicator">*</span>
                                        </label>
                                        <div>
                                            <select
                                                // onChange={onChangeLanguage}
                                                defaultValue={sellingOfficeId}
                                                id={"sellingOfficeId"}
                                            >
                                                {sellingOfficeList?.map((sellingOfficeItem, index) => (
                                                    <option key={index} value={sellingOfficeItem["sellingOfficeId"]}>
                                                        {sellingOfficeItem["sellingOfficeId"]}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </td>
                                    <td className="col-lg-6 col-md-6 col-sm-6">
                                        <label>
                                            Selling Office Name
                                            <span className="certif-required-indicator">*</span>
                                        </label>
                                        <div>
                                            <input type="text" defaultValue={sellingOfficeName} id={"sellingOfficeName"} />
                                        </div>
                                    </td>
                                </tr>
                                <tr className="col-lg-12 col-md-12 col-sm-12 certif-add-edit-selling-office-read-only">
                                    <td className="col-lg-6 col-md-6 col-sm-6">
                                        <label>
                                            Selling Office Region
                                            <span className="certif-required-indicator">*</span>
                                        </label>
                                        <div>
                                            <input type="text" defaultValue={sellingOfficeRegion} id={"sellingOfficeRegion"} />
                                        </div>
                                    </td>
                                    <td className="certif-admin-flex-check-td">
                                        <div className="certif-flex-check-label-wrapper">
                                            <input
                                                id="sellingOfficeActive"
                                                type="checkbox"
                                                defaultChecked={sellingOfficeActive}
                                                className="certif-input-field"
                                            // onChange={emailBodyForOriginalHandler}
                                            />
                                            <label>
                                                Selling Office Status
                                                <span className="certif-required-indicator">*</span>
                                            </label>
                                        </div>
                                    </td>
                                </tr>
                                <tr className="col-lg-12 col-md-12 col-sm-12">
                                    <td className="col-lg-6 col-md-6 col-sm-6">
                                        <label>
                                            Email
                                            {/* <span className="certif-required-indicator">*</span> */}
                                        </label>
                                        <div>
                                            <input
                                                type="email"
                                                defaultValue={email}
                                                id={"email"}
                                            />
                                            <p className="error-message" style={{ color: "red" }}>
                                                {emailError}
                                            </p>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot className="certif-table-footer">
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th>
                                        <div className="d-flex">
                                            <button
                                                className="btn btn-primary btn-sm"
                                                onClick={executeCancel}
                                            >
                                                {t("cancel")}
                                            </button>

                                            {props.mode === "edit" && (
                                                <button
                                                    className="btn btn-primary btn-sm"
                                                    onClick={executeUpdate}
                                                >
                                                    Update
                                                </button>
                                            )}

                                            {/* {props.mode === "add" && (
                                                <button
                                                    className="btn btn-primary btn-sm"
                                                    onClick={executeSave}
                                                >
                                                    Save
                                                </button>
                                            )} */}
                                        </div>
                                    </th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            )}
        </>
    );

}

export default AddEditSellingOffice;