import { useContext, useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import Utils from "../../../controls/Utils";

import AppContextComponent from "../../../controls/AppContext";

function Ribbon({ props = {} }) {
  let { t } = useTranslation();
  let { pageInfo } = useContext(AppContextComponent);

  let pages = {
    general_information: {
      label: "General Information",
      langKey: "ribbonGenInfo",
      active: props.active === Utils.PAGES[1], // "general_information",
    },
    certificate_information: {
      label: "Certificate Information",
      langKey: "ribbonCertInfo",
      active: props.active === Utils.PAGES[2], // "certificate_information",
    },
    confirmation: {
      label: "Confirmation",
      langKey: "confirmation",
      active: props.active === Utils.PAGES[3], // "confirmation",
    },
    validation: {
      label: "Validation",
      langKey: "validation",
      active: props.active === Utils.PAGES[4], // "validation",
    },
  };

  useEffect(() => {

  }, [pageInfo.path]);
  return (
    <>
      <div className="certif-ribbon d-flex">
        <div><span className="certif-label p-0">{t('companyOrOrganisation')}: </span><span className="certif-value">{pageInfo.company}</span></div>
        <span className="certif-pipe"></span>
        <div><span className="certif-label">{t('membershipNumber')}: </span><span className="certif-value">{pageInfo.programNumber}</span></div>
      </div>
      <div className="certif-progress d-flex">
        {Object.keys(pages).map((pageKey, index) => (
          <div key={index} className={pages[pageKey].active ? "active d-flex align-items-center" : "d-flex align-items-center"}>
            <div className="certif-stage-circle">{index + 1}</div>
            <div className="certif-progress-text">{t(pages[pageKey].langKey)}</div>
          </div>
        ))}
      </div>
    </>
  );
}

export default Ribbon;
