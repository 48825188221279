import { useState, useEffect, useRef, useContext } from "react";
import { useTranslation, Trans } from "react-i18next";

import { Modal } from "react-bootstrap";

import "primereact/resources/primereact.min.css";

// import { Editor } from "@tinymce/tinymce-react";
import Utils from "../../../controls/Utils";
import Error from "../../layout/body/Error";

import AppContextComponent from "../../../controls/AppContext";
import CertifAlert from "../../../controls/CertifAlert4AdminScreens";

function AddEditLegalEntity({ props }) {
  let REQ_FIELDS = [
    "entityId", //
    "name", //
    "sellingOfficeId", //
    "email", //
  ];
  const fields4ErrorTemplate = {
    entityId: "Legal Entity Id",
    name: "Name",
    sellingOfficeId: "Selling Office Id",
    email: "Email",
  };

  // console.log(props);
  let { setLoadingState, setPageInfo, setCanRedirectToLogin, setCanRedirectToError } = useContext(AppContextComponent);
  let { t } = useTranslation();
  let [isSuperAdmin, setIsSuperAdmin] = useState(Utils.isSuperAdminAccess());

  let [canRender, setCanRender] = useState(false);
  let [pageMode, setPageMode] = useState(props.mode);
  let [canContinue, setCanContinue] = useState(false);
  let [errorMessageKey, setErrorMessageKey] = useState("");
  let [errorFields, setErrorFields] = useState({});
  const editorRef = useRef(null);
  let [sellingOfficeIdOptions, setSellingOfficeIdOptions] = useState([]);
  let [showModal, setShowModal] = useState(false);
  let [errorMessage, setErrorMessage] = useState('');
  let [modalErrorType, setModalErrorType] = useState('');
  let [pageModeStatus, setPageModeStatus] = useState(false);
  let [emailError, setEmailError] = useState('');

  useEffect(() => {
    setLoadingState({
      applyMask: true,
    });
    const commonFail = ({ error }) => {
      console.log(error);
      Utils.handleDefaultError({
        error,
        setLoadingState,
        setCanRedirectToLogin,
        setCanRedirectToError,
        setPageInfo,
      });
    };

    const sellingOfficeSuccess = (res) => {
      // console.log("Selling office id", res.data)
      setSellingOfficeIdOptions(res.data);
      setCanRender(true);
      setLoadingState({
        applyMask: false,
      });
    };

    Utils.fetchData("SELLING_OFFICE").then(sellingOfficeSuccess, commonFail);
  }, []);

  const anyCallback = () => {
    setLoadingState({
      applyMask: false,
    });
  };

  let formHeading =
    props.mode === "add" ? "Add legal entity" : "Edit legal entity";

  let defEntityId =
    props.mode === "add" ? "" : props?.templates?.[props.recordIndex]?.["entityId"] || "";

  let [entityId, setEntityId] = useState(defEntityId);

  let defName =
    props.mode === "add" ? "" : props?.templates?.[props.recordIndex]?.["name"] || "";

  let [name, setName] = useState(defName);

  let defSellingOfficeId =
    props.mode === "add"
      ? ""
      : props?.templates?.[props.recordIndex]?.["sellingOfficeId"] || "";

  let [sellingOfficeId, setSellingOfficeId] = useState(defSellingOfficeId);

  let defBuilding =
    props.mode === "add" ? "" : props?.templates?.[props.recordIndex]?.["building"] || "";

  let [building, setBuilding] = useState(defBuilding);

  let defStreetName =
    props.mode === "add"
      ? ""
      : props?.templates?.[props.recordIndex]?.["streetName"] || "";

  let [streetName, setStreetName] = useState(defStreetName);

  let defCityName =
    props.mode === "add" ? "" : props?.templates?.[props.recordIndex]?.["cityName"] || "";

  let [cityName, setCityName] = useState(defCityName);

  let defCountryName =
    props.mode === "add"
      ? ""
      : props?.templates?.[props.recordIndex]?.["countryName"] || "";

  let [countryName, setCountryName] = useState(defCountryName);

  let defPostalCode =
    props.mode === "add"
      ? ""
      : props?.templates?.[props.recordIndex]?.["postalCode"] || "";

  let [postalCode, setPostalCode] = useState(defPostalCode);

  let defEmail =
    props.mode === "add" ? "" : props?.templates?.[props.recordIndex]?.["email"] || "";

  let [email, setEmail] = useState(defEmail);

  let defContactNumber =
    props.mode === "add"
      ? ""
      : props?.templates?.[props.recordIndex]?.["contactNumber"] || "";

  let [contactNumber, setContactNumber] = useState(defContactNumber);

  let defGmName =
    props.mode === "add" ? "" : props?.templates?.[props.recordIndex]?.["gmName"] || "";

  let [gmName, setGmName] = useState(defGmName);

  let defLetterHead =
    props.mode === "add"
      ? ""
      : (props?.templates?.[props.recordIndex]?.["letterHead"] ?? "");

  let [letterHead, setLetterHead] = useState(defLetterHead);

  let defFileName =
    defLetterHead?.length > 0
      ? letterHead?.substr(letterHead.lastIndexOf("/") + 1, letterHead.length)
      : "";

  let [fileName, setFileName] = useState(defFileName);

  const executeCancel = (options = {}) => {
    props.setPageMode("list");
  };


  const sellingOfficeIdCheck = (sellOffcId) => {
    const selinOffc = sellingOfficeIdOptions?.find(office => office.sellingOfficeId === sellOffcId);
    return !!selinOffc; // Returns true if selinOffc exists, false otherwise
  };

  const submitRecord = () => {
    let mode = pageMode;
    setLoadingState({
      applyMask: true,
    });
    let tempTemplates = [...props.templates];
    let record = {};

    props.fields.forEach((field, index) => {
      let fieldEle = document.getElementById(field);
      let fieldValue =
        field.type === "checkbox" ? fieldEle.checked : fieldEle.value;
      record[field] = fieldValue;
    });

    record.letterHead = document.getElementById("letterHead").files[0] ?? null;

    // console.log(record);

    const success = (res) => {
      // console.log(tempTemplates);
      // console.log(res);

      mode === "add" && tempTemplates.push(res.data);
      mode === "edit" && (tempTemplates[props.recordIndex] = res.data);

      props.setTemplates(tempTemplates);
      setPageModeStatus(true);
      let statusMessage = res.data !== [] ? Utils.USER_INTERACTION_MESSAGES_4_ADMIN_SCREENS.save : "Ok";
      mode === "add" && (statusMessage = Utils.USER_INTERACTION_MESSAGES_4_ADMIN_SCREENS.save);
      mode === "edit" && (statusMessage = Utils.USER_INTERACTION_MESSAGES_4_ADMIN_SCREENS.update);
      setErrorMessage(statusMessage);
      setLoadingState({
        applyMask: false,
      });
      setModalErrorType("info");
      setShowModal(true);

      // props.setPageMode("list");
    };

    const fail = ({ error }) => {
      console.log(error);
      // cost errCode = error.error.
      let errCode = error?.response?.status;
      if (errCode === 400) {
        const errorMessage = error.response ? error.response.data.message :
          Utils.USER_INTERACTION_MESSAGES_4_ADMIN_SCREENS.errorAlt;
        const updatedErrorMessage = errorMessage.slice(7);
        console.log(typeof (updatedErrorMessage));
        console.log(updatedErrorMessage);
        setErrorMessage(updatedErrorMessage);
        setPageModeStatus(false);
        setLoadingState({
          applyMask: false,
        });
        // Display the error modal
        setModalErrorType('error');
        // setShowErrorModal(true);
        setShowModal(true);
      } else {
        console.log(error);
        Utils.handleDefaultError({
          error,
          setLoadingState,
          setCanRedirectToLogin,
          setCanRedirectToError,
          setPageInfo,
        });
      }
    };

    mode === "add" && Utils.addLegalEntity(record).then(success, fail);
    mode === "edit" &&
      Utils.editLegalEntity(record, props.templates[props.recordIndex].id).then(
        success,
        fail,
      );
  };

  const validateForm = () => {
    let tempErrorFields = {};

    REQ_FIELDS.forEach((fieldId) => {
      let field = document.getElementById(fieldId);
      let fieldVal = '';
      if (field) {
        fieldVal =
          field.type === "checkbox" ? field.checked : field.value?.trim() ?? "";
        if (fieldVal.length === 0) {
          if (!tempErrorFields[fieldId]) {
            // tempErrorFields[fieldId] = errorMessages[fieldId][0];
            tempErrorFields[fieldId] = Utils.ADMIN_SECTIONS_ERROR_TEMPLATE.replace('{}', fields4ErrorTemplate[fieldId]);
          }
        }
        if (fieldId === "email" && fieldVal.length !== 0 && !validateEmail(fieldVal) && !tempErrorFields['emailErrorField']) {
          tempErrorFields['emailErrorField'] = Utils.ADMIN_SECTIONS_ERROR_TEMPLATE.replace('{}', fields4ErrorTemplate[fieldId]);
        }
      }
    });
    setErrorFields(tempErrorFields);
    return tempErrorFields;

  }

  const executeSave = (options = {}) => {
    let detailsObj = validateForm();
    // console.log(detailsObj);
    if (Utils.isObjectEmpty(detailsObj)) {
      submitRecord();
    }
  };

  const executeUpdate = (options = {}) => {
    let detailsObj = validateForm();
    // console.log(detailsObj);
    if (Utils.isObjectEmpty(detailsObj)) {
      submitRecord();
    }
  };

  const initiateDelete = (options = {}) => {
    setShowAlert(true);
  };

  let [showAlert, setShowAlert] = useState(false);

  const handleClose = (e) => {
    setShowAlert(false);
  };

  const executeDelete = (e) => {
    setLoadingState({
      applyMask: true,
    });
    let tempTemplates = [...props.templates];

    const delSuccess = (res) => {
      setPageModeStatus(false);
      tempTemplates.splice(props.recordIndex, 1);
      const statusMessage = res.data !== [] ? Utils.USER_INTERACTION_MESSAGES_4_ADMIN_SCREENS.delete : "Ok";
      setErrorMessage(statusMessage);
      setModalErrorType('info');
      // setShowErrorModal(true);
      setShowModal(true);
      setPageModeStatus(true);
      props.setTemplates(tempTemplates);
      setLoadingState({
        applyMask: false,
      });
      setShowAlert(false);
      // props.setPageMode("list");
    };

    const delFail = ({ error }) => {
      console.log(error);
      Utils.handleDefaultError({
        error,
        setLoadingState,
        setCanRedirectToLogin,
        setCanRedirectToError,
        setPageInfo,
      });
    };

    let recordIdToBeDelete = props.templates[props.recordIndex].id;
    // console.log(recordIdToBeDelete);
    Utils.deleteLegalEntity(recordIdToBeDelete).then(delSuccess, delFail);
  };

  const handleDownload = (e) => {
    let src = letterHead;
    Utils.downloadFile({ src, setLoadingState });
  };

  const validateEmail = (email) => {
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    return isValidEmail;
  };
  const handleEmailBlur = (e) => {
    const inputEmail = e.currentTarget.value;
    const isValidEmail = validateEmail(inputEmail);

    if (isValidEmail) {
      setEmailError("");
    } else {
      setEmailError(t("emailError"));
    }
  };

  return (
    <>
      <Modal centered show={showAlert} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete confirmation?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>Are you sure to delete this item?</div>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <div className="d-flex certif-delete-confirmation">
              <button onClick={handleClose} className="btn btn-primary btn-sm">
                No
              </button>
              <button
                onClick={executeDelete}
                className="btn btn-primary btn-sm"
              >
                Yes
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
      <CertifAlert
        props={{
          showModal,
          setShowModal,
          modalType: modalErrorType,
          bodyContent: errorMessage,
          backdrop: "static",
          keyboard: false,
          pageModeStatus,
          setPageModeProp: props.setPageMode,
        }}
      />

      {canRender && (
        <div>
          <Error props={{ errorMessageKey }} />

          <div className="table-responsive">
            <table className="table table-borderless">
              <thead className="certif-table-header">
                <tr>
                  <th className="certif-table-header-label">{formHeading}</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody className="certif-table-body">
                <tr className="col-lg-12 col-md-12 col-sm-12">
                  <td className="col-lg-6 col-md-6 col-sm-6">
                    <label>
                      Id
                      <span className="certif-required-indicator">*</span>
                    </label>
                    <div>
                      <input
                        type="text"
                        defaultValue={entityId}
                        id={"entityId"}
                      />
                      {errorFields.entityId && (<p className="error-message" style={{ color: "red" }}>
                        {errorFields.entityId}
                      </p>)}
                    </div>
                  </td>
                  <td className="col-lg-6 col-md-6 col-sm-6">
                    <label>
                      Name
                      <span className="certif-required-indicator">*</span>
                    </label>
                    <div>
                      <input type="text" defaultValue={name} id={"name"} />
                      {errorFields.name && (<p className="error-message" style={{ color: "red" }}>
                        {errorFields.name}
                      </p>)}
                    </div>
                  </td>
                </tr>
                <tr className="col-lg-12 col-md-12 col-sm-12">
                  <td className="col-lg-6 col-md-6 col-sm-6">
                    <label>
                      Selling Office Name
                      <span className="certif-required-indicator">*</span>
                    </label>

                    <div>
                      <select
                        // onChange={onChangeSellingOffice}
                        defaultValue={sellingOfficeId}
                        id={"sellingOfficeId"}
                      >
                        <option value="">Select</option>
                        {sellingOfficeIdOptions?.map((sellingOffice, index) => (
                          <option
                            value={sellingOffice.sellingOfficeId}
                            key={index}
                          >
                            {sellingOffice.sellingOfficeName}
                          </option>
                        ))}
                        {!sellingOfficeIdCheck(sellingOfficeId) && props.mode === "edit" && (
                          <option option value={sellingOfficeId}>{sellingOfficeId}</option>
                        )}
                      </select>
                      {errorFields.sellingOfficeId && (<p className="error-message" style={{ color: "red" }}>
                        {errorFields.sellingOfficeId}
                      </p>)}
                    </div>
                  </td>
                  <td className="col-lg-6 col-md-6 col-sm-6">
                    <label>
                      Building
                      {/* <span className="certif-required-indicator">*</span> */}
                    </label>
                    <div>
                      <input
                        type="text"
                        defaultValue={building}
                        id={"building"}
                      />
                    </div>
                  </td>
                </tr>
                <tr className="col-lg-12 col-md-12 col-sm-12">
                  <td className="col-lg-6 col-md-6 col-sm-6">
                    <label>
                      Street Name
                      {/* <span className="certif-required-indicator">*</span> */}
                    </label>
                    <div>
                      <input
                        type="text"
                        defaultValue={streetName}
                        id={"streetName"}
                      />
                    </div>
                  </td>
                  <td className="col-lg-6 col-md-6 col-sm-6">
                    <label>
                      City Name
                      {/* <span className="certif-required-indicator">*</span> */}
                    </label>
                    <div>
                      <input
                        type="text"
                        defaultValue={cityName}
                        id={"cityName"}
                      />
                    </div>
                  </td>
                </tr>
                <tr className="col-lg-12 col-md-12 col-sm-12">
                  <td className="col-lg-6 col-md-6 col-sm-6">
                    <label>
                      Country Name
                      {/* <span className="certif-required-indicator">*</span> */}
                    </label>
                    <div>
                      <input
                        type="text"
                        defaultValue={countryName}
                        id={"countryName"}
                      />
                    </div>
                  </td>
                  <td className="col-lg-6 col-md-6 col-sm-6">
                    <label>
                      Postal Code
                      {/* <span className="certif-required-indicator">*</span> */}
                    </label>
                    <div>
                      <input
                        type="text"
                        defaultValue={postalCode}
                        id={"postalCode"}
                      />
                    </div>
                  </td>
                </tr>
                <tr className="col-lg-12 col-md-12 col-sm-12">
                  <td className="col-lg-6 col-md-6 col-sm-6">
                    <label>
                      Email
                      <span className="certif-required-indicator">*</span>
                    </label>
                    <div>
                      <input
                        type="text"
                        defaultValue={email}
                        id={"email"}
                        onBlur={handleEmailBlur}
                      />
                      {errorFields.email && (<p className="error-message" style={{ color: "red" }}>
                        {errorFields.email}
                      </p>)}
                      <p className="error-message" style={{ color: "red" }}>
                        {emailError}
                      </p>
                    </div>
                  </td>
                  <td className="col-lg-6 col-md-6 col-sm-6">
                    <label>
                      Contact Number
                      {/* <span className="certif-required-indicator">*</span> */}
                    </label>
                    <div>
                      <input
                        type="text"
                        defaultValue={contactNumber}
                        id={"contactNumber"}
                      />
                    </div>
                  </td>
                </tr>
                <tr className="col-lg-12 col-md-12 col-sm-12">
                  <td colSpan="3">
                    <label>
                      General Manager Name
                      {/* <span className="certif-required-indicator">*</span> */}
                    </label>
                    <div>
                      <input type="text" defaultValue={gmName} id={"gmName"} />
                    </div>
                  </td>
                </tr>

                <tr className="col-lg-12 col-md-12 col-sm-12">
                  <td colSpan="3">
                    <label>
                      Letter head{" "}
                      {fileName.length > 0 && (
                        <span className="certif-download-link" onClick={handleDownload}>
                          ({fileName})
                        </span>
                      )}
                    </label>
                    <div>
                      <input type="file" id={"letterHead"} />
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot className="certif-table-footer">
                <tr>
                  <th>
                    <div className="d-flex">
                      {isSuperAdmin && props.mode === "edit" && (
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={initiateDelete}
                        >
                          Delete
                        </button>
                      )}
                    </div>
                  </th>
                  <th></th>
                  <th>
                    <div className="d-flex">
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={executeCancel}
                      >
                        {t("Cancel")}
                      </button>

                      {props.mode === "edit" && (
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={executeUpdate}
                        >
                          Update
                        </button>
                      )}

                      {props.mode === "add" && (
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={executeSave}
                        >
                          Save
                        </button>
                      )}
                    </div>
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div >
      )
      }
    </>
  );
}

export default AddEditLegalEntity;
