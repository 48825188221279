import { useState, useEffect } from "react";

function ProgramDetailsTable({ props }) {
  let fieldsData = props.programDetails;
  // console.log(fieldsData);
  let [canRender, setCanRender] = useState(false);

  useEffect(() => {
    setCanRender(true);
  }, []);

  let fieldSchema = {
    programNumber: {
      label: "Program number",
    },
    returnCode: {
      label: "Return code",
    },
    returnMessage: {
      label: "Return message",
    },
    programStatus: {
      label: "Program status",
    },
    companyId: {
      label: "Company ID",
    },
    companyName: {
      label: "Company name",
    },
    sellingOfficeId: {
      label: "Selling office ID",
    },
    sellingOfficeName: {
      label: "Selling office name",
    },
    productType: {
      label: "Product type",
    },
    productSubType: {
      label: "Product sub-type",
    },
    programNumber: {
      label: "Program number",
    },
    productCode: {
      label: "Product code",
    },
    membershipType: {
      label: "Membership type",
    },
    effectiveDate: {
      label: "Effective date",
    },
    expiryDate: {
      label: "Expiry date",
    },
    medicalLimit: {
      label: "Medical limit",
    },
    legalEntityName: {
      label: "Legal entity name",
    },
    isWaiverPDFEncrypt: {
      label: "is waiver PDF encrypted?",
    },
  };

  return (
    <>
      {canRender && (
        // <div className="certif-programdetails-table-wrapper d-flex1">
        <div className="certif-programdetails-table">
          <div className="table-responsive">
            <table className="table table-bordered m-0">
              <thead className="certif-table-header">
                <tr>
                  <th className="certif-table-header-label" colSpan="3">
                    Program Details for {props.programDetails.programNumber}
                  </th>
                </tr>
              </thead>
              <tbody className="certif-table-body certif-program-details-table-tbody">
                {Object.keys(fieldsData)?.map((field, index) => (
                  <tr key={index}>
                    <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6 certif-program-details-table-label">
                      <label className="">
                        {fieldSchema[field]?.label ?? fieldSchema[field]}
                      </label>
                    </td>
                    <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                      <div>
                        {fieldsData[field] === true
                          ? "Active"
                          : fieldsData[field] === false
                          ? "Inactive"
                          : fieldsData[field]}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <table className="table table-borderless m-0">
              <tfoot className="certif-table-footer">
                <tr>
                  <th></th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
        // </div>
      )}
    </>
  );
}

export default ProgramDetailsTable;
