import { useState, useEffect, useRef, useContext } from "react";
import { Table, Modal } from "react-bootstrap";

import CountryLimitDialog from "./CountryLimit/CountryLimitDialog";
import MarketingContentDialog from "./MarketingContent/MarketingContentDialog";
import EditImg from "./../../../assets/img/icon-modify.png";
import Utils from "../../../controls/Utils";
import AppContextComponent from "../../../controls/AppContext";
import CertifAlert from "../../../controls/CertifAlert4AdminScreens";

import ARROW_UP_IMG from "./../../../assets/img/arrow.up.svg";
import FILLED_ARROW_UP_IMG from "./../../../assets/img/arrow.up.fill.svg";
import ARROW_DOWN_IMG from "./../../../assets/img/arrow.down.svg";
import FILLED_ARROW_DOWN_IMG from "./../../../assets/img/arrow.down.fill.svg";

function SellingOfficeList({ props }) {

  let { setLoadingState, setPageInfo, setCanRedirectToLogin, setCanRedirectToError } = useContext(AppContextComponent);
  const submitHandler = (e) => { };

  let [fields, setFields] = useState([]);
  let [templates, setTemplates] = useState([]);
  let [filteredTemplates, setFilteredTemplates] = useState([]);

  let [showModal, setShowModal] = useState(false);
  let [errorMessage, setErrorMessage] = useState('');
  let [modalErrorType, setModalErrorType] = useState('');
  let [pageModeStatus, setPageModeStatus] = useState(false);

  const SORTABLE_FIELDS = [
    "sellingOfficeId",
    "sellingOfficeName",
    "sellingOfficeRegion",
    "email",
  ];

  useEffect(() => {
    setFields(props.fields);
    setTemplates(props.templates);
    setFilteredTemplates(props.templates);
  }, [props.fields, props.templates]);

  // const gotoAddPage = (e) => {
  //     props.setPageMode("add");
  // };

  let [addModalCanOpen, setAddModalCanOpen] = useState(false);
  let [showCountryLimitTable, setShowCountryLimitTable] = useState(false);
  let [showMarketingContentTable, setShowMarketingContentTable] =
    useState(false);
  let [modalTitle, setModalTitle] = useState("");
  let [sellingOfficeRecord, setSellingOfficeRecord] = useState({});

  const gotoEditPage = (e) => {
    let dataKey = e.currentTarget.getAttribute("data-key");
    console.log(dataKey);
    if (dataKey === "countryLimit") {
      let currentRecord = filteredTemplates[parseInt(e.currentTarget.id, 10)];
      // console.log(currentRecord);
      setSellingOfficeRecord(currentRecord);
      setModalTitle("Selling Office - " + currentRecord.sellingOfficeName);
      setShowCountryLimitTable(true);
      setAddModalCanOpen(true);
    } else if (dataKey === "marketingContent") {
      let currentRecord = filteredTemplates[parseInt(e.currentTarget.id, 10)];
      console.log(currentRecord);
      setSellingOfficeRecord(currentRecord);
      setModalTitle("Selling Office - " + currentRecord.sellingOfficeName);
      setShowMarketingContentTable(true);
      // debugger;
      setAddModalCanOpen(true);
    } else {
      const dataId = e.target.getAttribute('data-id');

      const index = templates.findIndex((template) => template.id === Number(dataId));
      if (index !== -1) {
        props.setRecordIndex(index);
        props.setPageMode("edit");
      }
    }
  };

  const handleClose = (e) => {
    if (showCountryLimitTable) {
      setShowCountryLimitTable(false);
    } else if (showMarketingContentTable) {
      setShowMarketingContentTable(false);
    }
    setAddModalCanOpen(false);
  };

  const filterTable = (e) => {
    Utils.filterTableData({
      event: e,
      templates,
      setTemplates,
      filteredTemplates,
      setFilteredTemplates,
    });
  };

  const sortTable = (e) => {
    Utils.sortTable({
      event: e,
      templates,
      setTemplates,
      filteredTemplates,
      setFilteredTemplates,
    });
  };

  const syncSellingOfficeDataHandler = () => {
    setLoadingState({
      applyMask: true,
    });

    const sellingOffFetchSuccess = (res) => {
      let tempTemplates = [...res.data];
      console.log(tempTemplates);
      props.setTemplates(tempTemplates);
      // setTemplates(tempTemplates);
      // console.log(templates);
      // setCanRender(true);
      setLoadingState({
        applyMask: false,
      });
      setModalErrorType("info");
      setShowModal(true);
    };

    const fetchSuccess = (res) => {
      // console.log(res.data);
      let statusMessage = res.data !== [] ? res.data : Utils.USER_INTERACTION_MESSAGES_4_ADMIN_SCREENS.errorAlt;
      setPageModeStatus(false);
      setErrorMessage(statusMessage);
      Utils.fetchData("SELLING_OFFICE_MANAGEMENT").then(sellingOffFetchSuccess, fetchFail);
    };

    const fetchFail = ({ error }) => {
      console.log(error);
      Utils.handleDefaultError({
        error,
        setLoadingState,
        setCanRedirectToLogin,
        setCanRedirectToError,
        setPageInfo,
      });
    };


    Utils.syncSellingOfficeData().then(fetchSuccess, fetchFail);
  }

  return (
    <>
      <Modal centered show={addModalCanOpen} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showCountryLimitTable && (
            <CountryLimitDialog props={{ sellingOfficeRecord }} />
          )}
          {showMarketingContentTable && (
            <MarketingContentDialog props={{ sellingOfficeRecord }} />
          )}
        </Modal.Body>
      </Modal>

      <CertifAlert
        props={{
          showModal,
          setShowModal,
          modalType: modalErrorType,
          bodyContent: errorMessage,
          backdrop: "static",
          keyboard: false,
          pageModeStatus,
          setPageModeProp: props.setPageMode,
        }}
      />

      <div className="certif-right-search-wrapper">
        <button className="certif-selling-office-sync-button"
          onClick={syncSellingOfficeDataHandler}
        >
          Sync
        </button>
        <input
          type="text"
          placeholder={Utils.PLACEHOLDER_4_SEARCH_IN_ADMIN_SCREENS}
          onChange={filterTable}
          sort-type=""
          sort-key=""
          id="certif-selling-office-filter-id"
        />
      </div>
      <div className="table-responsive certif-table-responsive-with-search">
        <div className="certif-bar-wrapper">
          <div className="certif-bar-left">Selling Office Management</div>
          {/* <div className="certif-bar-right" onClick={gotoAddPage}>
                        +
                    </div> */}
        </div>

        <div className="d-flex certif-table-with-scrolls certif-admin-sticky-column">
          <Table stripped="" className="m-0" bordered hover size="md">
            <thead>
              <tr>
                <th
                  width=""
                  id="sellingOfficeId_th"
                  className="certif-table-sort-col-wrapper"
                >
                  <span className="certif-table-sort-col-name">
                    Selling Office Id
                  </span>
                  <span className="certif-table-sort-wrapper">
                    <span
                      className="certif-table-sort-up"
                      sort-col-id="sellingOfficeId"
                      sort-col-interactive-id="certif-selling-office-filter-id"
                      sort-col-type="asc"
                      onClick={sortTable}
                    ></span>
                    <span
                      className="certif-table-sort-down"
                      sort-col-id="sellingOfficeId"
                      sort-col-interactive-id="certif-selling-office-filter-id"
                      sort-col-type="desc"
                      onClick={sortTable}
                    ></span>
                  </span>
                </th>

                <th
                  width=""
                  id="sellingOfficeName_th"
                  className="certif-table-sort-col-wrapper"
                >
                  <span className="certif-table-sort-col-name">
                    Selling Office Name
                  </span>
                  <span className="certif-table-sort-wrapper">
                    <span
                      className="certif-table-sort-up"
                      sort-col-id="sellingOfficeName"
                      sort-col-interactive-id="certif-selling-office-filter-id"
                      sort-col-type="asc"
                      onClick={sortTable}
                    ></span>
                    <span
                      className="certif-table-sort-down"
                      sort-col-id="sellingOfficeName"
                      sort-col-interactive-id="certif-selling-office-filter-id"
                      sort-col-type="desc"
                      onClick={sortTable}
                    ></span>
                  </span>
                </th>

                <th
                  width=""
                  id="sellingOfficeRegion_th"
                  className="certif-table-sort-col-wrapper"
                >
                  <span className="certif-table-sort-col-name">
                    Selling Office Region
                  </span>
                  <span className="certif-table-sort-wrapper">
                    <span
                      className="certif-table-sort-up"
                      sort-col-id="sellingOfficeRegion"
                      sort-col-interactive-id="certif-selling-office-filter-id"
                      sort-col-type="asc"
                      onClick={sortTable}
                    ></span>
                    <span
                      className="certif-table-sort-down"
                      sort-col-id="sellingOfficeRegion"
                      sort-col-interactive-id="certif-selling-office-filter-id"
                      sort-col-type="desc"
                      onClick={sortTable}
                    ></span>
                  </span>
                </th>

                <th width="">Status  </th>
                <th width="">Email</th>
                <th className="certif-admin-sticky-column certif-admin-sticky-selling-office-column">
                  Country Limit
                </th>
                {/* <th className="certif-admin-sticky-column" width="72px">Marketing Content</th> */}
              </tr>
            </thead>
            <tbody>
              {filteredTemplates.map((template, templateIndex) => (
                <tr
                  key={templateIndex}
                  className="certif-admin-template-item-row"
                >
                  {fields.map((field, fieldIndex) => (
                    <td
                      id={templateIndex}
                      key={fieldIndex}
                      data-id={template.id}
                      onClick={gotoEditPage}
                      data-key={
                        field === "countryLimit" || field === "marketingContent"
                          ? field
                          : ""
                      }
                      className={
                        field === "countryLimit"
                          ? "certif-table-center-col certif-admin-sticky-column certif-admin-sticky-selling-office-column"
                          : ""
                      }
                    >
                      {field === "sellingOfficeId" && <>{template[field]}</>}
                      {field === "sellingOfficeName" && <>{template[field]}</>}
                      {field === "sellingOfficeRegion" && (
                        <>{template[field]}</>
                      )}
                      {field === "sellingOfficeActive" && (
                        <>{template[field] ? "Active" : "Inactive"}</>
                      )}
                      {field === "email" && <>{template[field]}</>}
                      {field === "countryLimit" && (
                        <span className="">
                          <img src={EditImg} alt="Edit" />
                        </span>
                      )}
                      {/* {field === "marketingContent" && (
                                                <span className="">
                                                    <img src={EditImg} alt="Edit" />
                                                </span>
                                            )} */}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>

        <div className="certif-bar-wrapper">
          <div className="certif-bar-right-buttons">
            {/* <button className="btn btn-primary btn-sm" onClick={submitHandler}>
              Save
            </button> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default SellingOfficeList;
