import { useState, useEffect, useRef, useContext } from "react";

import TemplateList from "./Templates/TemplateList";
import AddEditTemplate from "./Templates/AddEditTemplate";
import Utils from "../../controls/Utils";
import AppContextComponent from "../../controls/AppContext";
import NavEventMixin from "../utilpages/NavEventMixin";

function AdminEmailTemplatePage() {
  /*  
  private String name;
  private String legalEntity;
  private String sellingOffice;	
  private String language;	
  private String emailSubject;	
  private String emailBody;	
  private String emailBodyContentForPassword;
  private String emailBodyForOriginal;	
  private String emailBodyContentForPdfLink;	
  private String emailBodyOriginalContentForPdfLink;  
  */

  let { setLoadingState, setPageInfo, setCanRedirectToLogin, setCanRedirectToError } = useContext(AppContextComponent);

  let [canRender, setCanRender] = useState(false);
  let [pageMode, setPageMode] = useState("list");
  let [templates, setTemplates] = useState([]);
  let [legalEntityDDOptions, setLegalEntityDDOptions] = useState([]);
  let [fields, setFields] = useState([
    "name",
    "legalEntity",
    // "sellingOffice",
    "language",
    "emailSubject",
    "emailBodyForOriginal",
    "emailBody",
    "emailBodyContentForPassword",
    "emailBodyContentForPdfLink",
    "emailBodyOriginalContentForPdfLink",
  ]);
  let [recordIndex, setRecordIndex] = useState(-1);

  let tempNoOfRecords = 7;

  useEffect(() => {
    setLoadingState({
      applyMask: true,
    });

    const legalEntityDDSuccess = (res) => {
      res.data.sort((itemA, itemB) => {
        return itemA.entityName.localeCompare(itemB.entityName);
      });
      let selectOptions = [
        {
          entityId: "",
          entityName: "Select",
        },
        ...res.data,
      ];
      // console.log("Selling office id", res.data)
      setLegalEntityDDOptions(selectOptions);
      setCanRender(true);
      setLoadingState({
        applyMask: false,
      });
    };

    const fetchSuccess = (res) => {
      let tempTemplates = [...res.data];
      setTemplates(tempTemplates);
      Utils.fetchData("LEGAL_ENTITY_DD").then(legalEntityDDSuccess, commonFail);
    };

    const commonFail = ({ error }) => {
      console.log(error);
      Utils.handleDefaultError({
        error,
        setLoadingState,
        setCanRedirectToLogin,
        setCanRedirectToError,
        setPageInfo,
      });
    };

    Utils.fetchData("EMAIL_TEMPLATES").then(fetchSuccess, commonFail);
  }, []);

  useEffect(() => { }, [fields, templates, pageMode]);

  return (
    <>
      {canRender && (
        <div className="table-responsive">
          {(pageMode === "add" || pageMode === "edit") && (
            <NavEventMixin
              props={{
                pageMode,
                setPageMode,
              }}
            />
          )}
          {pageMode === "list" && (
            <TemplateList
              props={{
                fields,
                templates,
                setPageMode,
                recordIndex,
                setRecordIndex,
                legalEntityDDOptions,
              }}
            />
          )}
          {pageMode === "add" && (
            <AddEditTemplate
              props={{
                fields,
                templates,
                setTemplates,
                setPageMode,
                mode: "add",
                tempNoOfRecords,
                legalEntityDDOptions,
              }}
            />
          )}
          {pageMode === "edit" && (
            <AddEditTemplate
              props={{
                fields,
                templates,
                setTemplates,
                setPageMode,
                recordIndex,
                tempNoOfRecords,
                setRecordIndex,
                mode: "edit",
                legalEntityDDOptions,
              }}
            />
          )}
        </div>
      )}
    </>
  );
}

export default AdminEmailTemplatePage;
