import React, { useEffect } from "react";
import Utils from "../../controls/Utils";

const NavEventMixin = ({ props }) => {
  const handleEvent = (e) => {
    props.setPageMode("list");
  };
  useEffect(() => {
    let closestActiveEle = document.querySelector(
      ".certif-admin-container-body .certif-admin-section-item > .active",
    );
    closestActiveEle.addEventListener("keydown", handleEvent);
    closestActiveEle.addEventListener("click", handleEvent);

    return () => {
      closestActiveEle.removeEventListener("click", handleEvent);
      closestActiveEle.removeEventListener("keydown", handleEvent);
    };
  }, []);
  return <></>;
};

export default NavEventMixin;
