import { useState, useEffect, useRef, useContext } from "react";

import Table from "react-bootstrap/Table";
import Utils from "../../../controls/Utils";

import ARROW_UP_IMG from "./../../../assets/img/arrow.up.svg";
import FILLED_ARROW_UP_IMG from "./../../../assets/img/arrow.up.fill.svg";
import ARROW_DOWN_IMG from "./../../../assets/img/arrow.down.svg";
import FILLED_ARROW_DOWN_IMG from "./../../../assets/img/arrow.down.fill.svg";

function LabelsList({ props }) {
    const submitHandler = (e) => { };

    // let fields = props.fields;
    // let templates = props.templates;

    let [fields, setFields] = useState([]);
    let [templates, setTemplates] = useState([]);
    let [filteredTemplates, setFilteredTemplates] = useState([]);
    const SORTABLE_FIELDS = [
        "code",
        "entext",
        "frtext",
        "description",
    ];

    useEffect(() => {
        setFields(props.fields);
        setTemplates(props.templates);
        setFilteredTemplates(props.templates);
    }, [props.fields, props.templates]);

    const gotoAddPage = (e) => {
        props.setPageMode("add");
    };

    const gotoEditPage = (e) => {
        const dataId = e.target.getAttribute('data-id');
        // console.log(dataId);
        // console.log(typeof (dataId));

        const index = templates.findIndex((template) => template.id === Number(dataId));
        if (index !== -1) {
            props.setRecordIndex(index);
            props.setPageMode("edit");
        }
    };

    const filterTable = (e) => {
        Utils.filterTableData({
            event: e,
            templates,
            setTemplates,
            filteredTemplates,
            setFilteredTemplates,
        });
    };

    const sortTable = (e) => {
        Utils.sortTable({
            event: e,
            templates,
            setTemplates,
            filteredTemplates,
            setFilteredTemplates,
        });
    };

    return (
        <>
            <div className="certif-right-search-wrapper">
                <input
                    type="text"
                    placeholder={Utils.PLACEHOLDER_4_SEARCH_IN_ADMIN_SCREENS}
                    onChange={filterTable}
                    sort-type=""
                    sort-key=""
                    id="certif-labels-filter-id"
                />
            </div>
            <div className="table-responsive certif-table-responsive-with-search">
                <div className="certif-bar-wrapper">
                    <div className="certif-bar-left">Label Management</div>
                    <div className="certif-bar-right" onClick={gotoAddPage}>
                        +
                    </div>
                </div>

                <div className="d-flex certif-table-with-scrolls">
                    <Table stripped="" className="m-0" bordered hover size="md">
                        <thead>
                            <tr>
                                <th
                                    width=""
                                    id="code_th"
                                    className="certif-table-sort-col-wrapper"
                                >
                                    <span className="certif-table-sort-col-name">
                                        Identifier
                                    </span>
                                    <span className="certif-table-sort-wrapper">
                                        <span
                                            className="certif-table-sort-up"
                                            sort-col-id="code"
                                            sort-col-interactive-id="certif-labels-filter-id"
                                            sort-col-type="asc"
                                            onClick={sortTable}
                                        ></span>
                                        <span
                                            className="certif-table-sort-down"
                                            sort-col-id="code"
                                            sort-col-interactive-id="certif-labels-filter-id"
                                            sort-col-type="desc"
                                            onClick={sortTable}
                                        ></span>
                                    </span>
                                </th>
                                <th
                                    width=""
                                    id="entext_th"
                                    className="certif-table-sort-col-wrapper"
                                >
                                    <span className="certif-table-sort-col-name">
                                        en-text
                                    </span>
                                    <span className="certif-table-sort-wrapper">
                                        <span
                                            className="certif-table-sort-up"
                                            sort-col-id="entext"
                                            sort-col-interactive-id="certif-labels-filter-id"
                                            sort-col-type="asc"
                                            onClick={sortTable}
                                        ></span>
                                        <span
                                            className="certif-table-sort-down"
                                            sort-col-id="entext"
                                            sort-col-interactive-id="certif-labels-filter-id"
                                            sort-col-type="desc"
                                            onClick={sortTable}
                                        ></span>
                                    </span>
                                </th>
                                <th
                                    width=""
                                    id="frtext_th"
                                    className="certif-table-sort-col-wrapper"
                                >
                                    <span className="certif-table-sort-col-name">
                                        fr-text
                                    </span>
                                    <span className="certif-table-sort-wrapper">
                                        <span
                                            className="certif-table-sort-up"
                                            sort-col-id="frtext"
                                            sort-col-interactive-id="certif-labels-filter-id"
                                            sort-col-type="asc"
                                            onClick={sortTable}
                                        ></span>
                                        <span
                                            className="certif-table-sort-down"
                                            sort-col-id="frtext"
                                            sort-col-interactive-id="certif-labels-filter-id"
                                            sort-col-type="desc"
                                            onClick={sortTable}
                                        ></span>
                                    </span>
                                </th>
                                <th
                                    width=""
                                    id="description_th"
                                    className="certif-table-sort-col-wrapper"
                                >
                                    <span className="certif-table-sort-col-name">
                                        Description
                                    </span>
                                    <span className="certif-table-sort-wrapper">
                                        <span
                                            className="certif-table-sort-up"
                                            sort-col-id="description"
                                            sort-col-interactive-id="certif-labels-filter-id"
                                            sort-col-type="asc"
                                            onClick={sortTable}
                                        ></span>
                                        <span
                                            className="certif-table-sort-down"
                                            sort-col-id="description"
                                            sort-col-interactive-id="certif-labels-filter-id"
                                            sort-col-type="desc"
                                            onClick={sortTable}
                                        ></span>
                                    </span>
                                </th>
                                {/* <th width="">Identifier</th> */}
                                {/* <th width="">en-text</th> */}
                                {/* <th width="">fr-text</th> */}
                                {/* <th width="">Description</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {filteredTemplates.map((template, templateIndex) => (
                                <tr
                                    key={templateIndex}
                                    className="certif-admin-template-item-row"
                                >
                                    {
                                        fields.map(
                                            (field, fieldIndex) =>
                                                fieldIndex < 4 && (
                                                    <td
                                                        id={templateIndex}
                                                        key={fieldIndex}
                                                        data-id={template.id}
                                                        onClick={gotoEditPage}
                                                    >
                                                        {field === 'code' && <>{template[field]}</>}
                                                        {field === "entext" && <>{template[field]}</>}
                                                        {field === "frtext" && <>{template[field]}</>}
                                                        {field === "description" && <>{template[field]}</>}
                                                    </td>
                                                ),
                                        )
                                    }
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>

                <div className="certif-bar-wrapper">
                    <div className="certif-bar-right-buttons">
                        {/* <button className="btn btn-primary btn-sm" onClick={submitHandler}>
              Save
            </button> */}
                    </div>
                </div>
            </div >
        </>
    );
}

export default LabelsList;
