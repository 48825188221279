import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import LandingPage from "./layout/LandingPage";
import ContextComponent from "./../controls/AppContext";
import Utils from "../controls/Utils";

function MainPage() {
  let navigate = useNavigate();
  let [loadingState, setLoadingState] = useState(false);
  let session = Utils.getSession();
  let [pageInfo, setPageInfo] = useState(session);
  let [canRedirectToLogin, setCanRedirectToLogin] = useState(false);
  let [canRedirectToError, setCanRedirectToError] = useState(false);
  let [readyToRender, setReadyToRender] = useState(0);

  useEffect(() => {
    if (canRedirectToLogin) {
      setCanRedirectToLogin(false);
      navigate(Utils.APP_URLS.LOGIN_PAGE);
    }
  }, [canRedirectToLogin]);

  useEffect(() => {
    if (canRedirectToError) {
      setCanRedirectToError(false);
      navigate(Utils.APP_URLS.ERROR_PAGE);
    }
  }, [canRedirectToError]);

  return (
    <>
      <ContextComponent.Provider
        value={{
          pageInfo,
          setPageInfo,
          loadingState,
          setLoadingState,
          canRedirectToLogin,
          setCanRedirectToLogin,
          canRedirectToError,
          setCanRedirectToError,
          readyToRender,
          setReadyToRender,
        }}
      >
        <LandingPage />
      </ContextComponent.Provider>
    </>
  );
}

export default MainPage;
