import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import Utils from "../../controls/Utils";
import AppContextComponent from "../../controls/AppContext";

import { Container } from "react-bootstrap";
import "./../../assets/css/error.css";
import { useState } from "react";
import { useEffect } from "react";

const ErrorPage = ({ props }) => {
  let { readyToRender, setReadyToRender } = useContext(AppContextComponent);
  console.log(props);
  let [is404Page, setIs404Page] = useState(
    (props?.error && props.error === 404) ?? false,
  );
  let navigate = useNavigate();

  const gotoHomePage = (e) => {
    setReadyToRender(readyToRender + 1);
    navigate(Utils.APP_URLS.LANDING_PAGE);
  };

  useEffect(() => {
    console.log(is404Page);
  }, [is404Page]);

  return (
    <>
      <Container className="">
        <div className="certif-error-form-wrapper">
          <div className="certif-error-form-headings text-left">
            <div className="certif-error-form-text-error">The page you're looking for doesn't exist.</div>

            <div className="certif-error-form-text-desc">
              {/* <p>
                Either the current page doesn't exists or your don't have
                required permissions to see curent page.
              </p> */}
              <br></br>
              <p>
                Check for a typo in the URL, or go to the site home
              </p>
              {/* <p>Please contact adiministrator.</p> */}
            </div>
          </div>

          {!is404Page && (
            <div className="certif-add-footer">
              <button
                type="button"
                className="btn btn-info"
                onClick={gotoHomePage}
              >
                Go to home page
              </button>
            </div>
          )}
        </div>
      </Container>
    </>
  );
};

export default ErrorPage;
