import { useState, useEffect, useContext } from "react";
import { useLocation, NavLink } from "react-router-dom";
import { useNavigate } from "react-router";

import AppContextComponent from "./../../../controls/AppContext";
import AdminSection1Body from "./AdminSection1Body";
import Utils from "../../../controls/Utils";

function AdminSection1() {
  const location = useLocation();
  const ACTIVE_CLASSNAME = "active";

  const supportedRootPaths = [
    Utils.APP_URLS.ADMIN_PAGE_SECTION_1,
    Utils.APP_URLS.ADMIN_PAGE_SECTION_1 + "/",
    Utils.APP_URLS.LEGAL_ENTITY,
    Utils.APP_URLS.LEGAL_ENTITY + "/",
  ];

  const isAtRootUrl = supportedRootPaths.indexOf(location?.pathname) !== -1;

  let [canRender, setCanRender] = useState(false);

  useEffect(() => {
    setCanRender(true);
  }, []);

  const navigationItemKeys = [
    "legalentity",
    "emailTemplate",
  ];

  const navLinkValues = {
    legalentity: {
      label: "Legal Entity |",
      id: "LEGAL_ENTITY",
      url: Utils.APP_URLS.LEGAL_ENTITY,
    },
    emailTemplate: {
      label: "Email Template",
      id: "EMAIL_TEMPLATE",
      url: Utils.APP_URLS.EMAIL_TEMPLATE,
    },
  };

  return (
    <>
      {canRender && (
        <div className="container certif-admin-container">
          <div className="d-flex certif-admin-section-wrapper">
            {navigationItemKeys.map((navKey, index, src) => (
              <div
                className={`certif-admin-section-item certif-admin-section-item-${navKey}`}
                key={index}
              >
                <NavLink
                  className={({ isActive }) => {
                    if (isAtRootUrl) {
                      isActive = index === 0;
                    }
                    return isActive ? ACTIVE_CLASSNAME : "";
                  }}
                  to={navLinkValues[navKey].url}
                  id={navKey}
                >
                  {({ isActive }) => (
                    <>
                      <div className="certif-admin-section-nav-items">
                        <div className="certif-admin-section-nav-item">
                          <div>{navLinkValues[navKey].label}</div>
                          <div className="certif-arrow-up certif-set-buttom"></div>
                        </div>
                      </div>
                    </>
                  )}
                </NavLink>
              </div>
            ))}
          </div>

          <div className="certif-admin-section-container-body">
            <AdminSection1Body />
          </div>
        </div>
      )}
    </>
  );
}

export default AdminSection1;
