import { useState, useEffect, useRef, useContext } from "react";

import "react-datepicker/dist/react-datepicker.css";

import ProgramDetailsTable from "./ProgramDetailsTable";
import Utils from "../../../controls/Utils";
import AppContextComponent from "../../../controls/AppContext";
import Error from "../../layout/body/Error";
import ErrorMessage from "../../layout/body/ErrorMessage";

function ProgramDetailsPage() {
  let {
    setLoadingState,
    setCanRedirectToLogin,
    setCanRedirectToError,
    setPageInfo,
  } = useContext(AppContextComponent);
  let [errorMessageKey, setErrorMessageKey] = useState("");
  let [errorFields, setErrorFields] = useState([]);

  let [programDetails, setProgramDetails] = useState({});

  let [canRender, setCanRender] = useState(false);
  let [canRenderProgramDetailsTable, setCanRenderProgramDetailsTable] =
    useState(false);

  useEffect(() => {
    let validSession = false;
    let session = Utils.getSession();
    session?.userInfo?.certifRoles?.length > 0 && (validSession = true);

    if (validSession) {
      setCanRender(true);
    } else {
      Utils.handleDefaultError({
        errorCode: 403,
        setLoadingState,
        setCanRedirectToError,
        gotoErrorPage: true,
        setPageInfo,
      });
    }
  }, []);

  let [formValues, setFormValues] = useState({
    programNumber: "",
    typeOfUser: "",
  });

  const changeInputHandler = (e) => {
    let ele = e.currentTarget;
    let tempFormValues = { ...formValues };
    tempFormValues[e.currentTarget.id] = ele.value;
    // console.log(tempFormValues);
    setFormValues(tempFormValues);
  };

  const getProgramDetails = (e) => {
    console.log(formValues);
    setLoadingState({
      applyMask: true,
    });
    const success = (res) => {
      if (typeof res.data === "string") {
        setErrorMessageKey(res.data);
      } else {
        setErrorMessageKey("");
        setProgramDetails(res.data);
        setCanRenderProgramDetailsTable(true);
      }
      setLoadingState({
        applyMask: false,
      });
    };

    const fail = (err) => {
      Utils.handleDefaultError({
        err,
        setLoadingState,
        setCanRedirectToLogin,
        setCanRedirectToError,
        setPageInfo,
      });
    };

    if (formValues.programNumber && formValues.typeOfUser) {
      setErrorFields([]);
      Utils.getProgramDetails(formValues, formValues.programNumber, formValues.typeOfUser).then(
        success,
        fail,
      );
    } else {
      let tempErrorFields = {};
      let fieldId = "programDetailsPage";
      if (!tempErrorFields[fieldId]) {
        tempErrorFields[fieldId] = {
          fieldId,
          errorTypes: [] // Initialize an array for error types
        };
      }
      tempErrorFields[fieldId].errorTypes.push("empty");
      let errorFields = Object.values(tempErrorFields);
      // console.log("final errorObject::: ", errorFields);
      setErrorFields(errorFields);
      setLoadingState({
        applyMask: false,
      });
    }
  };

  return (
    <>
      {canRender && (
        <>
          <div className="certif-non-home-page-wrapper">
            <ErrorMessage props={{ errorMessageKey }} />
            <Error props={{ errorMessageArray: errorFields }} />
            <div className="table-responsive">
              <table className="table table-borderless m-0">
                <thead className="certif-table-header">
                  <tr>
                    <th className="certif-table-header-label" colSpan="3">
                      Program Details
                    </th>
                  </tr>
                </thead>
                <tbody className="certif-table-body certif-report-top-tbody">
                  <tr>
                    <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                      <label>
                        Program Number
                        <span className="certif-required-indicator">*</span>
                      </label>
                      <div>
                        <input
                          type="text"
                          id={"programNumber"}
                          value={formValues.programNumber}
                          onChange={changeInputHandler}
                          className="certif-input-field certif-required-field"
                        />
                      </div>
                    </td>

                    <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                      <label>
                        Type of user
                        <span className="certif-required-indicator">*</span>
                      </label>
                      <div>
                        <select
                          id={"typeOfUser"}
                          value={formValues.typeOfUser}
                          className="certif-input-field certif-required-field"
                          onChange={changeInputHandler}
                        >
                          <option value="">Select</option>
                          <option value="I">Internal</option>
                          <option value="E">External</option>
                        </select>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <table className="table table-borderless m-0">
                <tfoot className="certif-table-footer">
                  <tr>
                    <th colSpan="3">
                      <button
                        id={"export"}
                        className="btn btn-primary btn-sm"
                        onClick={getProgramDetails}
                      >
                        Get Program Details
                      </button>
                    </th>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>

          {canRenderProgramDetailsTable && (
            <ProgramDetailsTable props={{ programDetails }} />
          )}
        </>
      )}
    </>
  );
}

export default ProgramDetailsPage;
