import { useState, useEffect, useRef, useContext } from "react";
import { useTranslation, Trans } from "react-i18next";
import Utils from "../../../controls/Utils";
import Ribbon from "./Ribbon";
import Error from "./Error";
import AppContextComponent from "../../../controls/AppContext";

function GeneralInformation() {
  let { setLoadingState, pageInfo, setPageInfo, setCanRedirectToLogin, setCanRedirectToError } =
    useContext(AppContextComponent);
  let { t } = useTranslation();

  // console.log("Gen Info PageInfo", pageInfo.programNumber);

  let active = Utils.PAGES[1];
  let existingValues = Utils.getSession()[active] ?? {};
  // console.log(existingValues);

  let [canRender, setCanRender] = useState(false);
  let [canContinue, setCanContinue] = useState(false);
  let [errorMessageKey, setErrorMessageKey] = useState("");
  let [errorFields, setErrorFields] = useState([]);

  let RequesterDD = useRef(null);
  let BeneficiaryDD = useRef(null);
  let StatusDD = useRef(null);

  let [requestorDefaultValue, setRequestorDefaultValue] = useState("");
  let [beneficiaryDefaultValue, setBeneficiaryDefaultValue] = useState("");
  let [statusDefaultValue, setStatusDefaultValue] = useState("");

  let [requestor, setRequestor] = useState("");
  let [beneficiary, setBeneficiary] = useState("");
  let [status, setStatus] = useState("");

  let [requestorOptions, setRequestorOptions] = useState([]);
  let [beneficiaryOptions, setBeneficiaryOptions] = useState([]);
  let [statusOptions, setStatusOptions] = useState([]);

  const anyCallback = () => {
    setLoadingState({
      applyMask: false,
    });
  };

  const genericLookupDD = (options = {}) => {
    const reqSuccess = (res) => {
      // Sort the res.data array based on res.data.value
      res.data.sort((itemA, itemB) => {
        return itemA.displayName.localeCompare(itemB.displayName);
      });
      // console.log(res);

      let selectOptions = [
        {
          value: "",
          displayName: t("select"),
        },
        ...res.data,
      ];
      options.callback(selectOptions);
      anyCallback();
    };

    const reqFail = ({ error }) => {
      Utils.handleDefaultError({
        error,
        setLoadingState,
        setCanRedirectToLogin,
        setCanRedirectToError,
        setPageInfo,
      });
    };

    setLoadingState({
      applyMask: true,
    });
    Utils.getLookupDD(options.qryOpts).then(reqSuccess, reqFail);
  };

  const requestorLookupDD = () => {
    const callback = (selectOptions) => {
      // console.log("requestor query options", selectOptions);
      setRequestorOptions(selectOptions);
      setCanRender(true);
    };

    genericLookupDD({
      callback,
      qryOpts: {
        lookup: "requestor",
        programNumber: pageInfo.programNumber,
      },
    });
  };

  useEffect(() => {
    requestorLookupDD();
  }, []);

  const updateRequestorDD = (val) => {
    BeneficiaryDD.current.value = "";
    setBeneficiaryOptions([]);
    BeneficiaryDD.current.setAttribute("disabled", "");

    StatusDD.current.value = "";
    setStatusOptions([]);
    StatusDD.current.setAttribute("disabled", "");

    if (val === "") {
    } else {
      const callback = (selectOptions) => {
        BeneficiaryDD.current.removeAttribute("disabled");
        setBeneficiaryOptions(selectOptions);
      };

      genericLookupDD({
        qryOpts: {
          lookup: "beneficiary",
          subLookup: val,
          programNumber: pageInfo.programNumber,
        },
        callback,
      });
    }
  };

  const onChangeRequestorDD = (e) => {
    let val = e?.currentTarget?.value || "";
    setRequestor(val);
    updateRequestorDD(val, true);
  };

  const updateBeneficiaryDD = (val) => {
    setBeneficiary(val);

    StatusDD.current.value = "";
    setStatusOptions([]);
    StatusDD.current.setAttribute("disabled", "");

    if (val === "") {
    } else {
      const callback = (selectOptions) => {
        StatusDD.current.removeAttribute("disabled");
        setStatusOptions(selectOptions);
      };

      genericLookupDD({
        qryOpts: {
          lookup: "status",
          subLookup: val,
          programNumber: pageInfo.programNumber,
        },
        callback,
      });
    }
  };

  const onChangeBeneficiaryDD = (e) => {
    let val = e?.currentTarget?.value || "";
    updateBeneficiaryDD(val, true);
  };

  const onChangeStatusDD = (e) => {
    let value = e?.currentTarget?.value || "";
    setStatus(value);
  };

  useEffect(() => {
    if (requestor !== "" && beneficiary !== "" && status !== "") {
      setCanContinue(true);
    } else {
      setCanContinue(false);
    }
  }, [requestor, beneficiary, status]);

  // useEffect(() => {
  //   if (!Utils.isObjectEmpty(existingValues)) {
  //     // RequesterDD.current.value = existingValues.requester;
  //     // updateRequestorDD(existingValues.requester);

  //     // updateBeneficiaryOfTheRequestDD(existingValues.beneficiary);
  //     // BeneficiaryDD.current.value = existingValues.beneficiary;

  //     // StatusDD.current.value = existingValues.status;

  //     // setRequestorDefaultValue(existingValues.requester);
  //     // setBeneficiaryDefaultValue(existingValues.beneficiary);
  //     // setStatusDefaultValue(existingValues.status);
  //   }
  // }, []);

  useEffect(() => { }, [
    requestorDefaultValue,
    beneficiaryDefaultValue,
    statusDefaultValue,
  ]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (canContinue) {
      setErrorMessageKey("");
      setErrorFields([]);
      // store the information and continue
      let storingObj = {};
      storingObj[active] = {
        requester: RequesterDD.current.value,
        beneficiary: BeneficiaryDD.current.value,
        status: StatusDD.current.value,
      };

      storingObj.path = Utils.PAGES[2];

      // console.log(storingObj);
      Utils.store(storingObj, setPageInfo);
    } else {
      // show error
      setErrorMessageKey("requiredWarning");
      let tempErrorFields = {};
      if (!tempErrorFields["genInfoDropdown"]) {
        tempErrorFields["genInfoDropdown"] = {
          fieldId: "genInfoDropdown",
          errorTypes: [] // Initialize an array for error types
        };
      }
      tempErrorFields["genInfoDropdown"].errorTypes.push("empty");
      let errorFields = Object.values(tempErrorFields);
      setErrorFields(errorFields);
    }
  };

  return (
    <>
      {canRender && (
        <div>
          <div className="certif-intro-title">{t("genInfo")}</div>
          <p>{t("genInfoP1")}</p>
          <p>{t("genInfoP2")}</p>
          <p>
            <Trans i18nKey="genInfoP3" components={[<strong />]} />
          </p>
          <p>
            <Trans i18nKey="genInfoP4" components={[<strong />]} />
          </p>
          <p>
            <Trans i18nKey="genInfoP5" components={[<strong />]} />
          </p>
          <p>{t("genInfoP6")}</p>
          <p>{t("genInfoP7")}</p>
          <p>{t("genInfoP8")}</p>

          <Ribbon props={{ active }} />

          {/* <Error props={{ errorMessageKey }} /> */}
          <Error props={{ errorMessageArray: errorFields }} />

          <div className="table-responsive">
            <table className="table table-borderless">
              <thead className="certif-table-header">
                <tr>
                  <th className="certif-table-header-label">{t("genInfoTableHeading")}</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody className="certif-table-body">
                <tr>
                  <td>
                    <label>
                      {t("typeOfRequestor")}{" "}
                      <span className="certif-required-indicator">*</span>
                    </label>
                    <div>
                      <select
                        onChange={onChangeRequestorDD}
                        ref={RequesterDD}
                        defaultValue={requestorDefaultValue}
                      >
                        {requestorOptions?.map((requestor, index) => (
                          <option value={requestor.value} key={index}>
                            {requestor.displayName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </td>
                  <td>
                    <label>
                      {t("beneficiaryOfTheRequest")}{" "}
                      <span className="certif-required-indicator">*</span>
                    </label>
                    <div>
                      <select
                        defaultValue={beneficiaryDefaultValue}
                        onChange={onChangeBeneficiaryDD}
                        ref={BeneficiaryDD}
                        disabled
                      >
                        {beneficiaryOptions?.map((beneficiary, index) => (
                          <option value={beneficiary.value} key={index}>
                            {beneficiary.displayName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </td>
                  <td>
                    <label>
                      {t("status")}{" "}
                      <span className="certif-required-indicator">*</span>
                    </label>
                    <div>
                      <select
                        onChange={onChangeStatusDD}
                        ref={StatusDD}
                        disabled
                        defaultValue={statusDefaultValue}
                      >
                        {statusOptions?.map((status, index) => (
                          <option value={status.value} key={index}>
                            {status.displayName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot className="certif-table-footer">
                <tr>
                  <th></th>
                  <th></th>
                  <th>
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={submitHandler}
                    >
                      {t("continue")}
                    </button>
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      )}
    </>
  );
}

export default GeneralInformation;
