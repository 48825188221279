import { useState, useEffect, useRef, useContext } from "react";

import MarketingContentList from "./MarketingContentList";
import AddEditMarketingContent from "./AddEditMarketingContent";
import Utils from "../../../../controls/Utils";
import AppContextComponent from "../../../../controls/AppContext";

function MarketingContentDialog({ props }) {
    let sellOffId = props.sellingOfficeRecord.sellingOfficeId;
    let [sellingOfficeId, setSellingOfficeId] = useState(sellOffId);
    let [languages, setLanguages] = useState([]);
    let [languagesObj, setLanguagesObj] = useState({});
    let { setLoadingState } = useContext(AppContextComponent);

    let [canRender, setCanRender] = useState(false);
    let [pageMode, setPageMode] = useState("list");
    let [marktContents, setMarktContents] = useState([]);
    let [fields, setFields] = useState([
        "language",
        "marketingContent",
        "sellingOfficeId",
    ]);
    let [recordIndex, setRecordIndex] = useState(-1);

    useEffect(() => {
        setLoadingState({
            applyMask: true,
        });
        const fail = (error) => {
            console.log(fail);
            setLoadingState({
                applyMask: false,
            });
        };
        const fetchSellOfficMrktCont = () => {
            const sellOfficMrktContSuccess = (markConts) => {
                // console.log("selling Office Marketing Content", markConts);
                setMarktContents(markConts.data);
                setCanRender(true);
                setLoadingState({
                    applyMask: false,
                });
            };
            Utils.getSellOfficMrktCont1(sellOffId).then(sellOfficMrktContSuccess, fail);
        };
        const langSuccess = (langs) => {
            // console.log("languages codes -- ", langs);
            setLanguages(langs);
            let langObj = {};
            langs.forEach((lang) => {
                langObj[lang.languageCode] = lang.languageText;
            });
            setLanguagesObj(langObj);
            fetchSellOfficMrktCont();
        };
        Utils.getStoredLanguages().then(langSuccess, fail);
    }, []);

    useEffect(() => { }, [fields, marktContents, pageMode]);

    return (
        <>
            {canRender && (
                <div className="table-responsive">
                    {pageMode === "list" && (
                        <MarketingContentList
                            props={{
                                fields,
                                marktContents,
                                setPageMode,
                                recordIndex,
                                sellingOfficeId,
                                setRecordIndex,
                                languagesObj,
                            }}
                        />
                    )}
                    {pageMode === "add" && (
                        <AddEditMarketingContent
                            props={{
                                fields,
                                marktContents,
                                sellingOfficeId,
                                setMarktContents,
                                setPageMode,
                                mode: "add",
                                languagesObj,
                            }}
                        />
                    )}
                    {pageMode === "edit" && (
                        <AddEditMarketingContent
                            props={{
                                fields,
                                marktContents,
                                setMarktContents,
                                sellingOfficeId,
                                setPageMode,
                                recordIndex,
                                setRecordIndex,
                                mode: "edit",
                                languagesObj,
                            }}
                        />
                    )}
                </div>
            )}
        </>
    );
}

export default MarketingContentDialog;
