import { useState, useEffect } from "react";
import { useNavigate } from "react-router";

import Utils from "../../controls/Utils";
import AdminLeftNavigation from "./AdminLeftNavigation";
import AdminBody from "./AdminBody";
import "./../../assets/css/admin.css";

function AdminPage() {
  const navigate = useNavigate();
  let [canRender, setCanRender] = useState(false);

  useEffect(() => {
    let session = { ...Utils.getSession() };
    // if (session?.reload) {
    //   console.log(session);
    //   let storeObj = {
    //     reload: false,
    //   };
    //   Utils.store(storeObj, () => {});
    //   window.location.reload(false);
    // }
    // debugger;
    if (Utils.getToken() && !Utils.isGuestAccess()) {
      setCanRender(true);
    } else {
      // Utils.setStoreObj(10, setPageInfo);
      navigate(Utils.APP_URLS.LOGIN_PAGE);
    }
  }, []);

  return (
    <>
      {canRender && (
        <div className="certif-admin-container d-flex certif-non-home-page-wrapper">
          <AdminLeftNavigation />
          <AdminBody />
        </div>
      )}
    </>
  );
}

export default AdminPage;
