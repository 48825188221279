import { useState, useEffect, useRef, useContext } from "react";
import { useTranslation, Trans } from "react-i18next";

import Utils from "../../../controls/Utils";
import Error from "../../layout/body/Error";
import AppContextComponent from "../../../controls/AppContext";

import Ribbon from "../../layout/body/Ribbon";


function AuditPage() {


    return (
        <>
            This is Audit Information Page.
        </>
    );
}

export default AuditPage;
