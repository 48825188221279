import { useEffect, useState, useContext } from "react";
import { useTranslation, Trans } from "react-i18next";
import { Link } from "react-router-dom";
import AppContextComponent from "../../../controls/AppContext";

import Ribbon from "./Ribbon";
import Error from "./Error";

import CheckMarkImg from "./../../../assets/img/icon-checkmark.png";
import iPhoneImg from "./../../../assets/img/iPhone.png";
import AppleImg from "./../../../assets/img/Apple.png";
import WindowsImg from "./../../../assets/img/Windows.png";
import BlackBerryImg from "./../../../assets/img/Blackberry.png";
import AndroidImg from "./../../../assets/img/Android.png";
import Img24 from "./../../../assets/img/24.png";
import LinkedlnImg from "./../../../assets/img/Linkedin.png";
import TwitterImg from "./../../../assets/img/Twitter.png";
import InstagramImg from "./../../../assets/img/Instagram.png";
import YouTubeImg from "./../../../assets/img/Youtube.png";
import VimeoImg from "./../../../assets/img/Vimeo.png";
import Utils from "../../../controls/Utils";

function Validation({ props }) {
  let { setPageInfo } = useContext(AppContextComponent);
  let active = Utils.PAGES[4];
  let { t } = useTranslation();
  let [errorMessageKey, setErrorMessageKey] = useState("");

  const submitHandler = (e) => {
    Utils.resetSession(["general_information", "requests", "externalEmails"]);
    Utils.setStoreObj(1, setPageInfo);
    window.location.reload(false);
  };

  function LinkText({ href, children }) {
    return <Link to={href || ""}>{children}</Link>;
  }

  return (
    <>
      <div>
        <div className="certif-intro-title">{t("validation")}</div>
        <p>{t("validationP1")}</p>
        <p>{t("validationP2")}</p>
        <p>{t("validationP3")}</p>

        <Ribbon props={{ active }} />

        <Error props={{ errorMessageKey }} />

        <div className="table-responsive">
          <table className="table table-borderless">
            <thead className="certif-table-header">
              <tr>
                <th className="certif-table-header-label">
                  {t("certificateOfAssistance")}
                </th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody className="certif-table-body">
              <tr className="certif-table-validation-tr">
                <td>
                  <img src={CheckMarkImg} alt="remove" />
                </td>
              </tr>
              <tr className="certif-table-validation-tr">
                <td className="col-sm-3 certify-validation-body-left d-flex">
                  <div className="certify-validation-iphone-img-wrapper">
                    <img src={iPhoneImg} alt="remove" />
                  </div>
                  <div className="certify-validation-social-imgs-wrapper">
                    <img src={AppleImg} alt="remove" />
                    <img src={WindowsImg} alt="remove" />
                    <img src={BlackBerryImg} alt="remove" />
                    <img src={AndroidImg} alt="remove" />
                  </div>
                </td>
                <td className="col-sm-9 certify-validation-body-right">
                  {props.marktContApiRes !== null &&
                    props.marktContApiRes !== "" ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: props.marktContApiRes,
                      }}
                    ></div>
                  ) : (
                    <p> </p>
                    // <>
                    //   <div>
                    //     <p className="certif-validation-greet">
                    //       {t("validationGreet")}
                    //     </p>
                    //     <p className="certif-validation-greet-msg">
                    //       <Trans
                    //         i18nKey="validationGreetMsg"
                    //         components={{
                    //           linkTag0: <br />,
                    //           linkTag1: (
                    //             <LinkText href="https://www.internationalsos.com/assistance-app" />
                    //           ),
                    //         }}
                    //       />
                    //     </p>
                    //   </div>
                    //   <div className="certif-validation-contact-us-wrapper">
                    //     <div className="certif-validation-contact-us-img-wrapper">
                    //       <img src={Img24} alt="remove" />
                    //     </div>
                    //     <div>{t("contactUsMsg")}</div>
                    //   </div>
                    // </>
                  )}
                </td>
              </tr>
              <tr className="certif-table-validation-tr">
                <td>
                  <span>{t("followUsOn")}:</span>
                  <span className="certif-validation-social-share-img-wrapper">
                    <a href="https://www.linkedin.com/company/international-sos"><img src={LinkedlnImg} alt="remove" /></a>
                    <a href="https://twitter.com/IntlSOS"><img src={TwitterImg} alt="remove" /></a>
                    <a href="https://www.instagram.com/intlsos/"><img src={InstagramImg} alt="remove" /></a>
                    <a href="https://www.youtube.com/user/intlsos"><img src={YouTubeImg} alt="remove" /></a>
                    <a href="https://vimeo.com/internationalsos"><img src={VimeoImg} alt="remove" /></a>
                  </span>
                  <span>
                    <a href="https://www.internationalsos.com/">
                      internationalsos.com
                    </a>
                  </span>
                </td>
              </tr>
              <tr className="certif-table-validation-tr d-none">
                <td>
                  <a href="https://www.internationalsos.com/">
                    internationalsos.com
                  </a>
                </td>
              </tr>
            </tbody>
            <tfoot className="certif-table-footer">
              <tr>
                <th>
                  <button
                    className="btn btn-primary btn-sm certif-align-btn-left"
                    onClick={submitHandler}
                  >
                    {t("newRequest")}
                  </button>
                </th>
                <th></th>
                <th></th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </>
  );
}

export default Validation;
