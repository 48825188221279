// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.certif-header-title-wrapper {
    font-weight: bold;
    font-size: 22px;
    /* margin-top: 14px; */
    margin-left: 32px;
    padding: 0;
}

.certif-header-sub-title {
    font-size: 18px;
    font-weight: normal;
    font-style: italic;
}

.certif-header-section {
    align-items: center;
}

.certif-header-section.certif-header-right-section {
    justify-content: flex-end;
    right: 20px;
    position: relative;
}

.certif-header-user-menu {
    margin-left: 5px;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #ccc;
    margin-top: 2px;
}

.indi-header-user-profile-firstname {
    display: flex;
    align-items: center;
    opacity: 0.5;
}


.indi-user-profile-wrapper .show.dropdown .indi-header-user-profile-firstname,
.indi-user-profile-wrapper:hover .indi-header-user-profile-firstname {
    opacity: 1;
}

.certif-header-login-hidden-link {
    opacity: 0;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/header.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,eAAe;IACf,sBAAsB;IACtB,iBAAiB;IACjB,UAAU;AACd;;AAEA;IACI,eAAe;IACf,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,QAAQ;IACR,SAAS;IACT,kCAAkC;IAClC,mCAAmC;IACnC,0BAA0B;IAC1B,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,YAAY;AAChB;;;AAGA;;IAEI,UAAU;AACd;;AAEA;IACI,UAAU;IACV,eAAe;AACnB","sourcesContent":[".certif-header-title-wrapper {\n    font-weight: bold;\n    font-size: 22px;\n    /* margin-top: 14px; */\n    margin-left: 32px;\n    padding: 0;\n}\n\n.certif-header-sub-title {\n    font-size: 18px;\n    font-weight: normal;\n    font-style: italic;\n}\n\n.certif-header-section {\n    align-items: center;\n}\n\n.certif-header-section.certif-header-right-section {\n    justify-content: flex-end;\n    right: 20px;\n    position: relative;\n}\n\n.certif-header-user-menu {\n    margin-left: 5px;\n    width: 0;\n    height: 0;\n    border-left: 6px solid transparent;\n    border-right: 6px solid transparent;\n    border-top: 6px solid #ccc;\n    margin-top: 2px;\n}\n\n.indi-header-user-profile-firstname {\n    display: flex;\n    align-items: center;\n    opacity: 0.5;\n}\n\n\n.indi-user-profile-wrapper .show.dropdown .indi-header-user-profile-firstname,\n.indi-user-profile-wrapper:hover .indi-header-user-profile-firstname {\n    opacity: 1;\n}\n\n.certif-header-login-hidden-link {\n    opacity: 0;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
