import { useState, useEffect, useContext } from "react";
import { Routes, Route } from "react-router-dom";

import Utils from "../../../controls/Utils";
import CountryPage from "../Country/CountryPage";
import ErrorPage from "../../utilpages/ErrorPage";

function AdminSection4Body() {
  let [canRender, setCanRender] = useState(false);

  useEffect(() => {
    setCanRender(true);
  }, []);

  return (
    <>
      {canRender && (
        <Routes>
          <Route path={`/`} exact element={<CountryPage />}></Route>
          <Route
            path={Utils.APP_URLS.COUNTRY_MANAGEMENT.replace(
              Utils.APP_URLS.ADMIN_PAGE_SECTION_4,
              "",
            )}
            element={<CountryPage />}
          ></Route>
          <Route
            path={`*`}
            element={<ErrorPage props={{ error: 404 }} />}
          ></Route>
        </Routes>
      )}
    </>
  );
}

export default AdminSection4Body;
