import { Routes, Route, useLocation } from "react-router-dom";

import "./../../../assets/css/landing.css";
import "./../../../assets/css/header.css";
import "./../../../assets/css/body.css";
import "./../../../assets/css/footer.css";
import "./../../../assets/css/navigation.css";

import AdminLandingPage from "./../../adminlayout/AdminPage";
import ReportsPage from "./../../adminlayout/ReportsPage";
import ProgramDetailsPage from "./../../adminlayout/ProgramDetails/ProgramDetailsPage";
import AnonLandingPage from "./AnonLandingPage";
import ErrorPage from "../../utilpages/ErrorPage";
import TermsAndConditionsPage from "../../TermsAndConditionsPage";
import Utils from "../../../controls/Utils";
import LogoutRedirectPage from "../../logout/LogoutRedirectPage";

function Body() {
  return (
    <Routes>
      <Route
        path={`${Utils.APP_URLS.ADMIN_PAGE}/*`}
        element={<AdminLandingPage />}
      ></Route>
      <Route
        path={`${Utils.APP_URLS.REPORTS_PAGE}`}
        element={<ReportsPage />}
      ></Route>
      <Route
        path={`${Utils.APP_URLS.PROGRAM_DETAILS_PAGE}`}
        element={<ProgramDetailsPage />}
      ></Route>
      <Route
        path={`${Utils.APP_URLS.TERMS_PAGE}`}
        element={<TermsAndConditionsPage />}
      ></Route>
      <Route
        path={`${Utils.APP_URLS.LOGOUT_REDIRECT_PAGE}`}
        element={<LogoutRedirectPage />}
      ></Route>
      <Route
        path={`${Utils.APP_URLS.ERROR_PAGE}`}
        element={<ErrorPage />}
      ></Route>
      <Route path={`/`} element={<AnonLandingPage />}></Route>
      <Route path={``} element={<AnonLandingPage />}></Route>
      <Route path={`*`} element={<ErrorPage />}></Route>
    </Routes>
  );
}

export default Body;
