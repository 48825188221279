import { useState, useEffect, useRef, useContext } from "react";

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Form } from "react-bootstrap";

import Utils from "../../controls/Utils";
import Ribbon from "../layout/body/Ribbon";
import Error from "../layout/body/Error";
import AppContextComponent from "../../controls/AppContext";

import CalImg from "./../../assets/img/icon-cal.png";

function ReportsPage() {
  let {
    setLoadingState,
    pageInfo,
    setCanRedirectToLogin,
    setCanRedirectToError,
    setPageInfo,
  } = useContext(AppContextComponent);

  let REQ_FIELDS = [
    "startDate", //
    "endDate", //
  ];

  const fields4ErrorTemplate = {
    startDate: "Start Date",
    endDate: "End Date",
  };

  let [startDate, setStartDate] = useState(null);
  let [endDate, setEndDate] = useState(null);
  let [programNumber, setProgramNumber] = useState("");

  let [sellingOffice, setSellingOffice] = useState([]);
  let [sellingOfficeList, setSellingOfficeList] = useState([]);

  let [role, setRole] = useState([]);
  let [roleList, setRoleList] = useState([]);

  let [destinationCountry, setDestinationCountry] = useState([]);
  let [destinationCountryList, setDestinationCountryList] = useState([]);

  let [user, setUser] = useState("");
  let [userList, setUserList] = useState("");

  let [canRender, setCanRender] = useState(false);
  let [currentLocale, setCurrentLocale] = useState(pageInfo.locale);

  useEffect(() => {
    setLoadingState({
      applyMask: true,
    });
    const commonFail = (err) => {
      Utils.handleDefaultError({
        err,
        setLoadingState,
        setCanRedirectToLogin,
        setCanRedirectToError,
        setPageInfo,
      });
    };

    const userSuccess = (res) => {
      res.data.sort((itemA, itemB) => {
        return itemA.userName.localeCompare(itemB.userName);
      });
      setUserList(res.data);
      setCanRender(true);
      setLoadingState({
        applyMask: false,
      });
    };

    const destinationCountrySuccess = (res) => {
      res.data.sort((itemA, itemB) => {
        return itemA.countryName.localeCompare(itemB.countryName);
      });
      setDestinationCountryList(res.data);
      Utils.fetchData("USER").then(userSuccess, commonFail);
    };

    const rolesSuccess = (res) => {
      res.data.sort((itemA, itemB) => {
        return itemA.displayName.localeCompare(itemB.displayName);
      });
      setRoleList(res.data);
      Utils.fetchData("DESTINATION_COUNTRY").then(
        destinationCountrySuccess,
        commonFail,
      );
    };

    const sellingOfficeSuccess = (res) => {
      res.data.sort((itemA, itemB) => {
        return itemA.sellingOfficeName.localeCompare(itemB.sellingOfficeName);
      });
      setSellingOfficeList(res.data);
      Utils.fetchData("ROLE").then(rolesSuccess, commonFail);
    };

    Utils.fetchData("SELLING_OFFICE").then(sellingOfficeSuccess, commonFail);
  }, []);

  let [formValues, setFormValues] = useState({
    startDate: "",
    endDate: "",
    programNumber: "",
    sellingOffice: [],
    role: [],
    destinationCountry: [],
    user: [],
  });

  const changeInputHandler = (e) => {
    let ele = e.currentTarget;
    let tempFormValues = { ...formValues };
    tempFormValues[e.currentTarget.id] = ele.value;
    // console.log(tempFormValues);
    setFormValues(tempFormValues);
  };

  const handleDateImgClick = (e) => {
    let idVal = e.currentTarget.getAttribute("data-target");
    let targetElement = document.getElementById(idVal);
    targetElement.focus();
  };

  const changeStartDate = (dt) => {
    changeDate(dt, setStartDate, "startDate");
  };

  const changeEndDate = (dt) => {
    changeDate(dt, setEndDate, "endDate");
  };

  const changeDate = (date, setFun, key) => {
    if (!date) {
      setFun("");
      return;
    }
    let month = (date.getMonth() + 1 + "").padStart(2, 0);
    let dt = (date.getDate() + "").padStart(2, 0);
    let year = date.getFullYear();
    let dateValue = year + "-" + month + "-" + dt;
    // console.log(dateValue);
    setFun(dateValue);

    let tempFormValues = { ...formValues };
    tempFormValues[key] = dateValue;
    setFormValues(tempFormValues);
  };

  const changeMultiSelectInputHandler = (e) => {
    let ele = e.currentTarget;
    let tempFormValues = { ...formValues };

    var options = [].slice.call(e.currentTarget.querySelectorAll("option"));
    var selected = options.filter(function (option) {
      return option.selected;
    });
    var selectedValues = selected.map(function (option) {
      return option.value;
    });

    // console.log(selectedValues);

    tempFormValues[e.currentTarget.id] = selectedValues;
    // console.log(tempFormValues);
    setFormValues(tempFormValues);
  };

  const exportHandler = (e) => {
    // console.log(formValues);
    const success = (res) => {
    // console.log(res);

      let contentType = res.headers.getContentType();
      let extn = contentType.split("/")[1];
      let fileExtn = "." + (extn === "csv" ? extn : "xls");
      let fileName = "certif_reports" + fileExtn;
      let contentDisposition = res.headers["content-disposition"];

      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
        // console.log("fileNameMatch", fileNameMatch);
        if (fileNameMatch.length === 2) {
          fileName = fileNameMatch[1];
        }
      }
      // console.log(fileName);

      let fileContent = res.data;

      const file = new Blob([fileContent], {
        type: `${contentType};CHARSET=utf-8`,
        // type: `${contentType}`,
      });
      const element = document.createElement("a");
      element.href = URL.createObjectURL(file);
      element.download = fileName;
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
    };

    const fail = (err) => {
      console.log(err);
    };

    Utils.exportToExcel(formValues).then(success, fail);
  };


  let [errorFields, setErrorFields] = useState({});

  const validateForm = () => {
    let tempErrorFields = {};

    REQ_FIELDS.forEach((fieldId) => {
      let field = document.getElementById(fieldId);
      if (field) {
        let fieldVal =
          field.type === "checkbox" ? field.checked : field?.value?.trim();
        if (fieldVal.length === 0) {
          if (!tempErrorFields[fieldId]) {
            // tempErrorFields[fieldId] = errorMessages[fieldId][0];
            tempErrorFields[fieldId] = Utils.ADMIN_SECTIONS_ERROR_TEMPLATE.replace('{}', fields4ErrorTemplate[fieldId]);
          }
        }
      }
    });
    // Travel start date cannot be greater than travel end date
    if (startDate && endDate) {
      // 1. Check weather end date is greater than Start Date!
      let fieldId = "startDate";
      if (new Date(startDate) > new Date(endDate)) {
        if (!tempErrorFields[fieldId]) {
          tempErrorFields[fieldId] = "Start date cannot be greater than end date";
        }
      }
    }

    setErrorFields(tempErrorFields);
    return tempErrorFields;
  }

  const exportHandlerCall = (e) => {
    setErrorFields({});
    let detailsObj = validateForm();
    // console.log(detailsObj);
    if (Utils.isObjectEmpty(detailsObj)) {
      exportHandler(e);
    }
  }

  return (
    <>
      {canRender && (
        <div className="certif-non-home-page-wrapper">
          <div className="table-responsive">
            <table className="table table-borderless m-0">
              <thead className="certif-table-header">
                <tr>
                  <th className="certif-table-header-label" colSpan="3">
                    Reports
                  </th>
                </tr>
              </thead>
              <tbody className="certif-table-body certif-report-top-tbody">
                <tr>
                  <td className="row">
                    <div className="col-sm-6">
                      <label>
                        Start Date
                        <span className="certif-required-indicator">*</span>
                      </label>
                      <div className="d-flex">
                        <DatePicker
                          className="certif-input-field certif-required-field"
                          id={"startDate"}
                          selected={!!startDate ? new Date(startDate) : null}
                          onChange={changeStartDate}
                          name="startDate"
                          locale={currentLocale}
                          dateFormat="yyyy-MM-dd"
                          maxDate={new Date()}
                        // minDate={new Date()}
                        />
                        <img
                          onClick={handleDateImgClick}
                          data-target={"startDate"}
                          className="certif-cal-img"
                          src={CalImg}
                          alt="Start date"
                        />
                      </div>
                      {errorFields.startDate && (<p className="error-message" style={{ color: "red" }}>
                        {errorFields.startDate}
                      </p>)}
                    </div>

                    <div className="col-sm-6">
                      <label>
                        End Date
                        <span className="certif-required-indicator">*</span>
                      </label>
                      <div className="d-flex">
                        <DatePicker
                          className="certif-input-field certif-required-field"
                          id={"endDate"}
                          selected={!!endDate ? new Date(endDate) : null}
                          onChange={changeEndDate}
                          name="endtDate"
                          locale={currentLocale}
                          dateFormat="yyyy-MM-dd"
                          // minDate={new Date()}
                          minDate={startDate ? new Date(startDate) : ""}
                          maxDate={new Date()}
                          disabled={!startDate} // disable till start date is selected
                        />
                        <img
                          onClick={handleDateImgClick}
                          data-target={"endDate"}
                          className="certif-cal-img"
                          src={CalImg}
                          alt="End date"
                        />
                      </div>
                      {errorFields.endDate && (<p className="error-message" style={{ color: "red" }}>
                        {errorFields.endDate}
                      </p>)}
                    </div>
                  </td>
                </tr>
              </tbody>
              <thead className="certif-table-header certif-admin-reports-tbody">
                <tr>
                  <th className="certif-table-header-label" colSpan="3">
                    Filters
                  </th>
                </tr>
              </thead>
              <tbody className="certif-table-body certif-report-bottom-tbody">
                <tr>
                  <td>
                    <label>Program Number</label>
                    <div>
                      <input
                        type="text"
                        id={"programNumber"}
                        defaultValue={programNumber}
                        onChange={changeInputHandler}
                        className="certif-input-field certif-required-field"
                      />
                    </div>
                  </td>
                </tr>

                <tr>
                  <td className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label>Selling office</label>
                    <div>
                      <Form.Select
                        className="certif-admin-reports-multiselect"
                        id={"sellingOffice"}
                        onChange={changeMultiSelectInputHandler}
                        multiple
                        aria-label="Select multiple selling offices"
                      >
                        {sellingOfficeList?.map((sellingOfficeItem, index) => (
                          <option
                            key={index}
                            value={sellingOfficeItem["sellingOfficeId"]}
                          >
                            {sellingOfficeItem["sellingOfficeName"]}
                          </option>
                        ))}
                      </Form.Select>
                    </div>
                  </td>

                  <td className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label>Role</label>
                    <div>
                      <Form.Select
                        className="certif-admin-reports-multiselect"
                        id={"role"}
                        onChange={changeMultiSelectInputHandler}
                        multiple
                        aria-label="Select multiple roles"
                      >
                        {roleList?.map((roleItem, index) => (
                          <option key={index} value={roleItem["value"]}>
                            {roleItem["displayName"]}
                          </option>
                        ))}
                      </Form.Select>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label>Destination country</label>
                    <div>
                      <Form.Select
                        className="certif-admin-reports-multiselect"
                        id={"destinationCountry"}
                        onChange={changeMultiSelectInputHandler}
                        multiple
                        aria-label="Select multiple destination countries"
                      >
                        {destinationCountryList?.map(
                          (destinationCountryItem, index) => (
                            <option
                              key={index}
                              value={destinationCountryItem["countryCode"]}
                            >
                              {destinationCountryItem["countryName"]}
                            </option>
                          ),
                        )}
                      </Form.Select>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>
                    {/* <label>Generated by user</label>
                    <div>
                      <select
                        id={"user"}
                        value={formValues.user}
                        className="certif-input-field certif-required-field"
                        onChange={changeInputHandler}
                      >
                        <option value="">Select</option>

                        {userList?.map((user, index) => (
                          <option key={index} value={user.email}>
                            {user.userName}
                          </option>
                        ))}
                      </select>
                    </div> */}
                    <label>Generated by user</label>
                    <div>
                      <Form.Select
                        className="certif-admin-reports-multiselect"
                        id={"user"}
                        onChange={changeMultiSelectInputHandler}
                        multiple
                        aria-label="Select multiple users"
                      >
                        {userList?.map(
                          (user, index) => (
                            <option
                              key={index}
                              value={user.email}
                            >
                              {user.userName}
                            </option>
                          ),
                        )}
                      </Form.Select>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <table className="table table-borderless m-0">
              <tfoot className="certif-table-footer">
                <tr>
                  <th colSpan="3">
                    <button
                      id={"export"}
                      className="btn btn-primary btn-sm"
                      onClick={exportHandlerCall}
                    >
                      Export To Excel
                    </button>
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      )}
    </>
  );
}

export default ReportsPage;
