import { useState, useEffect, useRef, useContext } from "react";

import Table from "react-bootstrap/Table";

import AppContextComponent from "../../../controls/AppContext";

import Utils from "../../../controls/Utils";
import DownloadImg from "./../../../assets/img/download.png";

function LegalEntityList({ props }) {
  let { setLoadingState } = useContext(AppContextComponent);

  // let fields = props.fields;
  // let templates = props.templates;

  let [fields, setFields] = useState([]);
  let [templates, setTemplates] = useState([]);
  let [filteredTemplates, setFilteredTemplates] = useState([]);
  let [transformedTemplates, setTransformedTemplates] = useState([]);
  const SORTABLE_FIELDS = [
    "entityId",
    "name",
    "sellingOfficeId",
    "building",
    "streetName",
    "letterHead",
  ];

  useEffect(() => {
    setFields(props.fields);
    setTemplates(props.templates);
    const addExtraField = (templates) => {
      return templates.map(template => ({
        ...template,
        extraField: getSellingOfficeName4Id(template.sellingOfficeId)
      }));
    };
    const modifiedTemplates = addExtraField(props.templates);

    setTransformedTemplates(modifiedTemplates);
	setFilteredTemplates(modifiedTemplates);
  }, [props.fields, props.templates]);

  const gotoAddPage = (e) => {
    props.setPageMode("add");
  };

  const getSellingOfficeName4Id = (sellOffcId) => {
    const selinOffc = props.sellingOfficeOptions?.find(office => office.sellingOfficeId === sellOffcId);
    if (!selinOffc) {
      return sellOffcId;
    }
    return selinOffc.sellingOfficeName;
  }

  const gotoEditPage = (e) => {
    // props.setPageMode('edit');
    // console.log(e.currentTarget.id);
    // Find the entityId from the event's target (the clicked <td> element)
    const dataId = e.target.getAttribute("data-id");
    // console.log(dataId);
    // console.log(typeof (dataId));

    const index = templates.findIndex(
      (template) => template.id === Number(dataId),
    );
    if (index !== -1) {
      props.setRecordIndex(index);
      props.setPageMode("edit");
    }
  };

  const filterTable = (e) => {
    Utils.filterTableData({
      event: e,
      templates: transformedTemplates,
      setTemplates,
      filteredTemplates,
      setFilteredTemplates,
    });
  };

  const sortTable = (e) => {
    Utils.sortTable({
      event: e,
      templates: transformedTemplates,
      setTemplates,
      filteredTemplates,
      setFilteredTemplates,
    });
  };

  const handleDownload = (e) => {
    let src = e.currentTarget.getAttribute("data-src");
    Utils.downloadFile({ src, setLoadingState });
  };

  return (
    <>
      <div className="certif-right-search-wrapper">
        <input
          type="text"
          placeholder={Utils.PLACEHOLDER_4_SEARCH_IN_ADMIN_SCREENS}
          onChange={filterTable}
          sort-type=""
          sort-key=""
          id="certif-legal-entity-filter-id"
        />
      </div>
      <div className="table-responsive certif-table-responsive-with-search">
        <div className="certif-bar-wrapper">
          <div className="certif-bar-left">Legal Entity</div>
          <div className="certif-bar-right" onClick={gotoAddPage}>
            +
          </div>
        </div>

        <div className="d-flex certif-table-with-scrolls">
          <Table stripped="" className="m-0" bordered hover size="md">
            <thead>
              <tr>
                <th
                  width=""
                  id="entityId_th"
                  className="certif-table-sort-col-wrapper"
                >
                  <span className="certif-table-sort-col-name">
                    Id
                  </span>
                  <span className="certif-table-sort-wrapper">
                    <span
                      className="certif-table-sort-up"
                      sort-col-id="entityId"
                      sort-col-interactive-id="certif-legal-entity-filter-id"
                      sort-col-type="asc"
                      onClick={sortTable}
                    ></span>
                    <span
                      className="certif-table-sort-down"
                      sort-col-id="entityId"
                      sort-col-interactive-id="certif-legal-entity-filter-id"
                      sort-col-type="desc"
                      onClick={sortTable}
                    ></span>
                  </span>
                </th>
                <th
                  width=""
                  id="name_th"
                  className="certif-table-sort-col-wrapper"
                >
                  <span className="certif-table-sort-col-name">Name</span>
                  <span className="certif-table-sort-wrapper">
                    <span
                      className="certif-table-sort-up"
                      sort-col-id="name"
                      sort-col-interactive-id="certif-legal-entity-filter-id"
                      sort-col-type="asc"
                      onClick={sortTable}
                    ></span>
                    <span
                      className="certif-table-sort-down"
                      sort-col-id="name"
                      sort-col-interactive-id="certif-legal-entity-filter-id"
                      sort-col-type="desc"
                      onClick={sortTable}
                    ></span>
                  </span>
                </th>
                <th
                  width=""
                  id="sellingOfficeId_th"
                  className="certif-table-sort-col-wrapper"
                >
                  <span className="certif-table-sort-col-name">
                    Selling Office Name
                  </span>
                  <span className="certif-table-sort-wrapper">
                    <span
                      className="certif-table-sort-up"
                      sort-col-id="sellingOfficeId"
                      sort-col-interactive-id="certif-legal-entity-filter-id"
                      sort-col-type="asc"
                      onClick={sortTable}
                    ></span>
                    <span
                      className="certif-table-sort-down"
                      sort-col-id="sellingOfficeId"
                      sort-col-interactive-id="certif-legal-entity-filter-id"
                      sort-col-type="desc"
                      onClick={sortTable}
                    ></span>
                  </span>
                </th>
                <th
                  width=""
                  id="building_th"
                  className="certif-table-sort-col-wrapper"
                >
                  <span className="certif-table-sort-col-name">Building</span>
                  <span className="certif-table-sort-wrapper">
                    <span
                      className="certif-table-sort-up"
                      sort-col-id="building"
                      sort-col-interactive-id="certif-legal-entity-filter-id"
                      sort-col-type="asc"
                      onClick={sortTable}
                    ></span>
                    <span
                      className="certif-table-sort-down"
                      sort-col-id="building"
                      sort-col-interactive-id="certif-legal-entity-filter-id"
                      sort-col-type="desc"
                      onClick={sortTable}
                    ></span>
                  </span>
                </th>
                <th
                  width=""
                  id="streetName_th"
                  className="certif-table-sort-col-wrapper"
                >
                  <span className="certif-table-sort-col-name">
                    Street Name
                  </span>
                  <span className="certif-table-sort-wrapper">
                    <span
                      className="certif-table-sort-up"
                      sort-col-id="streetName"
                      sort-col-interactive-id="certif-legal-entity-filter-id"
                      sort-col-type="asc"
                      onClick={sortTable}
                    ></span>
                    <span
                      className="certif-table-sort-down"
                      sort-col-id="streetName"
                      sort-col-interactive-id="certif-legal-entity-filter-id"
                      sort-col-type="desc"
                      onClick={sortTable}
                    ></span>
                  </span>
                </th>
                {/* <th width="">Legal Entity Id</th> */}
                {/* <th width="">Name</th> */}
                {/* <th width="">Selling Office Id</th> */}
                {/* <th width="">Building</th> */}
                {/* <th width="">Street Name</th> */}
                <th width="">Letter Head</th>
                {/* <th width="">cityName</th>
                                <th width="">countryName</th>
                                <th width="">postalCode</th>
                                <th width="">email</th>
                                <th width="">contactNumber</th>
                                <th width="">gmName</th> */}
              </tr>
            </thead>
            <tbody>
              {filteredTemplates.map((template, templateIndex) => (
                <tr
                  key={templateIndex}
                  className="certif-admin-template-item-row"
                >
                  {fields.map(
                    (field, fieldIndex) =>
                      fieldIndex < 6 && (
                        <td
                          id={templateIndex}
                          key={fieldIndex}
                          data-id={template.id}
                          onClick={gotoEditPage}
                          className={
                            field === "letterHead" ? "certif-download-td" : ""
                          }
                        >
                          {field === "entityId" && <>{template[field]}</>}
                          {field === "name" && <>{template[field]}</>}
                          {field === "sellingOfficeId" && (
                            <>{getSellingOfficeName4Id(template[field])}</>
                          )}
                          {field === "building" && <>{template[field]}</>}
                          {field === "streetName" && <>{template[field]}</>}
                          {field === "letterHead" && (
                            <>
                              {template[field]?.length > 0 ? (
                                <img
                                  className="certif-download-image"
                                  onClick={handleDownload}
                                  src={DownloadImg}
                                  data-src={template[field]}
                                  alt="download"
                                />
                              ) : (
                                ""
                              )}
                            </>
                          )}
                        </td>
                      ),
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>

        <div className="certif-bar-wrapper">
          <div className="certif-bar-right-buttons">
            {/* <button className="btn btn-primary btn-sm" onClick={submitHandler}>
              Save
            </button> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default LegalEntityList;
