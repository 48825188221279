// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.certif-login-form-wrapper {
    color: #2f4694;
    font-size: 16px;
}

.certif-login-form-text-login {
    font-weight: 600;
    font-size: 25px;
}

.certif-add-footer {
    margin-top: 15px;
}

.certif-add-footer button {
    color: #60464675;
}

.certif-add-footer button:focus,
.certif-add-footer button:hover {
    color: #604646;
}

/* --- Logout Redirect css Styles for text: Start --- */
.certif-logout-redirect-text-desc {
    font-size: 2vw;
}
/* --- Logout Redirect css Styles for text: End --- */
`, "",{"version":3,"sources":["webpack://./src/assets/css/login.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;;IAEI,cAAc;AAClB;;AAEA,uDAAuD;AACvD;IACI,cAAc;AAClB;AACA,qDAAqD","sourcesContent":[".certif-login-form-wrapper {\n    color: #2f4694;\n    font-size: 16px;\n}\n\n.certif-login-form-text-login {\n    font-weight: 600;\n    font-size: 25px;\n}\n\n.certif-add-footer {\n    margin-top: 15px;\n}\n\n.certif-add-footer button {\n    color: #60464675;\n}\n\n.certif-add-footer button:focus,\n.certif-add-footer button:hover {\n    color: #604646;\n}\n\n/* --- Logout Redirect css Styles for text: Start --- */\n.certif-logout-redirect-text-desc {\n    font-size: 2vw;\n}\n/* --- Logout Redirect css Styles for text: End --- */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
