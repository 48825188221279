import { useEffect, useState, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import CertifAlert from "../../../controls/CertifAlert";
import WelcomeImg from "./../../../assets/img/home_welcome.jpg";
import QuestionImg from "./../../../assets/img/icon-question.png";
import CloseImg from "./../../../assets/img/close.png";
import InfoImg from "./../../../assets/img/info.png";
import Utils from "../../../controls/Utils";
import AppContextComponent from "../../../controls/AppContext";

import i18n from "./../../../i18n/config";

function Home() {
  let { setPageInfo, setLoadingState } = useContext(AppContextComponent);
  let [errorMessage, setErrorMessage] = useState("");
  let [membershipNum, setMembershipNum] = useState("");
  let submitBtn = useRef(null);
  let errorDiv = useRef(null);
  let [isLinkClick, setIsLinkClick] = useState(false);
  let [infoDescContent, setInfoDescContent] = useState("");
  let [resData, setResData] = useState({});
  let [showModal, setShowModal] = useState(false);
  let [contGenInfoPage, setContGenInfoPage] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (errorMessage.length) {
      errorDiv.current.classList.remove("d-none");
    } else {
      errorDiv.current.classList.add("d-none");
    }
  }, [errorMessage]);

  const anyCallback = () => {
    setLoadingState({
      applyMask: false,
    });
  };

  const langChangeCallback = (newLang) => {
    setErrorMessage("");
  };

  useEffect(() => {
    i18n.on("languageChanged", langChangeCallback);
    return () => {
      i18n.off("languageChanged", langChangeCallback);
    };
  }, []);

  

  useEffect(() => {
    // Modal is close
    if (!showModal) {
      // Perform Utils.store only if showModal is false and it's not a link click.
      if (contGenInfoPage) {
        let newStorage = { ...resData.data };
        newStorage.path = Utils.PAGES[1];
        Utils.store(newStorage, setPageInfo);
      }
    }
    // anyCallback();
  }, [showModal]);

  const success = (res) => {
    if (res.status === 404 || res.data.status === "-1") {
      // invalid membership number
      let errMessage =
        res.data?.errorDesc.length > 0
          ? res.data.errorDesc
          : t("invalidMembershipNumber");
      setErrorMessage(errMessage);
    } else if (res.status === 200) {
      // valid membership number, store the information in storage.
      console.log(res.data);
      setResData(res);
      let infoCont =
        res.data?.infoDesc?.length > 0
          ? res.data.infoDesc
          : "";
      setInfoDescContent(infoCont);
      if (infoCont) {
        setContGenInfoPage(true);
        setShowModal(true);
      } else {
        let newStorage = { ...res.data };
        newStorage.path = Utils.PAGES[1];
        //debugger;
        Utils.store(newStorage, setPageInfo);
      }
    }
    anyCallback();
  };
  const fail = (err) => {
    console.log(err);
    err?.message?.length && console.log(err);
    if (err?.redirect) {
    }
    anyCallback();
  };

  const submitForm = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setResData({});
    setErrorMessage("");
    setContGenInfoPage(true);
    if (membershipNum.length) {
      setLoadingState({
        applyMask: true,
      });
      let formData = {
        locale: Utils.getLocale(),
        programNumber: membershipNum,
      };
      // submit the form
      try {
        Utils.validateMembershipNum(formData).then(success, fail);
      } catch (err) {
        console.log(err);
      }
    } else {
      // show local error
      setErrorMessage(t("emptyMembershipNumber"));
      return false;
    }
  };

  const inputHandler = (e) => {
    let num = e.currentTarget.value.trim();
    // console.log(num);
    setMembershipNum(num);
  };

  function LinkText({ href, children }) {
    return (
      <Link
        to={href || ""}
        style={{ textDecoration: "none", color: "#0072c6" }}
        target="_blank"
      >
        {children}
      </Link>
    );
  }

  const keyDownHandler = (e) => {
    if (e.keyCode === 13) {
      submitBtn.current.click();
    }
  };

  const handleOpen = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setContGenInfoPage(false);
    setIsLinkClick(true);
    setShowModal(true);
  };

  return (
    <>
      <CertifAlert
        props={{
          showModal,
          setShowModal,
          modalType: "info",
          bodyContent: isLinkClick ? `${t("dontKnowMemNumb")}` : infoDescContent,
          isLinkClick,
          setIsLinkClick,
        }}
      />
      <div className="certif-home-page-wrapper container">
        <div className="certif-home-page row">
          <div className="certif-home-page-first-row col col-md-8 col-sm-12">
            <div className="certif-home-page-welcome-img">
              <img src={WelcomeImg} alt="Welcome" className="img-fluid" />
            </div>
            <div>
              <h1 className="certif-intro-title">{t("welcomeMessage")}</h1>
              <p>
                <Trans
                  i18nKey="welcomeDesc"
                  t={t}
                  components={{
                    linkTag1: (
                      <LinkText href="https://www.internationalsos.com/MasterPortal/images/OtherAppImages/Certificate1.pdf" />
                    ),
                    linkTag2: (
                      <LinkText href="mailto:CertificatesofAssistance@internationalsos.com" />
                    ),
                  }}
                />{" "}
                {/* <span style={{ color: "#ffffff" }}>15AVTS000021</span> */}
              </p>
            </div>
          </div>
          <div className="certif-home-page-membership-form-wrapper col col-md-4 col-sm-12">
            <div className="certif-home-page-membership-form">
              <div className="certif-home-page-membership-form-label">
                {t("enterMessage")}{" "}
                <img
                  src={QuestionImg}
                  alt="help"
                  title={t("MembershipNumberImageicon")}
                />
              </div>
              <div className="certif-home-page-membership-form-input-text">
                <input
                  type="text"
                  onChange={inputHandler}
                  onKeyDown={keyDownHandler}
                  autoComplete="on"
                />
                <div
                  className="certif-home-page-membership-form-error certif-error d-none"
                  ref={errorDiv}
                >
                  {/* {t(`${errorKey}`)} */}
                  {errorMessage}
                </div>
              </div>
              <div className="certif-home-page-membership-form-submit-btn-wrapper">
                <button
                  className="certif-home-page-membership-form-submit-btn"
                  onClick={submitForm}
                  ref={submitBtn}
                >
                  {t("submitLabel")}
                </button>
              </div>
              <div className="certif-home-page-membership-form-help-link-wrapper">
                <a
                  href="http://cmsedge.com"
                  className="certif-home-page-membership-form-help-link"
                  onClick={handleOpen}
                >
                  {t("membershipQuestion")}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
