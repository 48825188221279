import { useState, useEffect, useContext } from "react";
import Home from "./Home";
import GeneralInformation from "./GeneralInformation";
import CertificateInformation from "./CertificateInformation";
import Confirmation from "./Confirmation";
import Validation from "./Validation";
import TermsAndConditionsPage from "./../../TermsAndConditionsPage";
import AppContextComponent from "../../../controls/AppContext";

function AnonLandingPage() {
  let { pageInfo } = useContext(AppContextComponent);

  let [pageName, setPageName] = useState(pageInfo?.path);

  let [marktContApiRes, setMarktContApiRes] = useState(null);

  useEffect(() => {
    setPageName("");
  }, []);

  useEffect(() => {
    setPageName(pageInfo?.path);
  }, [pageInfo?.path]);

  return (
    <div className="container">
      {(pageName === "" || pageName === "home") && <Home />}
      {pageName === "general_information" && <GeneralInformation />}
      {pageName === "certificate_information" && <CertificateInformation />}
      {pageName === "confirmation" && <Confirmation
        props={{
          setMarktContApiRes,
        }} />}
      {pageName === "validation" && <Validation
        props={{
          marktContApiRes,
        }} />}
      {/* {pageName === "terms_and_conditions" && <TermsAndConditionsPage />} */}
    </div>
  );
}

export default AnonLandingPage;
