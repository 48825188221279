import React, { useEffect, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import { useNavigate, useLocation } from "react-router";
import axios from "axios";

import { useOktaAuth } from "@okta/okta-react";

import { Container } from "react-bootstrap";
import Utils from "../../controls/Utils";
import AppContextComponent from "../../controls/AppContext";

const LoginForm = () => {
  let { setPageInfo } = useContext(AppContextComponent);

  const navigate = useNavigate();
  const location = useLocation();
  const { authState, oktaAuth, authClient } = useOktaAuth();

  const [searchParams] = useSearchParams();

  const code = searchParams.get("code");
  const state = searchParams.get("state");

  useEffect(() => {
    Utils.setStoreObj(0, setPageInfo);
    // console.log(authState);
    // console.log(oktaAuth);
    // console.log(authClient);
    // console.log(code);
    // console.log(state);
  }, []);

  useEffect(() => {
    // if (authState?.isAuthenticated) {
    //   console.log(" ========================= ");
    // } else {
    //   console.log(" ------------------------ ");
    // }

    let sessionObj = JSON.parse(
        sessionStorage.getItem("okta-transaction-storage"),
      ),
      issuer = sessionObj.issuer,
      clientId = sessionObj.clientId,
      codeChallenge = sessionObj.codeChallenge,
      codeVerifier = sessionObj.codeVerifier,
      nonce = sessionObj.nonce,
      redirectUri = sessionObj.redirectUri,
      tokenUrl = sessionObj.urls.tokenUrl;

    axios({
      method: "post",
      url: tokenUrl,
      data: {
        grant_type: "authorization_code",
        code,
        client_id: clientId,
        redirect_uri: redirectUri,
        state,
        codeVerifier,
        code_verifier: codeVerifier,
      },
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    }).then((res) => {
      let session = { ...res.data };
      // session.reload = true;
      // debugger;
      Utils.createOKTASession(session);
      // navigate(Utils.APP_URLS.LANDING_PAGE);
      window.location = Utils.APP_URLS.LANDING_PAGE;
    });
  }, [authState, oktaAuth]);

  return (
    <Container className="vh-100 vw-100 p-0 m-0 mw-100 mh-100 d-none">
      <div>Loading...</div>
      <div></div>
    </Container>
  );
};

export default LoginForm;
