import { useState, useEffect, useRef, useContext } from "react";
import { Modal } from "react-bootstrap";
import Utils from "../../../../controls/Utils";
import AppContextComponent from "../../../../controls/AppContext";
import CertifAlert from "../../../../controls/CertifAlert4AdminScreens";

function AddEditRegion({ props }) {
  let REQ_FIELDS = [
    "language", //
    "regionName", //
    "regionCode", //
  ];
  const fields4ErrorTemplate = {
    language: "Language",
    regionName: "Region Name",
    regionCode: "Region Code",
  };

  // console.log(props);
  let { setLoadingState, setPageInfo, setCanRedirectToLogin, setCanRedirectToError } = useContext(AppContextComponent);
  let [isSuperAdmin, setIsSuperAdmin] = useState(Utils.isSuperAdminAccess());

  let [canRender, setCanRender] = useState(true);
  let [pageMode, setPageMode] = useState(props.mode);
  let [errorFields, setErrorFields] = useState({});
  let [showModal, setShowModal] = useState(false);
  let [errorMessage, setErrorMessage] = useState('');
  let [modalErrorType, setModalErrorType] = useState('');
  let [pageModeStatus, setPageModeStatus] = useState(false);

  const anyCallback = () => {
    setLoadingState({
      applyMask: false,
    });
  };

  let formHeading = props.mode === "add" ? "Add region" : "Edit region";

  let defLanguage =
    props.mode === "add" ? "" : props?.regions?.[props.recordIndex]?.["language"] || "";

  let [language, setLanguage] = useState(defLanguage);

  let defCountryCode =
    props.mode === "add"
      ? props?.countryCode
      : props?.regions?.[props.recordIndex]?.["countryCode"] || "";

  let [countryCode, setCountryCode] = useState(defCountryCode);

  let defRegionCode =
    props.mode === "add" ? "" : props?.regions?.[props.recordIndex]?.["regionCode"] || "";

  let [regionCode, setRegionCode] = useState(defRegionCode);

  let defRegionName =
    props.mode === "add" ? "" : props?.regions?.[props.recordIndex]?.["regionName"] || "";

  let [regionName, setRegionName] = useState(defRegionName);

  let defRestricted =
    props.mode === "add" ? "" : props?.regions?.[props.recordIndex]?.["restricted"] || "";

  let [restricted, setRestricted] = useState(defRestricted);

  const executeCancel = (options = {}) => {
    props.setPageMode("list");
  };

  const submitRecord = () => {
    let mode = pageMode;
    setLoadingState({
      applyMask: true,
    });
    let tempRegions = [...props.regions];
    let record = {};

    props.fields.forEach((field, index) => {
      let fieldEle = document.getElementById(field);
      let fieldValue =
        fieldEle.type === "checkbox" ? fieldEle.checked : fieldEle.value;
      record[field] = fieldValue;
    });
    // console.log(record);

    const success = (res) => {
      // debugger;
      // console.log(tempRegions);
      // console.log(res);

      mode === "add" && tempRegions.push(res.data);
      mode === "edit" && (tempRegions[props.recordIndex] = res.data);

      props.setRegions(tempRegions);
      setPageModeStatus(true);
      let statusMessage = res.data !== [] ? Utils.USER_INTERACTION_MESSAGES_4_ADMIN_SCREENS.save : "Ok";
      mode === "add" && (statusMessage = Utils.USER_INTERACTION_MESSAGES_4_ADMIN_SCREENS.save);
      mode === "edit" && (statusMessage = Utils.USER_INTERACTION_MESSAGES_4_ADMIN_SCREENS.update);
      setErrorMessage(statusMessage);
      setLoadingState({
        applyMask: false,
      });
      setModalErrorType("info");
      setShowModal(true);

      // props.setPageMode("list");
    };

    const fail = ({ error }) => {

      console.log(error);
      // cost errCode = error.error.
      let errCode = error?.response?.status;
      if (errCode === 400) {
        const errorMessage = error.response ? error.response.data.message :
          Utils.USER_INTERACTION_MESSAGES_4_ADMIN_SCREENS.errorAlt;
        const updatedErrorMessage = errorMessage.slice(7);
        console.log(typeof (updatedErrorMessage));
        console.log(updatedErrorMessage);
        setErrorMessage(updatedErrorMessage);
        setPageModeStatus(false);
        setLoadingState({
          applyMask: false,
        });
        // Display the error modal
        setModalErrorType('error');
        // setShowErrorModal(true);
        setShowModal(true);
      } else {
        console.log(error);
        Utils.handleDefaultError({
          error,
          setLoadingState,
          setCanRedirectToLogin,
          setCanRedirectToError,
          setPageInfo,
        });
      }
    };
    // debugger;
    mode === "add" && Utils.addRegion(record).then(success, fail);
    mode === "edit" &&
      Utils.editRegion(
        record,
        props.regions[props.recordIndex].id,
      ).then(success, fail);
  };

  const validateForm = () => {
    let tempErrorFields = {};

    REQ_FIELDS.forEach((fieldId) => {
      let field = document.getElementById(fieldId);
      let fieldVal = '';
      if (field) {
        fieldVal =
          field.type === "checkbox" ? field.checked : field.value?.trim() ?? "";
        // fieldId === "marketingContent" && (fieldVal = marketingContent);
        if (fieldVal.length === 0) {
          if (!tempErrorFields[fieldId]) {
            // tempErrorFields[fieldId] = errorMessages[fieldId][0];
            tempErrorFields[fieldId] = Utils.ADMIN_SECTIONS_ERROR_TEMPLATE.replace('{}', fields4ErrorTemplate[fieldId]);
          }
        }
      }
    });
    setErrorFields(tempErrorFields);
    return tempErrorFields;

  }

  const executeSave = (options = {}) => {
    let detailsObj = validateForm();
    // console.log(detailsObj);
    if (Utils.isObjectEmpty(detailsObj)) {
      submitRecord();
    }
  };

  const executeUpdate = (options = {}) => {
    let detailsObj = validateForm();
    // console.log(detailsObj);
    if (Utils.isObjectEmpty(detailsObj)) {
      submitRecord();
    }
  };

  const onChangeLangDD = (e) => {
    // do nothing;
  };

  const initiateDelete = (options = {}) => {
    setShowAlert(true);
  };

  let [showAlert, setShowAlert] = useState(false);

  const handleClose = (e) => {
    setShowAlert(false);
  };

  const executeDelete = (e) => {
    setLoadingState({
      applyMask: true,
    });
    let tempRegions = [...props.regions];

    const delSuccess = (res) => {
      setPageModeStatus(false);
      tempRegions.splice(props.recordIndex, 1);
      const statusMessage = res.data !== [] ? Utils.USER_INTERACTION_MESSAGES_4_ADMIN_SCREENS.delete : "Ok";
      setErrorMessage(statusMessage);
      setModalErrorType('info');
      // setShowErrorModal(true);
      setShowModal(true);
      setPageModeStatus(true);
      props.setRegions(tempRegions);
      setLoadingState({
        applyMask: false,
      });
      setShowAlert(false);
      // props.setPageMode("list");
    };

    const delFail = ({ error }) => {
      console.log(error);
      Utils.handleDefaultError({
        error,
        setLoadingState,
        setCanRedirectToLogin,
        setCanRedirectToError,
        setPageInfo,
      });
    };

    let recordIdToBeDelete = props.regions[props.recordIndex].id;
    // console.log(recordIdToBeDelete);
    Utils.deleteRegion(recordIdToBeDelete).then(delSuccess, delFail);
  };

  return (
    <>
      <Modal centered show={showAlert} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete confirmation?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>Are you sure to delete this item?</div>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <div className="d-flex certif-delete-confirmation">
              <button onClick={handleClose} className="btn btn-primary btn-sm">
                No
              </button>
              <button
                onClick={executeDelete}
                className="btn btn-primary btn-sm"
              >
                Yes
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
      <CertifAlert
        props={{
          showModal,
          setShowModal,
          modalType: modalErrorType,
          bodyContent: errorMessage,
          backdrop: "static",
          keyboard: false,
          pageModeStatus,
          setPageModeProp: props.setPageMode,
        }}
      />
      {canRender && (
        <div>
          <div className="table-responsive">
            <table className="table table-borderless">
              <thead className="certif-table-header">
                <tr>
                  <th className="certif-table-header-label">{formHeading}</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody className="certif-table-body">
                <tr className="col-lg-12 col-md-12 col-sm-12">
                  <td className="col-lg-6 col-md-6 col-sm-6">
                    <label>
                      Language
                      <span className="certif-required-indicator">*</span>
                    </label>
                    <div>
                      <select
                        onChange={onChangeLangDD}
                        id={"language"}
                        defaultValue={language}
                      >
                        <option value="">Select</option>
                        {Object.keys(props.languagesObj)?.map(
                          (langKey, index) => (
                            <option value={langKey} key={index}>
                              {props.languagesObj[langKey]}
                            </option>
                          ),
                        )}
                      </select>
                      {errorFields.language && (<p className="error-message" style={{ color: "red" }}>
                        {errorFields.language}
                      </p>)}
                    </div>
                  </td>

                  <td className="col-lg-6 col-md-6 col-sm-6">
                    <label>
                      Region Code
                      <span className="certif-required-indicator">*</span>
                    </label>
                    <div>
                      <input
                        type="text"
                        defaultValue={regionCode}
                        id={"regionCode"}
                      />
                    </div>
                    {errorFields.regionCode && (<p className="error-message" style={{ color: "red" }}>
                      {errorFields.regionCode}
                    </p>)}
                  </td>

                  <td className="col-lg-6 col-md-6 col-sm-6 d-none">
                    <label>
                      Country Code
                      <span className="certif-required-indicator">*</span>
                    </label>
                    <div>
                      <input
                        type="hidden"
                        defaultValue={countryCode}
                        id={"countryCode"}
                      />
                    </div>
                  </td>
                </tr>
                <tr className="col-lg-12 col-md-12 col-sm-12">
                  <td className="col-lg-6 col-md-6 col-sm-6">
                    <label>
                      Region Name
                      <span className="certif-required-indicator">*</span>
                    </label>
                    <div>
                      <input
                        type="text"
                        defaultValue={regionName}
                        id={"regionName"}
                      />
                      {errorFields.regionName && (<p className="error-message" style={{ color: "red" }}>
                        {errorFields.regionName}
                      </p>)}
                    </div>
                  </td>

                  <td className="certif-admin-flex-check-td">
                    <div className="certif-flex-check-label-wrapper">
                      <input
                        id="restricted"
                        type="checkbox"
                        defaultChecked={restricted}
                        className="certif-input-field"
                      // onChange={emailBodyForOriginalHandler}
                      />
                      <label> Is Restricted?</label>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot className="certif-table-footer">
                <tr>
                  <th>
                    <div className="d-flex">
                      {isSuperAdmin && props.mode === "edit" && (
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={initiateDelete}
                        >
                          Delete
                        </button>
                      )}
                    </div>
                  </th>
                  <th></th>
                  <th>
                    <div className="d-flex">
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={executeCancel}
                      >
                        Cancel
                      </button>

                      {props.mode === "edit" && (
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={executeUpdate}
                        >
                          Update
                        </button>
                      )}

                      {props.mode === "add" && (
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={executeSave}
                        >
                          Save
                        </button>
                      )}
                    </div>
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      )}
    </>
  );
}

export default AddEditRegion;
