import { useState, useEffect, useRef, useContext } from "react";

import CountryLimitList from "./CountryLimitList";
import AddEditCountryLimit from "./AddEditCountryLimit";
import Utils from "../../../../controls/Utils";
import AppContextComponent from "../../../../controls/AppContext";
import NavEventMixin from "../../../utilpages/NavEventMixin";

function CountryLimitDialog({ props }) {
    let sellOffId = props.sellingOfficeRecord.sellingOfficeId;
    let [sellingOfficeId, setSellingOfficeId] = useState(sellOffId);
    let [countryCodes, setCountryCodes] = useState([]);
    let [countryCodesObj, setCountryCodesObj] = useState({});
    let [currencyCodes, setCurrencyCodes] = useState([]);
    let [currencyCodesObj, setCurrencyCodesObj] = useState({});
    let { setLoadingState, setPageInfo, setCanRedirectToLogin, setCanRedirectToError } = useContext(AppContextComponent);

    let [canRender, setCanRender] = useState(false);
    let [pageMode, setPageMode] = useState("list");
    let [countryLimits, setCountryLimits] = useState([]);
    let [fields, setFields] = useState([
        "sellingOfficeRegion",
        "countryCode",
        "amount",
        "currency",
        "sellingOfficeId",
    ]);
    let [recordIndex, setRecordIndex] = useState(-1);

    useEffect(() => {
        setLoadingState({
            applyMask: true,
        });
        const fail = ({ error }) => {
            console.log(error);
            Utils.handleDefaultError({
                error,
                setLoadingState,
                setCanRedirectToLogin,
                setCanRedirectToError,
                setPageInfo,
            });
        };
        const fetchSellOfficCntLimits = () => {
            const sellOfficCntLimitsSuccess = (cntLimits) => {
                // console.log("selling Office Countries Limits", cntLimits);
                setCountryLimits(cntLimits.data);
                setCanRender(true);
                setLoadingState({
                    applyMask: false,
                });
            };
            Utils.getSellOfficCntLimits(sellOffId).then(sellOfficCntLimitsSuccess, fail);
        };

        const fetchCurrencyLabels = () => {
            const currencyCodesSuccess = (res) => {
                res.data.sort((itemA, itemB) => {
                    return itemA.currencyCode.localeCompare(itemB.currencyCode);
                });
                let currencyCodes = res.data;
                // console.log("country codes -- ", cntCodes);
                setCurrencyCodes(currencyCodes);
                let currencyCodesObj = {};
                currencyCodes.forEach((currencyCode) => {
                    currencyCodesObj[currencyCode.currencyCode] = currencyCode.currencyCode;
                });
                setCurrencyCodesObj(currencyCodesObj);
                fetchSellOfficCntLimits();
            };
            Utils.fetchData("CURRENCY_LABELS").then(currencyCodesSuccess, fail);
        };


        const cntCodesSuccess = (res) => {
            res.data.sort((itemA, itemB) => {
                return itemA.countryCode.localeCompare(itemB.countryCode);
            });
            let cntCodes = res.data;
            // console.log("country codes -- ", cntCodes);
            setCountryCodes(cntCodes);
            let cntCodesObj = {};
            cntCodes.forEach((cntCode) => {
                cntCodesObj[cntCode.countryCode] = cntCode.countryCode;
            });
            setCountryCodesObj(cntCodesObj);
            fetchCurrencyLabels();
        };
        Utils.fetchData("DESTINATION_COUNTRY").then(cntCodesSuccess, fail);
    }, []);

    useEffect(() => { }, [fields, countryLimits, pageMode]);

    return (
        <>
            {canRender && (
                <div className="table-responsive">
                    {(pageMode === "add" || pageMode === "edit") && (
                        <NavEventMixin
                            props={{
                                pageMode,
                                setPageMode,
                            }}
                        />
                    )}
                    {pageMode === "list" && (
                        <CountryLimitList
                            props={{
                                fields,
                                countryLimits,
                                setPageMode,
                                recordIndex,
                                sellingOfficeId,
                                setRecordIndex,
                                countryCodesObj,
                                currencyCodesObj,
                            }}
                        />
                    )}
                    {pageMode === "add" && (
                        <AddEditCountryLimit
                            props={{
                                fields,
                                countryLimits,
                                sellingOfficeId,
                                setCountryLimits,
                                setPageMode,
                                mode: "add",
                                countryCodesObj,
                                currencyCodesObj,
                            }}
                        />
                    )}
                    {pageMode === "edit" && (
                        <AddEditCountryLimit
                            props={{
                                fields,
                                countryLimits,
                                setCountryLimits,
                                sellingOfficeId,
                                setPageMode,
                                recordIndex,
                                setRecordIndex,
                                mode: "edit",
                                countryCodesObj,
                                currencyCodesObj,
                            }}
                        />
                    )}
                </div>
            )}
        </>
    );
}

export default CountryLimitDialog;
