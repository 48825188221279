import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";

import CloseImg from "./../assets/img/close.png";
import InfoImg from "./../assets/img/info.png";
import ErrorImg from "./../assets/img/errorcross.png";

function CertifAlert4AdminScreens({ props }) {
  let [modalCanOpen, setModalCanOpen] = useState(false);
  let [modalType, setModalType] = useState("info");
  let [imgType, setImgType] = useState(InfoImg);
  let [bodyContent, setBodyContent] = useState('');
  let [keyboard, setKeyboard] = useState(true);
  let [backdropValue, setBackdropValue] = useState('');

  const handleClose = (e) => {
    setModalCanOpen(false);

    props.setShowModal(false);
    if (props.pageModeStatus) {
      props.setPageModeProp('list');
    }
  };

  useEffect(() => {
    if (props.showModal) {
      let type = props?.modalType ?? "info";
      setModalType(type);
      if (type === "info") {
        setImgType(InfoImg);
      } else {
        setImgType(ErrorImg);
      }
      let ModalKeyBoard = props?.keyboard ?? true;
      if (ModalKeyBoard) {
        setKeyboard(ModalKeyBoard);
      }
      let ModalBackdropValue = props?.backdrop ?? '';
      if (ModalBackdropValue) {
        setBackdropValue(ModalBackdropValue);
      }
      setModalCanOpen(true);
      setBodyContent(props?.bodyContent ?? '');
    }
  }, [props.showModal]);

  return (
    <>
      <Modal
        className={`certif-home-custom-modal certif-home-custom-modal-${modalType}`}
        centered
        backdrop={backdropValue}
        keyboard={keyboard}
        show={modalCanOpen}
        onHide={handleClose}
      >
        <Modal.Body>
          <div className="d-flex certif-home-modal-body">
            <div
              className="d-flex certif-home-modal-body-row-1"
              onClick={handleClose}
            >
              <img src={CloseImg} alt="Close" />
            </div>
            <br></br>
            <div className="d-flex certif-home-modal-body-row-2">
              <div className="certif-home-modal-body-row-21">
                <img src={imgType} alt="" />
              </div>
              <div className="certif-home-modal-body-row-22">
                {bodyContent}
                {/* <span>{bodyContent}</span> */}
              </div>
            </div>
            <br></br>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CertifAlert4AdminScreens;
