import { useState, useEffect, useContext } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";

import ContextComponent from "./../../controls/AppContext";
import Header from "./header/Header";
import Utils from "../../controls/Utils";
import Mask from "./../../controls/Mask";
import LandingBody from "./LandingBody";

function LandingPage() {
  let {
    pageInfo,
    setPageInfo,
    setLoadingState,
    readyToRender,
    setReadyToRender,
    setCanRedirectToLogin,
    setCanRedirectToError,
  } = useContext(ContextComponent);
  let navigate = useNavigate();

  let [canRender, setCanRender] = useState(false);

  useEffect(() => {
    setLoadingState({
      applyMask: true,
    });

    const commonFail = ({ error }) => {
      Utils.handleDefaultError({
        error,
        setLoadingState,
        setCanRedirectToLogin,
        setCanRedirectToError,
        setPageInfo,
      });
      setCanRender(true);
    };
    const messageCodeSuccess = (res) => {
      Utils.storeMessages(res.data, setPageInfo);
      setCanRender(true);
      setLoadingState({
        applyMask: false,
      });
    };
    // Check local storage here
    let session = Utils.getSession();
    const storedData = session.messages ?? {};
    if (!Utils.isObjectEmpty(storedData)) {
      setLoadingState({
        applyMask: false,
      });
      setCanRender(true);
    } else {
      Utils.fetchMessages().then(messageCodeSuccess, commonFail);
    }
  }, []);

  // console.log(pageInfo);

  let [canRenderBody, setCanRenderBody] = useState(false);

  useEffect(() => {
    if (readyToRender > 0) {
      setCanRenderBody(true);
    }
  }, [readyToRender]);

  return (
    <>
      {canRender && (
        <>
          <Mask />
          <div className="certif-domain container-fluid p-0">
            <Header />
            {canRenderBody && <LandingBody />}
          </div>
        </>
      )}
    </>
  );
}

export default LandingPage;
