import { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

import Utils from "../../controls/Utils";
import AppContextComponent from "../../controls/AppContext";

function AdminLeftNavigation() {
  const location = useLocation();
  const isAtRootUrl = location?.pathname === Utils.APP_URLS.ADMIN_PAGE;
  const navigationItemKeys = ["section1", "section2", "section3", "section4", "section5",
    "section6", "section7", "section8", "section10", "section11", "section12"];
  const ACTIVE_CLASSNAME = "certif-admin-active-nav-item";

  const navigationItemKeysValuesSort = [
    { "section1": "Legal Entity" },
    { "section2": "Labels" },
    { "section3": "Lookup" },
    { "section4": "Country" },
    { "section5": "Masters" },
    { "section6": "Messages" },
    { "section7": "Certificate Templates" },
    { "section8": "Users" },
    // { "section9": "Audit" },
    { "section10": "Selling Office" },
    { "section11": "Marketing Content" },
    { "section12": "Certificate Link Config" },
  ]

  navigationItemKeys.sort((a, b) => {
    const valueA = navigationItemKeysValuesSort.find(item => item[a]);
    const valueB = navigationItemKeysValuesSort.find(item => item[b]);

    return valueA && valueB ? valueA[a].localeCompare(valueB[b]) : 0;
  });

  const navLinkValues = {
    section1: {
      label: "Legal Entity",
      //   id: "section1",
      url: Utils.APP_URLS.ADMIN_PAGE_SECTION_1,
    },
    section2: {
      label: "Labels",
      //   id: "section2",
      url: Utils.APP_URLS.ADMIN_PAGE_SECTION_2,
    },
    section3: {
      label: "Lookup",
      //   id: "section3",
      url: Utils.APP_URLS.ADMIN_PAGE_SECTION_3,
    },
    section4: {
      label: "Country",
      //   id: "section1",
      url: Utils.APP_URLS.ADMIN_PAGE_SECTION_4,
    },
    section5: {
      label: "Masters",
      //   id: "section1",
      url: Utils.APP_URLS.ADMIN_PAGE_SECTION_5,
    },
    section6: {
      label: "Messages",
      //   id: "section1",
      url: Utils.APP_URLS.ADMIN_PAGE_SECTION_6,
    },
    section7: {
      label: "Certificate Templates",
      //   id: "section1",
      url: Utils.APP_URLS.ADMIN_PAGE_SECTION_7,
    },
    section8: {
      label: "Users",
      //   id: "section1",
      url: Utils.APP_URLS.ADMIN_PAGE_SECTION_8,
    },
    section9: {
      label: "Audit",
      //   id: "section1",
      url: Utils.APP_URLS.ADMIN_PAGE_SECTION_9,
    },
    section10: {
      label: "Selling Office",
      //   id: "section1",
      url: Utils.APP_URLS.ADMIN_PAGE_SECTION_10,
    },
    section11: {
      label: "Marketing Content",
      //   id: "section1",
      url: Utils.APP_URLS.ADMIN_PAGE_SECTION_11,
    },
    section12: {
      label: "Certificate Link Config",
      //   id: "section1",
      url: Utils.APP_URLS.ADMIN_PAGE_SECTION_12,
    },
  };

  return (
    <div className="certif-admin-left-nav-wrapper d-flex">
      {navigationItemKeys.map((navKey, index, src) => (
        <div
          className={`certif-admin-nav-items-wrapper certif-admin-nav-item-${navKey}`}
          key={index}
        >
          <NavLink
            className={({ isActive }) => {
              if (isAtRootUrl) {
                isActive = index === 0;
              }
              let clsName = isActive ? ACTIVE_CLASSNAME : "";
              return clsName;
            }}
            to={navLinkValues[navKey].url}
            id={navKey}
          // onClick={handleNavItemClick}
          >
            {({ isActive }) => (
              <>
                <div className="certif-admin-nav-items">
                  <div className="certif-admin-nav-item">
                    {navLinkValues[navKey].label}
                  </div>
                  {/* <div className="certif-arrow-up certif-set-buttom"></div> */}
                </div>
              </>
            )}
          </NavLink>
        </div>
      ))}
    </div>
  );
}

export default AdminLeftNavigation;
