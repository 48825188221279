import { useState, useEffect, useContext } from "react";
import { useLocation, NavLink } from "react-router-dom";
import { useNavigate } from "react-router";

import Utils from "../../../controls/Utils";
import AdminSection6Body from "./AdminSection6Body";

function AdminSection6() {
    const location = useLocation();
    const ACTIVE_CLASSNAME = "active";

    const isAtRootUrl =
        location?.pathname === Utils.APP_URLS.ADMIN_PAGE_SECTION_6 ||
        location?.pathname === Utils.APP_URLS.MESSAGE;

    let [canRender, setCanRender] = useState(false);

    useEffect(() => {
        setCanRender(true);
    }, []);


    const navigationItemKeys = [
        "messageManagement",
    ];

    const navLinkValues = {
        messageManagement: {
            label: "Message Management",
            id: "Message_Management",
            url: Utils.APP_URLS.MESSAGE,
        },
    };

    return (
        <>
            {canRender && (
                <div className="container certif-admin-container">
                    <div className="d-flex certif-admin-section-wrapper">
                        {navigationItemKeys.map((navKey, index, src) => (
                            <div
                                className={`certif-admin-section-item certif-admin-section-item-${navKey}`}
                                key={index}
                            >
                                <NavLink
                                    className={({ isActive }) => {
                                        if (isAtRootUrl) {
                                            isActive = index === 0;
                                        }
                                        return isActive ? ACTIVE_CLASSNAME : "";
                                    }}
                                    to={navLinkValues[navKey].url}
                                    id={navKey}
                                >
                                    {({ isActive }) => (
                                        <>
                                            <div className="certif-admin-section-nav-items">
                                                <div className="certif-admin-section-nav-item">
                                                    <div>{navLinkValues[navKey].label}</div>
                                                    <div className="certif-arrow-up certif-set-buttom"></div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </NavLink>
                            </div>
                        ))}
                    </div>

                    <div className="certif-admin-section-container-body">
                        <AdminSection6Body />
                    </div>
                </div>

            )}
        </>
    );
}

export default AdminSection6;
