import { useState, useEffect, useRef, useContext } from "react";
import { Table } from "react-bootstrap";

import AppContextComponent from "../../../../controls/AppContext";

import Utils from "./../../../../controls/Utils";

import ARROW_UP_IMG from "./../../../../assets/img/arrow.up.svg";
import FILLED_ARROW_UP_IMG from "./../../../../assets/img/arrow.up.fill.svg";
import ARROW_DOWN_IMG from "./../../../../assets/img/arrow.down.svg";
import FILLED_ARROW_DOWN_IMG from "./../../../../assets/img/arrow.down.fill.svg";


function DetailsList({ props }) {
    // console.log(props);
    let msgCode = props.messageCode;
    let [messageCode, setMessageCode] = useState(msgCode);

    let [canRender, setCanRender] = useState(true);

    let [fields, setFields] = useState(props.fields ?? []);
    let [templates, setTemplates] = useState([]);
    let [filteredTemplates, setFilteredTemplates] = useState([]);
    const SORTABLE_FIELDS = [
        "language",
        "message",
    ];

    useEffect(() => {
        setFields(props.fields ?? []);
        setTemplates(props.details ?? []);
        setFilteredTemplates(props.details ?? []);
    }, [props.fields, props.details]);

    const gotoAddPage = (e) => {
        props.setPageMode("add");
    };

    const gotoEditPage = (e) => {
        const dataId = e.target.getAttribute('data-id');
        // console.log(dataId);
        // console.log(typeof (dataId));

        const index = templates.findIndex((template) => template.id === Number(dataId));
        if (index !== -1) {
            props.setRecordIndex(index);
            props.setPageMode("edit");
        }
    };

    const filterTable = (e) => {
        Utils.filterTableData({
            event: e,
            templates,
            setTemplates,
            filteredTemplates,
            setFilteredTemplates,
        });
    };

    const sortTable = (e) => {
        Utils.sortTable({
            event: e,
            templates,
            setTemplates,
            filteredTemplates,
            setFilteredTemplates,
        });
    };

    return (
        <>
            {canRender && (
                <>
                    <div className="certif-right-search-wrapper">
                        <input
                            type="text"
                            // placeholder={`Search Message Code ${msgCode}`}
                            placeholder={Utils.PLACEHOLDER_4_SEARCH_IN_ADMIN_SCREENS}
                            onChange={filterTable}
                            sort-type=""
                            sort-key=""
                            id="certif-details-filter-id"
                        />
                    </div>
                    <div className="table-responsive">
                        <div className="certif-bar-wrapper">
                            <div className="certif-bar-left">Details - {messageCode}</div>
                            <div className="certif-bar-right" onClick={gotoAddPage}>
                                +
                            </div>
                        </div>

                        <div className="d-flex certif-table-with-scrolls">
                            <Table stripped="" className="m-0" bordered hover size="md">
                                <thead>
                                    <tr>
                                        <th
                                            width=""
                                            id="language_th"
                                            className="certif-table-sort-col-wrapper"
                                        >
                                            <span className="certif-table-sort-col-name">
                                                Language
                                            </span>
                                            <span className="certif-table-sort-wrapper">
                                                <span
                                                    className="certif-table-sort-up"
                                                    sort-col-id="language"
                                                    sort-col-interactive-id="certif-details-filter-id"
                                                    sort-col-type="asc"
                                                    onClick={sortTable}
                                                ></span>
                                                <span
                                                    className="certif-table-sort-down"
                                                    sort-col-id="language"
                                                    sort-col-interactive-id="certif-details-filter-id"
                                                    sort-col-type="desc"
                                                    onClick={sortTable}
                                                ></span>
                                            </span>
                                        </th>
                                        <th
                                            width=""
                                            id="message_th"
                                            className="certif-table-sort-col-wrapper"
                                        >
                                            <span className="certif-table-sort-col-name">
                                                Message
                                            </span>
                                            <span className="certif-table-sort-wrapper">
                                                <span
                                                    className="certif-table-sort-up"
                                                    sort-col-id="message"
                                                    sort-col-interactive-id="certif-details-filter-id"
                                                    sort-col-type="asc"
                                                    onClick={sortTable}
                                                ></span>
                                                <span
                                                    className="certif-table-sort-down"
                                                    sort-col-id="message"
                                                    sort-col-interactive-id="certif-details-filter-id"
                                                    sort-col-type="desc"
                                                    onClick={sortTable}
                                                ></span>
                                            </span>
                                        </th>
                                        {/* <th width="">language</th> */}
                                        {/* <th width="">message</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredTemplates.map((template, templateIndex) => (
                                        <tr
                                            key={templateIndex}
                                            className="certif-admin-template-item-row"
                                        >
                                            {fields.map(
                                                (field, fieldIndex) =>
                                                    fieldIndex < 2 && (
                                                        <td
                                                            id={templateIndex}
                                                            key={fieldIndex}
                                                            data-id={template.id}
                                                            onClick={gotoEditPage}
                                                        >
                                                            {/* {field === "countryCode" && <>{template[field]}</>} */}
                                                            {field === "language" && (
                                                                <>{props.languagesObj[template[field]]}</>
                                                            )}
                                                            {field === "message" && <>{template[field]}</>}
                                                        </td>
                                                    ),
                                            )}
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

export default DetailsList;
