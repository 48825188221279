import { useState, useEffect, useContext } from "react";
import { Routes, Route } from "react-router-dom";


import Utils from "../../../controls/Utils";
import LookupPage from "../Lookup/LookupPage";
import ErrorPage from "../../utilpages/ErrorPage";

function AdminSection3Body() {
    let [canRender, setCanRender] = useState(false);

    useEffect(() => {
        setCanRender(true);
    }, []);

    let lookupMangPath = Utils.APP_URLS.LOOKUP_MANAGEMENT.replace(
        Utils.APP_URLS.ADMIN_PAGE_SECTION_3,
        "",
    );

    return (
        <>
            {canRender && (
                <Routes>
                    <Route path={`/`} exact element={<LookupPage />}></Route>
                    <Route
                        path={lookupMangPath}
                        element={<LookupPage />}
                    ></Route>
                    <Route path={`*`} element={<ErrorPage props={{error: 404}}/>}></Route>

                    {/* <Route
                        path={Utils.APP_URLS.LABELS_CONTENT.replace(
                            Utils.APP_URLS.ADMIN_PAGE,
                            "",
                        )}
                        element={<AdminMarketingContentPage />}
                    ></Route>
                    <Route
                        path={Utils.APP_URLS.LABELS_MANAGEMENT.replace(
                            Utils.APP_URLS.ADMIN_PAGE,
                            "",
                        )}
                        element={<AdminUserManagementPage />}
                    ></Route> */}
                </Routes>
            )}
        </>
    );
}

export default AdminSection3Body;
