const CLIENT_ID = "0oab4ukm96spwbKfF0i7";
const ISSUER_BASE_URL = "https://intlsos.okta-emea.com";
const ISSUER = ISSUER_BASE_URL + "/oauth2/default";
const ISSUER_USER_SETTINGS = ISSUER_BASE_URL + "/enduser/settings";
const OKTA_TESTING_DISABLEHTTPSCHECK =
  process.env.OKTA_TESTING_DISABLEHTTPSCHECK || false;
const REDIRECT_URI = `https://certificates.internationalsos.com/internal/callback`;

// eslint-disable-next-line
export default {
  oidc: {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    issuerBaseUrl: ISSUER_BASE_URL,
    issuerUserSettings: ISSUER_USER_SETTINGS,
    redirectUri: REDIRECT_URI,
    scopes: ["openid", "profile", "email", "cards"],
    responseType: ["token", "id_token", "code"],
    pkce: true,
    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
  },
  resourceServer: {
    messagesUrl: "http://localhost:3000/api/messages",
  },
  REST_API_CTX: {
    PROTOCOL: "https://",
    HOST: "certificates.internationalsos.com",
    PORT: "",
    API: "/api",
    EXT: "/external",
    LOOKUP: "/lookup",
  },
};
