import { useState, useEffect, useRef, useContext } from "react";

import DetailsList from "./DetailsList";
import AddEditDetails from "./AddEditDetails";
import Utils from "../../../../controls/Utils";
import AppContextComponent from "../../../../controls/AppContext";
import NavEventMixin from "../../../utilpages/NavEventMixin";

function DetailsDialog({ props }) {
    let ctryCode = props.countryRecord.countryCode;
    let [countryCode, setCountryCode] = useState(ctryCode);
    let [languages, setLanguages] = useState([]);
    let [languagesObj, setLanguagesObj] = useState({});
    let { setLoadingState, setPageInfo, setCanRedirectToLogin, setCanRedirectToError } = useContext(AppContextComponent);

    let [canRender, setCanRender] = useState(false);
    let [pageMode, setPageMode] = useState("list");
    let [details, setDetails] = useState([]);
    let [fields, setFields] = useState([
        "countryName",
        "language",
        "preposition",
        "countryCode",
    ]);
    let [recordIndex, setRecordIndex] = useState(-1);

    useEffect(() => {
        setLoadingState({
            applyMask: true,
        });
        const fail = ({ error }) => {
            console.log(error);
            Utils.handleDefaultError({
                error,
                setLoadingState,
                setCanRedirectToLogin,
                setCanRedirectToError,
                setPageInfo,
            });
        };
        const fetchDetails = () => {
            const detailsSuccess = (detls) => {
                setDetails(detls.data);
                setCanRender(true);
                setLoadingState({
                    applyMask: false,
                });
            };
            Utils.getCountryDetails(ctryCode).then(detailsSuccess, fail);
        };
        const langSuccess = (langs) => {
            setLanguages(langs);
            let langObj = {};
            langs.forEach((lang) => {
                langObj[lang.languageCode] = lang.languageText;
            });
            setLanguagesObj(langObj);
            fetchDetails();
        };
        Utils.getStoredLanguages().then(langSuccess, fail);
    }, []);

    useEffect(() => { }, [fields, details, pageMode]);

    return (
        <>
            {canRender && (
                <div className="table-responsive">
                    {(pageMode === "add" || pageMode === "edit") && (
                        <NavEventMixin
                            props={{
                                pageMode,
                                setPageMode,
                            }}
                        />
                    )}
                    {pageMode === "list" && (
                        <DetailsList
                            props={{
                                fields,
                                details,
                                setPageMode,
                                recordIndex,
                                countryCode,
                                setRecordIndex,
                                languagesObj,
                            }}
                        />
                    )}
                    {pageMode === "add" && (
                        <AddEditDetails
                            props={{
                                fields,
                                details,
                                countryCode,
                                setDetails,
                                setPageMode,
                                mode: "add",
                                languagesObj,
                            }}
                        />
                    )}
                    {pageMode === "edit" && (
                        <AddEditDetails
                            props={{
                                fields,
                                details,
                                setDetails,
                                countryCode,
                                setPageMode,
                                recordIndex,
                                setRecordIndex,
                                mode: "edit",
                                languagesObj,
                            }}
                        />
                    )}
                </div>
            )}
        </>
    );
}

export default DetailsDialog;
