import { useState, useEffect, useRef, useContext } from "react";

import Utils from "../../../controls/Utils";
import AppContextComponent from "../../../controls/AppContext";
import NavEventMixin from "../../utilpages/NavEventMixin";

import UserManagementList from "./UserManagementList";
import AddEditUserManagement from "./AddEditUserManagement";

function UserManagementPage() {

    let { setPageInfo, setLoadingState, setCanRedirectToLogin, setCanRedirectToError } = useContext(AppContextComponent);

    let [canRender, setCanRender] = useState(false);
    let [pageMode, setPageMode] = useState("list");
    let [templates, setTemplates] = useState([]);
    let [userRoles, setUserRoles] = useState([]);

    let [fields, setFields] = useState([
        "userName",
        "email",
        "password",
        "roles",
        "firstName",
        "lastName",
    ]);
    let [recordIndex, setRecordIndex] = useState(-1);

    let tempNoOfRecords = 7;

    useEffect(() => {
        setLoadingState({
            applyMask: true,
        });
        const fetchSuccess = (res) => {
            let tempTemplates = [...res.data];
            // console.log(tempTemplates);
            setTemplates(tempTemplates);
            // console.log(templates);
            setCanRender(true);
            setLoadingState({
                applyMask: false,
            });
        };

        const fetchFail = ({ error }) => {
            Utils.handleDefaultError({
                error,
                setLoadingState,
                setCanRedirectToLogin,
                setCanRedirectToError,
                setPageInfo,
            });
        };

        Utils.fetchData("USER_MANAGEMENT").then(fetchSuccess, fetchFail);
    }, []);

    useEffect(() => { }, [fields, templates, pageMode, canRender]);

    return (
        <>
            {canRender && (
                <div className="table-responsive">
                    {(pageMode === "add" || pageMode === "edit") && (
                        <NavEventMixin
                            props={{
                                pageMode,
                                setPageMode,
                            }}
                        />
                    )}
                    {pageMode === "list" && (
                        <UserManagementList
                            props={{
                                fields,
                                templates,
                                setPageMode,
                                recordIndex,
                                setRecordIndex,
                            }}
                        />
                    )}
                    {pageMode === "add" && (
                        <AddEditUserManagement
                            props={{
                                fields,
                                templates,
                                setTemplates,
                                setPageMode,
                                mode: "add",
                                tempNoOfRecords,
                            }}
                        />
                    )}
                    {pageMode === "edit" && (
                        <AddEditUserManagement
                            props={{
                                fields,
                                templates,
                                setTemplates,
                                setPageMode,
                                recordIndex,
                                tempNoOfRecords,
                                setRecordIndex,
                                mode: "edit",
                            }}
                        />
                    )}
                </div>
            )}
        </>
    );
}

export default UserManagementPage;
