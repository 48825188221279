import { useState, useEffect, useContext } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { useNavigate } from "react-router";

import AppContextComponent from "./../../controls/AppContext";
import AdminSection1 from "./Sections/AdminSection1";
import AdminSection2 from "./Sections/AdminSection2";
import AdminSection3 from "./Sections/AdminSection3";
import AdminSection4 from "./Sections/AdminSection4";
import AdminSection5 from "./Sections/AdminSection5";
import AdminSection6 from "./Sections/AdminSection6";
import AdminSection7 from "./Sections/AdminSection7";
import AdminSection8 from "./Sections/AdminSection8";
import AdminSection9 from "./Sections/AdminSection9";
import AdminSection10 from "./Sections/AdminSection10";
import AdminSection11 from "./Sections/AdminSection11";
import AdminSection12 from "./Sections/AdminSection12";

import Utils from "../../controls/Utils";

function AdminBody() {
  const location = useLocation();
  const navigate = useNavigate();
  let { pageInfo, setPageInfo } = useContext(AppContextComponent);

  let [canRender, setCanRender] = useState(true);
  let [pageName, setPageName] = useState(pageInfo?.path);

  let postPath = "/*";

  return (
    <>
      {canRender && (
        <div className="certif-admin-container-body">
          <Routes>
            <Route path={`/*`} exact element={<AdminSection12 />}></Route>
            <Route
              path={
                Utils.APP_URLS.ADMIN_PAGE_SECTION_1.replace(
                  Utils.APP_URLS.ADMIN_PAGE,
                  "",
                ) + postPath
              }
              element={<AdminSection1 />}
            ></Route>
            <Route
              path={
                Utils.APP_URLS.ADMIN_PAGE_SECTION_2.replace(
                  Utils.APP_URLS.ADMIN_PAGE,
                  "",
                ) + postPath
              }
              element={<AdminSection2 />}
            ></Route>
            <Route
              path={
                Utils.APP_URLS.ADMIN_PAGE_SECTION_3.replace(
                  Utils.APP_URLS.ADMIN_PAGE,
                  "",
                ) + postPath
              }
              element={<AdminSection3 />}
            ></Route>
            <Route
              path={
                Utils.APP_URLS.ADMIN_PAGE_SECTION_4.replace(
                  Utils.APP_URLS.ADMIN_PAGE,
                  "",
                ) + postPath
              }
              element={<AdminSection4 />}
            ></Route>
            <Route
              path={
                Utils.APP_URLS.ADMIN_PAGE_SECTION_5.replace(
                  Utils.APP_URLS.ADMIN_PAGE,
                  "",
                ) + postPath
              }
              element={<AdminSection5 />}
            ></Route>
            <Route
              path={
                Utils.APP_URLS.ADMIN_PAGE_SECTION_6.replace(
                  Utils.APP_URLS.ADMIN_PAGE,
                  "",
                ) + postPath
              }
              element={<AdminSection6 />}
            ></Route>
            <Route
              path={
                Utils.APP_URLS.ADMIN_PAGE_SECTION_7.replace(
                  Utils.APP_URLS.ADMIN_PAGE,
                  "",
                ) + postPath
              }
              element={<AdminSection7 />}
            ></Route>
            <Route
              path={
                Utils.APP_URLS.ADMIN_PAGE_SECTION_8.replace(
                  Utils.APP_URLS.ADMIN_PAGE,
                  "",
                ) + postPath
              }
              element={<AdminSection8 />}
            ></Route>
            <Route
              path={
                Utils.APP_URLS.ADMIN_PAGE_SECTION_9.replace(
                  Utils.APP_URLS.ADMIN_PAGE,
                  "",
                ) + postPath
              }
              element={<AdminSection9 />}
            ></Route>
            <Route
              path={
                Utils.APP_URLS.ADMIN_PAGE_SECTION_10.replace(
                  Utils.APP_URLS.ADMIN_PAGE,
                  "",
                ) + postPath
              }
              element={<AdminSection10 />}
            ></Route>
            <Route
              path={
                Utils.APP_URLS.ADMIN_PAGE_SECTION_11.replace(
                  Utils.APP_URLS.ADMIN_PAGE,
                  "",
                ) + postPath
              }
              element={<AdminSection11 />}
            ></Route>
            <Route
              path={
                Utils.APP_URLS.ADMIN_PAGE_SECTION_12.replace(
                  Utils.APP_URLS.ADMIN_PAGE,
                  "",
                ) + postPath
              }
              element={<AdminSection12 />}
            ></Route>
          </Routes>
        </div>
      )}
    </>
  );
}

export default AdminBody;
