import { useState, useEffect, useRef, useContext } from "react";
import { Table } from "react-bootstrap";

import AppContextComponent from "../../../../controls/AppContext";
import Utils from "./../../../../controls/Utils";

function MarketingContentList({ props }) {
    // console.log("Props in CountryLimitList", props);
    let sellOffId = props.sellingOfficeId;
    let [sellingOfficeId, setSellingOfficeId] = useState(sellOffId);

    let [canRender, setCanRender] = useState(true);

    let [fields, setFields] = useState(props.fields ?? []);
    let [marktContents, setMarktContents] = useState(props.marktContents ?? []);

    const gotoAddPage = (e) => {
        props.setPageMode("add");
    };

    const gotoEditPage = (e) => {
        props.setRecordIndex(e.currentTarget.id);
        props.setPageMode("edit");
    };

    return (
        <>
            {canRender && (
                <>
                    <div className="table-responsive">
                        <div className="certif-bar-wrapper">
                            <div className="certif-bar-left">Selling Office Id - {sellingOfficeId}</div>
                            <div className="certif-bar-right" onClick={gotoAddPage}>
                                +
                            </div>
                        </div>

                        <div className="d-flex certif-table-with-scrolls">
                            <Table stripped="" className="m-0" bordered hover size="md">
                                <thead>
                                    <tr>
                                        <th width="">Language</th>
                                        <th width="">Marketing Content</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {marktContents.map((template, templateIndex) => (
                                        <tr
                                            key={templateIndex}
                                            className="certif-admin-template-item-row"
                                        >
                                            {fields.map(
                                                (field, fieldIndex) =>
                                                    fieldIndex < 2 && (
                                                        <td
                                                            id={templateIndex}
                                                            key={fieldIndex}
                                                            onClick={gotoEditPage}
                                                        >
                                                            {field === "language" && (
                                                                <>{props.languagesObj[template[field]]}</>
                                                            )}
                                                            {field === "marketingContent" && <>{template[field]}</>}
                                                        </td>
                                                    ),
                                            )}
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

export default MarketingContentList;
