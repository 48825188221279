import { useState, useEffect, useContext } from "react";
import { Routes, Route } from "react-router-dom";

import Utils from "../../../controls/Utils";
import LabelsTemplate from "./../Labels/LabelsTemplate";
import LabelsContent from "./../Labels/LabelsContent";
import LabelsManagement from "./../Labels/LabelsManagement";
import ErrorPage from "../../utilpages/ErrorPage";

function AdminSection1Body() {
  let [canRender, setCanRender] = useState(false);

  useEffect(() => {
    setCanRender(true);
  }, []);

  let templatePath = Utils.APP_URLS.LABELS_TEMPLATE.replace(
    Utils.APP_URLS.ADMIN_PAGE_SECTION_2,
    "",
  );

  let contentPath = Utils.APP_URLS.LABELS_CONTENT.replace(
    Utils.APP_URLS.ADMIN_PAGE_SECTION_2,
    "",
  );
  let managementPath = Utils.APP_URLS.LABELS_MANAGEMENT.replace(
    Utils.APP_URLS.ADMIN_PAGE_SECTION_2,
    "",
  );

  return (
    <>
      {canRender && (
        <Routes>
          <Route path={`/`} exact element={<LabelsManagement />}></Route>

          <Route path={managementPath} element={<LabelsManagement />}></Route>

          <Route path={templatePath} element={<LabelsTemplate />}></Route>

          <Route path={contentPath} element={<LabelsContent />}></Route>
          <Route path={`*`} element={<ErrorPage props={{error: 404}}/>}></Route>
        </Routes>
      )}
    </>
  );
}

export default AdminSection1Body;
