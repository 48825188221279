import { useState, useEffect, useContext } from "react";
import { Routes, Route } from "react-router-dom";

import Utils from "../../../controls/Utils";
import CertificateLinkConfigPage from "../CertifLinkConfig/CertificateLinkConfigPage";

import ErrorPage from "../../utilpages/ErrorPage";

function AdminSection12Body() {
    let [canRender, setCanRender] = useState(false);

    useEffect(() => {
        setCanRender(true);
    }, []);

    return (
        <>
            {canRender && (
                <Routes>
                    <Route path={`/`} exact element={<CertificateLinkConfigPage />}></Route>
                    <Route
                        path={Utils.APP_URLS.CERTIFICATE_LINK_CONFIG.replace(
                            Utils.APP_URLS.ADMIN_PAGE_SECTION_12,
                            "",
                        )}
                        element={<CertificateLinkConfigPage />}
                    ></Route>
                    <Route
                        path={`*`}
                        element={<ErrorPage props={{ error: 404 }} />}
                    ></Route>
                </Routes>
            )}
        </>
    );
}

export default AdminSection12Body;
