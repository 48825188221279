import { useState, useEffect, useRef, useContext } from "react";
import { Table, Modal } from "react-bootstrap";

import Utils from "../../../controls/Utils";

import ARROW_UP_IMG from "./../../../assets/img/arrow.up.svg";
import FILLED_ARROW_UP_IMG from "./../../../assets/img/arrow.up.fill.svg";
import ARROW_DOWN_IMG from "./../../../assets/img/arrow.down.svg";
import FILLED_ARROW_DOWN_IMG from "./../../../assets/img/arrow.down.fill.svg";

import RegionsDialog from "./Regions/RegionsDialog";
import DetailsDialog from "./Details/DetailsDialog";

import EditImg from "./../../../assets/img/icon-modify.png";

function CountryList({ props }) {
  const submitHandler = (e) => { };

  let [fields, setFields] = useState([]);
  let [templates, setTemplates] = useState([]);
  let [filteredTemplates, setFilteredTemplates] = useState([]);
  const SORTABLE_FIELDS = [
    "countryCode",
    "amount",
    "currency",
  ];

  useEffect(() => {
    setFields(props.fields);
    setTemplates(props.templates);
    setFilteredTemplates(props.templates);
  }, [props.fields, props.templates]);

  const gotoAddPage = (e) => {
    props.setPageMode("add");
  };

  let [addModalCanOpen, setAddModalCanOpen] = useState(false);
  let [showRegionsTable, setShowRegionsTable] = useState(false);
  let [showDetailsTable, setShowDetailsTable] = useState(false);
  let [modalTitle, setModalTitle] = useState("");
  let [countryRecord, setCountryRecord] = useState({});

  const gotoEditPage = (e) => {
    let dataKey = e.currentTarget.getAttribute("data-key");
    console.log(dataKey);
    if (dataKey === "region") {
      let currentRecord = filteredTemplates[parseInt(e.currentTarget.id, 10)];
      // console.log(currentRecord);
      setCountryRecord(currentRecord);
      setModalTitle("Country code - " + currentRecord.countryCode);
      setShowRegionsTable(true);
      setAddModalCanOpen(true);
    } else if (dataKey === "details") {
      let currentRecord = filteredTemplates[parseInt(e.currentTarget.id, 10)];
      // console.log(currentRecord);
      setCountryRecord(currentRecord);
      setModalTitle("Country code - " + currentRecord.countryCode);
      setShowDetailsTable(true);
      // debugger;
      setAddModalCanOpen(true);
    } else {
      const dataId = e.target.getAttribute('data-id');
      const index = templates.findIndex((template) => template.id === Number(dataId));
      if (index !== -1) {
        props.setRecordIndex(index);
        props.setPageMode("edit");
      }
    }
  };

  const handleClose = (e) => {
    if (showRegionsTable) {
      setShowRegionsTable(false);
    } else if (showDetailsTable) {
      setShowDetailsTable(false);
    }
    setAddModalCanOpen(false);
  };

  const filterTable = (e) => {
    Utils.filterTableData({
      event: e,
      templates,
      setTemplates,
      filteredTemplates,
      setFilteredTemplates,
    });
  };

  const sortTable = (e) => {
    Utils.sortTable({
      event: e,
      templates,
      setTemplates,
      filteredTemplates,
      setFilteredTemplates,
    });
  };

  return (
    <>
      <Modal centered show={addModalCanOpen} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showRegionsTable && <RegionsDialog props={{ countryRecord }} />}
          {showDetailsTable && <DetailsDialog props={{ countryRecord }} />}
        </Modal.Body>
      </Modal>
      <div className="certif-right-search-wrapper">
        <input
          type="text"
          placeholder={Utils.PLACEHOLDER_4_SEARCH_IN_ADMIN_SCREENS}
          onChange={filterTable}
          sort-type=""
          sort-key=""
          id="certif-country-filter-id"
        />
      </div>
      <div className="table-responsive certif-table-responsive-with-search">
        <div className="certif-bar-wrapper">
          <div className="certif-bar-left">Country Management</div>
          <div className="certif-bar-right" onClick={gotoAddPage}>
            +
          </div>
        </div>

        <div className="d-flex certif-table-with-scrolls certif-admin-sticky-column">
          <Table stripped="" className="m-0" bordered hover size="md">
            <thead>
              <tr>
                <th
                  width=""
                  id="countryCode_th"
                  className="certif-table-sort-col-wrapper"
                >
                  <span className="certif-table-sort-col-name">
                    Country Code
                  </span>
                  <span className="certif-table-sort-wrapper">
                    <span
                      className="certif-table-sort-up"
                      sort-col-id="countryCode"
                      sort-col-interactive-id="certif-country-filter-id"
                      sort-col-type="asc"
                      onClick={sortTable}
                    ></span>
                    <span
                      className="certif-table-sort-down"
                      sort-col-id="countryCode"
                      sort-col-interactive-id="certif-country-filter-id"
                      sort-col-type="desc"
                      onClick={sortTable}
                    ></span>
                  </span>
                </th>
                {/* <th width="">Country code</th> */}
                <th width="">Issue Certificate</th>
                <th width="">Request Original</th>
                <th
                  width=""
                  id="amount_th"
                  className="certif-table-sort-col-wrapper"
                >
                  <span className="certif-table-sort-col-name">
                    Amount
                  </span>
                  <span className="certif-table-sort-wrapper">
                    <span
                      className="certif-table-sort-up"
                      sort-col-id="amount"
                      sort-col-interactive-id="certif-country-filter-id"
                      sort-col-type="asc"
                      onClick={sortTable}
                    ></span>
                    <span
                      className="certif-table-sort-down"
                      sort-col-id="amount"
                      sort-col-interactive-id="certif-country-filter-id"
                      sort-col-type="desc"
                      onClick={sortTable}
                    ></span>
                  </span>
                </th>
                <th
                  width=""
                  id="currency_th"
                  className="certif-table-sort-col-wrapper"
                >
                  <span className="certif-table-sort-col-name">
                    Currency
                  </span>
                  <span className="certif-table-sort-wrapper">
                    <span
                      className="certif-table-sort-up"
                      sort-col-id="currency"
                      sort-col-interactive-id="certif-country-filter-id"
                      sort-col-type="asc"
                      onClick={sortTable}
                    ></span>
                    <span
                      className="certif-table-sort-down"
                      sort-col-id="currency"
                      sort-col-interactive-id="certif-country-filter-id"
                      sort-col-type="desc"
                      onClick={sortTable}
                    ></span>
                  </span>
                </th>
                {/* <th width="">Amount</th> */}
                {/* <th width="">currency</th> */}
                <th width="">Issue Certificate External</th>
                <th width="">Message Code</th>
                <th width="">High Risk Country</th>
                <th className="certif-admin-sticky-column" width="72px">Region</th>
                <th className="certif-admin-sticky-column" width="72px">Details</th>
              </tr>
            </thead>
            <tbody>
              {filteredTemplates.map((template, templateIndex) => (
                <tr
                  key={templateIndex}
                  className="certif-admin-template-item-row"
                >
                  {fields.map((field, fieldIndex) => (
                    <td
                      id={templateIndex}
                      key={fieldIndex}
                      data-id={template.id}
                      onClick={gotoEditPage}
                      data-key={field === "region" || field === "details" ? field : ""}
                      className={
                        field === "region" || field === "details" ? "certif-table-center-col certif-admin-sticky-column" : ""
                      }
                    >
                      {field === "countryCode" && <>{template[field]}</>}
                      {field === "region" && (
                        <span className="">
                          <img src={EditImg} alt="Edit" />
                        </span>
                      )}
                      {field === "issueCertif" && (
                        <>{template[field] ? "Yes" : "No"}</>
                      )}
                      {field === "details" && (
                        <span className="">
                          <img src={EditImg} alt="Edit" />
                        </span>
                      )}
                      {field === "requestForOriginal" && (
                        <>{template[field] ? "Yes" : "No"}</>
                      )}
                      {field === "amount" && <>{template[field]}</>}
                      {field === "currency" && <>{template[field]}</>}
                      {field === "issueCertifExt" && (
                        <>{template[field] ? "Yes" : "No"}</>
                      )}
                      {field === "messageCode" && <>{template[field]}</>}
                      {field === "highRiskCountry" && (
                        <>{template[field] ? "Yes" : "No"}</>
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>

        <div className="certif-bar-wrapper">
          <div className="certif-bar-right-buttons">
            {/* <button className="btn btn-primary btn-sm" onClick={submitHandler}>
              Save
            </button> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default CountryList;
