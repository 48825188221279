import { useState, useEffect, useRef, useContext } from "react";
import { useTranslation, Trans } from "react-i18next";
import { Modal } from "react-bootstrap";
import { Editor } from "primereact/editor";
import "primereact/resources/primereact.min.css";

// import { Editor } from "@tinymce/tinymce-react";
import Utils from "../../../controls/Utils";
import Error from "../../layout/body/Error";
import AppContextComponent from "../../../controls/AppContext";
import CertifAlert from "../../../controls/CertifAlert4AdminScreens";

function AddEditCertificateTemplates({ props }) {
    let REQ_FIELDS = [
        "language", //
        "template", //
        "countryName", //
        "membershipType", //
        "templateContent", //
    ];
    const fields4ErrorTemplate = {
        language: "Language",
        template: "Template",
        countryName: "Country Name",
        membershipType: "Membership Type",
        templateContent: "Template Content",
    };

    // console.log(props);
    let { setLoadingState, setPageInfo, setCanRedirectToLogin, setCanRedirectToError } = useContext(AppContextComponent);
    let { t } = useTranslation();
    let [isSuperAdmin, setIsSuperAdmin] = useState(Utils.isSuperAdminAccess());

    let [canRender, setCanRender] = useState(true);
    let [pageMode, setPageMode] = useState(props.mode);
    let [canContinue, setCanContinue] = useState(false);
    let [errorMessageKey, setErrorMessageKey] = useState("");
    let [errorFields, setErrorFields] = useState({});
    let [showModal, setShowModal] = useState(false);
    let [errorMessage, setErrorMessage] = useState('');
    let [modalErrorType, setModalErrorType] = useState('');
    let [pageModeStatus, setPageModeStatus] = useState(false);

    const anyCallback = () => {
        setLoadingState({
            applyMask: false,
        });
    };

    let formHeading = props.mode === "add" ? "Add Certificate Template" : "Edit Certificate Template";

    let defTemplate =
        props.mode === "add" ? "" : props?.templates?.[props.recordIndex]?.["template"] || "";

    let [template, setTemplate] = useState(defTemplate);

    let deflanguage =
        props.mode === "add"
            ? ""
            : props?.templates?.[props.recordIndex]?.["language"] || "";

    let [language, setLanguage] = useState(
        deflanguage,
    );

    const languageOptions = [
        {
            value: "",
            displayName: "Select",
        },
        {
            value: "en",
            displayName: "English",
        },
        {
            value: "fr",
            displayName: "French",
        },
    ];

    let defMedicalAssistance =
        props.mode === "add"
            ? ""
            : props?.templates?.[props.recordIndex]?.["medicalAssistance"] || "";

    let [medicalAssistance, setMedicalAssistance] = useState(
        defMedicalAssistance,
    );

    let defCountryName =
        props.mode === "add"
            ? ""
            : props?.templates?.[props.recordIndex]?.["countryName"] || "";

    let [countryName, setCountryName] = useState(
        defCountryName,
    );

    let defMembershipType =
        props.mode === "add"
            ? ""
            : props?.templates?.[props.recordIndex]?.["membershipType"] || "";

    let [membershipType, setMembershipType] = useState(
        defMembershipType,
    );

    const membershipTypeOptions = [
        {
            value: "",
            displayName: "Select",
        },
        {
            value: "MemberShip Access",
            displayName: "MemberShip Access",
        },
        {
            value: "MemberShip Service",
            displayName: "MemberShip Service",
        },
        {
            value: "Subscription Service",
            displayName: "Subscription Service",
        },
    ];

    let defBeneficiary =
        props.mode === "add"
            ? ""
            : props?.templates?.[props.recordIndex]?.["beneficiary"] || "";

    let [beneficiary, setBeneficiary] = useState(
        defBeneficiary,
    );

    let defTemplateContent =
        props.mode === "add"
            ? ""
            : props?.templates?.[props.recordIndex]?.["templateContent"] || "";

    let [templateContent, setTemplateContent] = useState(
        defTemplateContent,
    );

    const executeCancel = (options = {}) => {
        props.setPageMode("list");
    };

    const submitRecord = () => {
        let mode = pageMode;
        setLoadingState({
            applyMask: true,
        });
        let tempTemplates = [...props.templates];
        let record = {};

        props.fields.forEach((field, index) => {
            let fieldEle = document.getElementById(field);
            let fieldValue = '';

            if (fieldEle) {
                if (fieldEle.type === 'checkbox') {
                    fieldValue = fieldEle.checked;
                } else {
                    fieldValue = field === "templateContent" ? templateContent : fieldEle.value.trim();
                }
            }

            record[field] = fieldValue;

            // let fieldValue =
            //     field.type === "checkbox" ? fieldEle.checked : fieldEle.value;
            // field === "templateContent" && (fieldValue = templateContent);
            // record[field] = fieldValue;
        });

        console.log("New record");
        console.log(record);

        const success = (res) => {
            console.log(tempTemplates);
            console.log(res);

            mode === "add" && tempTemplates.push(res.data);
            mode === "edit" && (tempTemplates[props.recordIndex] = res.data);

            props.setTemplates(tempTemplates);
            setPageModeStatus(true);
            let statusMessage = res.data !== [] ? Utils.USER_INTERACTION_MESSAGES_4_ADMIN_SCREENS.save : "ok";
            mode === "add" && (statusMessage = Utils.USER_INTERACTION_MESSAGES_4_ADMIN_SCREENS.save);
            mode === "edit" && (statusMessage = Utils.USER_INTERACTION_MESSAGES_4_ADMIN_SCREENS.update);
            setErrorMessage(statusMessage);
            setLoadingState({
                applyMask: false,
            });
            setModalErrorType("info");
            setShowModal(true);

            // props.setPageMode("list");
        };

        const fail = ({ error }) => {
            console.log(error);
            // cost errCode = error.error.
            let errCode = error?.response?.status;
            if (errCode === 400) {
                const errorMessage = error.response ? error.response.data.message :
                    Utils.USER_INTERACTION_MESSAGES_4_ADMIN_SCREENS.errorAlt;
                const updatedErrorMessage = errorMessage.slice(7);
                console.log(typeof (updatedErrorMessage));
                console.log(updatedErrorMessage);
                setErrorMessage(updatedErrorMessage);
                setPageModeStatus(false);
                setLoadingState({
                    applyMask: false,
                });
                // Display the error modal
                setModalErrorType('error');
                // setShowErrorModal(true);
                setShowModal(true);
            } else {
                console.log(error);
                Utils.handleDefaultError({
                    error,
                    setLoadingState,
                    setCanRedirectToLogin,
                    setCanRedirectToError,
                    setPageInfo,
                });
            }
        };

        mode === "add" && Utils.addCertificateTemplates(record).then(success, fail);
        mode === "edit" &&
            Utils.editCertificateTemplates(
                record,
                props.templates[props.recordIndex].id,
            ).then(success, fail);
    };

    const validateForm = () => {
        let tempErrorFields = {};

        REQ_FIELDS.forEach((fieldId) => {
            let field = document.getElementById(fieldId);
            let fieldVal = '';
            if (field) {
                fieldVal =
                    field.type === "checkbox" ? field.checked : field.value?.trim() ?? "";
                fieldId === "templateContent" && (fieldVal = templateContent);
                if (fieldVal.length === 0) {
                    if (!tempErrorFields[fieldId]) {
                        // tempErrorFields[fieldId] = errorMessages[fieldId][0];
                        tempErrorFields[fieldId] = Utils.ADMIN_SECTIONS_ERROR_TEMPLATE.replace('{}', fields4ErrorTemplate[fieldId]);
                    }
                }
            }
        });
        setErrorFields(tempErrorFields);
        return tempErrorFields;

    }

    const executeSave = (options = {}) => {
        let detailsObj = validateForm();
        // console.log(detailsObj);
        if (Utils.isObjectEmpty(detailsObj)) {
            submitRecord();
        }
    };

    const executeUpdate = (options = {}) => {
        let detailsObj = validateForm();
        // console.log(detailsObj);
        if (Utils.isObjectEmpty(detailsObj)) {
            submitRecord();
        }
    };

    const initiateDelete = (options = {}) => {
        setShowAlert(true);
    };

    let [showAlert, setShowAlert] = useState(false);

    const handleClose = (e) => {
        setShowAlert(false);
    };

    const executeDelete = (e) => {
        setLoadingState({
            applyMask: true,
        });
        let tempTemplates = [...props.templates];

        const delSuccess = (res) => {
            setPageModeStatus(false);
            tempTemplates.splice(props.recordIndex, 1);
            const statusMessage = res.data !== [] ? Utils.USER_INTERACTION_MESSAGES_4_ADMIN_SCREENS.delete : "Ok";
            setErrorMessage(statusMessage);
            setModalErrorType('info');
            // setShowErrorModal(true);
            setShowModal(true);
            setPageModeStatus(true);
            props.setTemplates(tempTemplates);
            setLoadingState({
                applyMask: false,
            });
            setShowAlert(false);
            // props.setPageMode("list");
        };

        const delFail = ({ error }) => {
            console.log(error);
            Utils.handleDefaultError({
                error,
                setLoadingState,
                setCanRedirectToLogin,
                setCanRedirectToError,
                setPageInfo,
            });
        };

        let recordIdToBeDelete = props.templates[props.recordIndex].id;
        // console.log(recordIdToBeDelete);
        Utils.deleteCertificateTemplates(recordIdToBeDelete).then(delSuccess, delFail);
    };

    return (
        <>
            <Modal centered show={showAlert} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete confirmation?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>Are you sure to delete this item?</div>
                </Modal.Body>
                <Modal.Footer>
                    <div>
                        <div className="d-flex certif-delete-confirmation">
                            <button onClick={handleClose} className="btn btn-primary btn-sm">
                                No
                            </button>
                            <button
                                onClick={executeDelete}
                                className="btn btn-primary btn-sm"
                            >
                                Yes
                            </button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
            <CertifAlert
                props={{
                    showModal,
                    setShowModal,
                    modalType: modalErrorType,
                    bodyContent: errorMessage,
                    backdrop: "static",
                    keyboard: false,
                    pageModeStatus,
                    setPageModeProp: props.setPageMode,
                }}
            />
            {canRender && (
                <div>
                    {/* <Error props={{ errorMessageKey }} /> */}

                    <div className="table-responsive">
                        <table className="table table-borderless">
                            <thead className="certif-table-header">
                                <tr>
                                    <th className="certif-table-header-label">{formHeading}</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody className="certif-table-body">
                                <tr className="col-lg-12 col-md-12 col-sm-12">
                                    <td className="col-lg-6 col-md-6 col-sm-6">
                                        <label>
                                            Template
                                            <span className="certif-required-indicator">*</span>
                                        </label>
                                        <div>
                                            <input type="text" defaultValue={template} id={"template"} />
                                            {errorFields.template && (<p className="error-message" style={{ color: "red" }}>
                                                {errorFields.template}
                                            </p>)}
                                        </div>
                                    </td>
                                    <td colSpan="2">
                                        <label>
                                            Language
                                            <span className="certif-required-indicator">*</span>
                                        </label>
                                        <div>
                                            <select
                                                // onChange={onChangeLanguage}
                                                defaultValue={language}
                                                id={"language"}
                                            >
                                                {languageOptions?.map((lang, index) => (
                                                    <option value={lang.value} key={index}>
                                                        {lang.displayName}
                                                    </option>
                                                ))}
                                            </select>
                                            {errorFields.language && (<p className="error-message" style={{ color: "red" }}>
                                                {errorFields.language}
                                            </p>)}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="1">
                                        <label>
                                            Country Name
                                            <span className="certif-required-indicator">*</span>
                                        </label>
                                        <div>
                                            <input type="text" defaultValue={countryName} id={"countryName"} />
                                            {errorFields.countryName && (<p className="error-message" style={{ color: "red" }}>
                                                {errorFields.countryName}
                                            </p>)}
                                        </div>
                                    </td>
                                    <td className="certif-admin-flex-check-td">
                                        <div className="certif-flex-check-label-wrapper">
                                            <input
                                                id="medicalAssistance"
                                                type="checkbox"
                                                defaultChecked={medicalAssistance}
                                                className="certif-input-field"
                                            // onChange={emailBodyForOriginalHandler}
                                            />
                                            <label>
                                                Medical Assistance
                                                {/* <span className="certif-required-indicator">*</span> */}
                                            </label>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="1">
                                        <label>
                                            Membership Type
                                            <span className="certif-required-indicator">*</span>
                                        </label>
                                        <div>
                                            <select
                                                // onChange={onChangeLanguage}
                                                defaultValue={membershipType}
                                                id={"membershipType"}
                                            >
                                                {membershipTypeOptions?.map((membershipType, index) => (
                                                    <option value={membershipType.value} key={index}>
                                                        {membershipType.displayName}
                                                    </option>
                                                ))}
                                            </select>
                                            {errorFields.membershipType && (<p className="error-message" style={{ color: "red" }}>
                                                {errorFields.membershipType}
                                            </p>)}
                                        </div>
                                    </td>
                                    <td className="certif-admin-flex-check-td">
                                        <div className="certif-flex-check-label-wrapper">
                                            <input
                                                id="beneficiary"
                                                type="checkbox"
                                                defaultChecked={beneficiary}
                                                className="certif-input-field"
                                            />
                                            <label>
                                                Beneficiary
                                                {/* <span className="certif-required-indicator">*</span> */}
                                            </label>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="3">
                                        <label>
                                            Template Content
                                            <span className="certif-required-indicator">*</span>
                                        </label>
                                        <div>
                                            <Editor
                                                value={templateContent}
                                                id="templateContent"
                                                onTextChange={(e) => setTemplateContent(e.htmlValue)}
                                                style={{ height: "250px" }}
                                            />
                                            {errorFields.templateContent && (<p className="error-message" style={{ color: "red" }}>
                                                {errorFields.templateContent}
                                            </p>)}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot className="certif-table-footer">
                                <tr>
                                    <th>
                                        {/* <div className="d-flex">
                                            {isSuperAdmin && props.mode === "edit" && (
                                                <button
                                                    className="btn btn-primary btn-sm"
                                                    onClick={initiateDelete}
                                                >
                                                    Delete
                                                </button>
                                            )}
                                        </div> */}
                                    </th>
                                    <th></th>
                                    <th>
                                        <div className="d-flex">
                                            <button
                                                className="btn btn-primary btn-sm"
                                                onClick={executeCancel}
                                            >
                                                {t("Cancel")}
                                            </button>

                                            {/* {props.mode === "edit" && (
                                                <button
                                                    className="btn btn-primary btn-sm"
                                                    onClick={executeUpdate}
                                                >
                                                    Update
                                                </button>
                                            )} */}

                                            {props.mode === "add" && (
                                                <button
                                                    className="btn btn-primary btn-sm"
                                                    onClick={executeSave}
                                                >
                                                    Save
                                                </button>
                                            )}
                                        </div>
                                    </th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            )}
        </>
    );
}

export default AddEditCertificateTemplates;
