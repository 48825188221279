import { useState, useEffect, useRef, useContext } from "react";
import { useTranslation, Trans } from "react-i18next";
import { Modal, Form } from "react-bootstrap";

import "primereact/resources/primereact.min.css";

// import { Editor } from "@tinymce/tinymce-react";
import Utils from "../../../controls/Utils";
import Error from "../../layout/body/Error";
import AppContextComponent from "../../../controls/AppContext";
import CertifAlert from "../../../controls/CertifAlert4AdminScreens";

function AddEditUserManagement({ props }) {
    let ADD_REQ_FIELDS = [
        "userName", //
        "email", //
        "password", //
        "roles", //
        "firstName", //
        "lastName", //
    ];
    let EDIT_REQ_FIELDS = [
        "userName", //
        "email", //
        "roles", //
        "firstName", //
        "lastName", //
    ];

    let REQ_FIELDS = props.mode === "add" ? ADD_REQ_FIELDS : EDIT_REQ_FIELDS;

    const fields4ErrorTemplate = {
        userName: "User Name",
        email: "Email",
        password: "Password",
        roles: "Roles",
        firstName: "First Name",
        lastName: "Last Name",
    };

    // console.log(props);
    let { setLoadingState, setPageInfo, setCanRedirectToLogin, setCanRedirectToError } = useContext(AppContextComponent);
    let { t } = useTranslation();
    let [isSuperAdmin, setIsSuperAdmin] = useState(Utils.isSuperAdminAccess());

    let [canRender, setCanRender] = useState(true);
    let [pageMode, setPageMode] = useState(props.mode);
    let [errorMessageKey, setErrorMessageKey] = useState("");
    let [errorFields, setErrorFields] = useState({});
    let [showModal, setShowModal] = useState(false);
    let [errorMessage, setErrorMessage] = useState('');
    let [modalErrorType, setModalErrorType] = useState('');
    let [pageModeStatus, setPageModeStatus] = useState(false);
    let [emailError, setEmailError] = useState('');

    const anyCallback = () => {
        setLoadingState({
            applyMask: false,
        });
    };

    let formHeading = props.mode === "add" ? "Add User" : "Edit User";

    let defUserName =
        props.mode === "add" ? "" : props?.templates?.[props.recordIndex]?.["userName"] || "";

    let [userName, setUserName] = useState(defUserName);

    let defEmail =
        props.mode === "add" ? "" : props?.templates?.[props.recordIndex]?.["email"] || "";

    let [email, setEmail] = useState(defEmail);

    let defPassword =
        props.mode === "add" ? "" : props?.templates?.[props.recordIndex]?.["password"] || "";

    let [password, setPassword] = useState(defPassword || "");

    let defRoles =
        props.mode === "add"
            ? ""
            : props?.templates?.[props.recordIndex]?.["roles"] || "";

    let [roles, setRoles] = useState(
        defRoles,
    );

    const rolesOptions = [
        {
            value: "ROLE_ADMIN",
            displayName: "Admin",
        },
        {
            value: "ROLE_EXTERNAL_USER",
            displayName: "External User"
        },
        {
            value: "ROLE_SUPER_ADMIN",
            displayName: "Super Admin",
        },
        {
            value: "ROLE_USER",
            displayName: "User",
        },
    ];

    let defFirstName =
        props.mode === "add" ? "" : props?.templates?.[props.recordIndex]?.["firstName"] || "";

    let [firstName, setFirstName] = useState(defFirstName);

    let defLastName =
        props.mode === "add" ? "" : props?.templates?.[props.recordIndex]?.["lastName"] || "";

    let [lastName, setLastName] = useState(defLastName);

    const changeMultiSelectInputHandler = (e) => {
        let ele = e.currentTarget;
        let tempRoleValues = { ...roles };

        var options = [].slice.call(e.currentTarget.querySelectorAll("option"));
        var selected = options.filter(function (option) {
            return option.selected;
        });
        var selectedValues = selected.map(function (option) {
            return option.value;
        });

        tempRoleValues[e.currentTarget.id] = selectedValues;
        setRoles(tempRoleValues.roles);
    };


    const executeCancel = (options = {}) => {
        props.setPageMode("list");
    };

    const submitRecord = () => {
        let mode = pageMode;
        setLoadingState({
            applyMask: true,
        });
        let tempTemplates = [...props.templates];
        let record = {};

        props.fields.forEach((field, index) => {
            let fieldEle = document.getElementById(field);
            let fieldValue = '';

            if (fieldEle.type === 'checkbox') {
                fieldValue = fieldEle.checked;
            } else if (field === "roles") {
                fieldValue = roles ?? [];
            } else {
                fieldValue = fieldEle.value;
            }

            // if (fieldEle) {
            //     if (fieldEle.type === 'checkbox') {
            //         fieldValue = fieldEle.checked;
            //     } else {
            //         fieldValue = field === "roles" ? roles : fieldEle.value;
            //     }
            // }
            record[field] = fieldValue;
        });

        // console.log("New record");
        // console.log(record);

        const success = (res) => {
            // console.log(tempTemplates);
            // console.log(res);

            mode === "add" && tempTemplates.push(res.data);
            mode === "edit" && (tempTemplates[props.recordIndex] = res.data);

            props.setTemplates(tempTemplates);
            setPageModeStatus(true);
            let statusMessage = res.data !== [] ? Utils.USER_INTERACTION_MESSAGES_4_ADMIN_SCREENS.save : "Ok";
            mode === "add" && (statusMessage = Utils.USER_INTERACTION_MESSAGES_4_ADMIN_SCREENS.save);
            mode === "edit" && (statusMessage = Utils.USER_INTERACTION_MESSAGES_4_ADMIN_SCREENS.update);
            setErrorMessage(statusMessage);
            setLoadingState({
                applyMask: false,
            });
            setModalErrorType("info");
            setShowModal(true);

            // props.setPageMode("list");
        };

        const fail = ({ error }) => {
            console.log(error);
            // cost errCode = error.error.
            let errCode = error?.response?.status;
            if (errCode === 400) {
                const errorMessage = error.response ? error.response.data.message :
                    Utils.USER_INTERACTION_MESSAGES_4_ADMIN_SCREENS.errorAlt;
                const updatedErrorMessage = errorMessage.slice(7);
                console.log(typeof (updatedErrorMessage));
                console.log(updatedErrorMessage);
                setErrorMessage(updatedErrorMessage);
                setPageModeStatus(false);
                setLoadingState({
                    applyMask: false,
                });
                // Display the error modal
                setModalErrorType('error');
                // setShowErrorModal(true);
                setShowModal(true);
            } else {
                Utils.handleDefaultError({
                    error,
                    setLoadingState,
                    setCanRedirectToLogin,
                    setCanRedirectToError,
                    setPageInfo,
                });
            }
        };

        mode === "add" && Utils.addUser(record).then(success, fail);
        mode === "edit" &&
            Utils.editUser(
                record,
                props.templates[props.recordIndex].id,
            ).then(success, fail);
    };

    const validateForm = () => {
        let tempErrorFields = {};

        REQ_FIELDS.forEach((fieldId) => {
            let field = document.getElementById(fieldId);
            if (field) {
                let fieldVal =
                    field.type === "checkbox" ? field.checked : field?.value?.trim();
                if (fieldVal.length === 0) {
                    if (!tempErrorFields[fieldId]) {
                        // tempErrorFields[fieldId] = errorMessages[fieldId][0];
                        tempErrorFields[fieldId] = Utils.ADMIN_SECTIONS_ERROR_TEMPLATE.replace('{}', fields4ErrorTemplate[fieldId]);
                    }
                }
                if (fieldId === "email" && fieldVal.length !== 0 && !validateEmail(fieldVal) && !tempErrorFields['emailErrorField']) {
                    tempErrorFields['emailErrorField'] = Utils.ADMIN_SECTIONS_ERROR_TEMPLATE.replace('{}', fields4ErrorTemplate[fieldId]);
                }
            }
        });
        setErrorFields(tempErrorFields);
        return tempErrorFields;

    }

    const executeSave = (options = {}) => {
        let detailsObj = validateForm();
        // console.log(detailsObj);
        if (Utils.isObjectEmpty(detailsObj)) {
            submitRecord();
        }
    };

    const executeUpdate = (options = {}) => {
        let detailsObj = validateForm();
        // console.log(detailsObj);
        if (Utils.isObjectEmpty(detailsObj)) {
            submitRecord();
        }
    };

    const initiateDelete = (options = {}) => {
        setShowAlert(true);
    };

    let [showAlert, setShowAlert] = useState(false);

    const handleClose = (e) => {
        setShowAlert(false);
    };

    const executeDelete = (e) => {
        setLoadingState({
            applyMask: true,
        });
        let tempTemplates = [...props.templates];

        const delSuccess = (res) => {
            setPageModeStatus(false);
            tempTemplates.splice(props.recordIndex, 1);
            const statusMessage = res.data !== [] ? Utils.USER_INTERACTION_MESSAGES_4_ADMIN_SCREENS.delete : "Ok";
            setErrorMessage(statusMessage);
            setModalErrorType('info');
            // setShowErrorModal(true);
            setShowModal(true);
            setPageModeStatus(true);
            props.setTemplates(tempTemplates);
            setLoadingState({
                applyMask: false,
            });
            setShowAlert(false);
            // props.setPageMode("list");
        };

        const delFail = ({ error }) => {
            Utils.handleDefaultError({
                error,
                setLoadingState,
                setCanRedirectToLogin,
                setCanRedirectToError,
                setPageInfo,
            });
        };

        let recordIdToBeDelete = props.templates[props.recordIndex].id;
        // console.log(recordIdToBeDelete);
        Utils.deleteUser(recordIdToBeDelete).then(delSuccess, delFail);
    };

    const validateEmail = (email) => {
        const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
        return isValidEmail;
    };

    const handleEmailBlur = (e) => {
        const inputEmail = e.currentTarget.value;
        const isValidEmail = validateEmail(inputEmail);

        if (isValidEmail) {
            setEmailError("");
        } else {
            setEmailError(t("emailError"));
        }
    };

    return (
        <>
            <Modal centered show={showAlert} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete confirmation?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>Are you sure to delete this item?</div>
                </Modal.Body>
                <Modal.Footer>
                    <div>
                        <div className="d-flex certif-delete-confirmation">
                            <button onClick={handleClose} className="btn btn-primary btn-sm">
                                No
                            </button>
                            <button
                                onClick={executeDelete}
                                className="btn btn-primary btn-sm"
                            >
                                Yes
                            </button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
            <CertifAlert
                props={{
                    showModal,
                    setShowModal,
                    modalType: modalErrorType,
                    bodyContent: errorMessage,
                    backdrop: "static",
                    keyboard: false,
                    pageModeStatus,
                    setPageModeProp: props.setPageMode,
                }}
            />
            {canRender && (
                <div>
                    {/* <Error props={{ errorMessageKey }} /> */}

                    <div className="table-responsive">
                        <table className="table table-borderless">
                            <thead className="certif-table-header">
                                <tr>
                                    <th className="certif-table-header-label">{formHeading}</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody className="certif-table-body">
                                <tr className="col-lg-12 col-md-12 col-sm-12">
                                    <td className="col-lg-6 col-md-6 col-sm-6">
                                        <label>
                                            User Name
                                            <span className="certif-required-indicator">*</span>
                                        </label>
                                        <div>
                                            <input type="text" defaultValue={userName} id={"userName"} />
                                            {errorFields.userName && (<p className="error-message" style={{ color: "red" }}>
                                                {errorFields.userName}
                                            </p>)}
                                        </div>
                                    </td>
                                    <td className="col-lg-6 col-md-6 col-sm-6">
                                        <label>
                                            Email
                                            <span className="certif-required-indicator">*</span>
                                        </label>
                                        <div>
                                            <input
                                                type="text"
                                                defaultValue={email}
                                                id={"email"}
                                                onBlur={handleEmailBlur}
                                            />
                                            {errorFields.email && (<p className="error-message" style={{ color: "red" }}>
                                                {errorFields.email}
                                            </p>)}
                                            <p className="error-message" style={{ color: "red" }}>
                                                {emailError}
                                            </p>
                                        </div>
                                    </td>
                                </tr>
                                <tr className="col-lg-12 col-md-12 col-sm-12">
                                    <td className="col-lg-6 col-md-6 col-sm-6">
                                        <label>
                                            First Name
                                            <span className="certif-required-indicator">*</span>
                                        </label>
                                        <div>
                                            <input
                                                id="firstName"
                                                type="text"
                                                defaultValue={firstName}
                                            />
                                            {errorFields.firstName && (<p className="error-message" style={{ color: "red" }}>
                                                {errorFields.firstName}
                                            </p>)}
                                        </div>
                                    </td>
                                    <td className="col-lg-6 col-md-6 col-sm-6">
                                        <label>
                                            Last Name
                                            <span className="certif-required-indicator">*</span>
                                        </label>
                                        <div>
                                            <input type="text" defaultValue={lastName} id={"lastName"} />
                                            {errorFields.lastName && (<p className="error-message" style={{ color: "red" }}>
                                                {errorFields.lastName}
                                            </p>)}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <label>Roles</label>
                                        <span className="certif-required-indicator">*</span>
                                        <div>
                                            <Form.Select
                                                className="certif-admin-reports-multiselect"
                                                id={"roles"}
                                                onChange={changeMultiSelectInputHandler}
                                                multiple
                                                aria-label="Select multiple roles"
                                                defaultValue={roles}
                                            >
                                                {rolesOptions?.map((roleItem, index) => (
                                                    <option key={index} value={roleItem["value"]}>
                                                        {roleItem["displayName"]}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                            {errorFields.roles && (<p className="error-message" style={{ color: "red" }}>
                                                {errorFields.roles}
                                            </p>)}
                                        </div>
                                    </td>
                                    <td className="col-lg-6 col-md-6 col-sm-6">
                                        <label>
                                            Password
                                            {props.mode === "add" && <span className="certif-required-indicator">*</span>}
                                        </label>
                                        <div>
                                            <input type="password" defaultValue={password} id={"password"} />
                                            {props.mode === "add" && errorFields.password && (<p className="error-message" style={{ color: "red" }}>
                                                {errorFields.password}
                                            </p>)}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot className="certif-table-footer">
                                <tr>
                                    <th>
                                        <div className="d-flex">
                                            {isSuperAdmin && props.mode === "edit" && (
                                                <button
                                                    className="btn btn-primary btn-sm"
                                                    onClick={initiateDelete}
                                                >
                                                    Delete
                                                </button>
                                            )}
                                        </div>
                                    </th>
                                    <th></th>
                                    <th>
                                        <div className="d-flex">
                                            <button
                                                className="btn btn-primary btn-sm"
                                                onClick={executeCancel}
                                            >
                                                {t("cancel")}
                                            </button>

                                            {props.mode === "edit" && (
                                                <button
                                                    className="btn btn-primary btn-sm"
                                                    onClick={executeUpdate}
                                                >
                                                    Update
                                                </button>
                                            )}

                                            {props.mode === "add" && (
                                                <button
                                                    className="btn btn-primary btn-sm"
                                                    onClick={executeSave}
                                                >
                                                    Save
                                                </button>
                                            )}
                                        </div>
                                    </th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            )}
        </>
    );
}

export default AddEditUserManagement;
