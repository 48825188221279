import { useState, useEffect, useRef, useContext } from "react";
import { useTranslation, Trans } from "react-i18next";

import { Modal } from "react-bootstrap";

import { Editor } from "primereact/editor";
import "primereact/resources/primereact.min.css";

// import { Editor } from "@tinymce/tinymce-react";
import Utils from "../../../controls/Utils";
import Error from "../../layout/body/Error";
import AppContextComponent from "../../../controls/AppContext";
import CertifAlert from "../../../controls/CertifAlert4AdminScreens";

function AddEditTemplate({ props }) {
  let REQ_FIELDS = [
    "name", //
    "legalEntity", //
    "language", //
    "emailSubject", //
    "emailBody", //
  ];
  const fields4ErrorTemplate = {
    name: "Name",
    legalEntity: "Legal Entity",
    language: "Language",
    emailSubject: "Email Subject",
    emailBody: "Email Body",
  };

  // console.log(props);
  let { setLoadingState, setPageInfo, setCanRedirectToLogin, setCanRedirectToError } = useContext(AppContextComponent);
  let { t } = useTranslation();
  let [isSuperAdmin, setIsSuperAdmin] = useState(Utils.isSuperAdminAccess());

  let [canRender, setCanRender] = useState(true);
  let [pageMode, setPageMode] = useState(props.mode);
  let [canContinue, setCanContinue] = useState(false);
  let [errorMessageKey, setErrorMessageKey] = useState("");
  let [errorFields, setErrorFields] = useState({});
  const editorRef = useRef(null);
  // let [legalEntityDD, setLegalEntityDDSuccess] = useState([]);
  let [showModal, setShowModal] = useState(false);
  let [errorMessage, setErrorMessage] = useState('');
  let [modalErrorType, setModalErrorType] = useState('');
  let [pageModeStatus, setPageModeStatus] = useState(false);

  const anyCallback = () => {
    setLoadingState({
      applyMask: false,
    });
  };

  // useEffect(() => {

  //   setLoadingState({
  //     applyMask: true,
  //   });
  //   const commonFail = ({ error }) => {
  //     console.log(error);
  //     Utils.handleDefaultError({
  //       error,
  //       setLoadingState,
  //       setCanRedirectToLogin,
  //       setCanRedirectToError,
  //       setPageInfo,
  //     });
  //   };

  //   const legalEntityDDSuccess = (res) => {
  //     res.data.sort((itemA, itemB) => {
  //       return itemA.entityName.localeCompare(itemB.entityName);
  //     });
  //     let selectOptions = [
  //       {
  //         entityId: "",
  //         entityName: "Select",
  //       },
  //       ...res.data,
  //     ];
  //     // console.log("Selling office id", res.data)
  //     setLegalEntityDDSuccess(selectOptions);
  //     setCanRender(true);
  //     anyCallback();
  //   };

  //   Utils.fetchData("LEGAL_ENTITY_DD").then(legalEntityDDSuccess, commonFail);
  // }, []);

  let formHeading = props.mode === "add" ? "Add template" : "Edit template";

  let defName =
    props.mode === "add" ? "" : props?.templates?.[props.recordIndex]?.["name"] || "";

  let [name, setName] = useState(defName);


  // let sellingOff =
  //   props.mode === "add"
  //     ? ""
  //     : props.templates[props.recordIndex]["sellingOffice"];

  // let [sellingOffice, setSellingOffice] = useState(sellingOff);

  // const sellingOfficeOptions = [];

  // for (let i = 1; i <= props.tempNoOfRecords; i++) {
  //   sellingOfficeOptions.push({
  //     value: "sellingOffice_" + i,
  //     displayName: "Selling office " + i,
  //   });
  // }

  let legalEnt =
    props.mode === "add"
      ? ""
      : props?.templates?.[props.recordIndex]?.["legalEntity"] || "";

  let [legalEntity, setLegalEntity] = useState(legalEnt);

  // const legalEntityOptions = [];

  // for (let i = 1; i <= props.tempNoOfRecords; i++) {
  //   legalEntityOptions.push({
  //     value: "legalEntity_" + i,
  //     displayName: "Legal Entity " + i,
  //   });
  // }

  let lang =
    props.mode === "add" ? "" : props?.templates?.[props.recordIndex]?.["language"] || "";

  let [language, setLanguage] = useState(lang);

  const languageOptions = [
    {
      value: "",
      displayName: "Select",
    },
    {
      value: "en",
      displayName: "English",
    },
    {
      value: "fr",
      displayName: "French",
    },
  ];

  let eBodySub =
    props.mode === "add"
      ? ""
      : props?.templates?.[props.recordIndex]?.["emailSubject"] || "";

  let [emailSubject, setEmailSubject] = useState(eBodySub);

  let eBody =
    props.mode === "add" ? "" : props?.templates?.[props.recordIndex]?.["emailBody"] || "";
  let [emailBody, setEmailBody] = useState(eBody);

  let defEmailBodyContentForPdfLink =
    props.mode === "add"
      ? ""
      : props?.templates?.[props.recordIndex]?.["emailBodyContentForPdfLink"] || "";

  let [emailBodyContentForPdfLink, setEmailBodyContentForPdfLink] = useState(
    defEmailBodyContentForPdfLink
  );

  let defEmailBodyContentForPassword =
    props.mode === "add"
      ? ""
      : props?.templates?.[props.recordIndex]?.["emailBodyContentForPassword"] || "";

  let [emailBodyContentForPassword, setEmailBodyContentForPassword] = useState(
    defEmailBodyContentForPassword,
  );

  let defEmailBodyOriginalContentForPdfLink =
    props.mode === "add"
      ? ""
      : props?.templates?.[props.recordIndex]?.["emailBodyOriginalContentForPdfLink"] || "";

  let [emailBodyOriginalContentForPdfLink, setEmailBodyOriginalContentForPdfLink] = useState(
    defEmailBodyOriginalContentForPdfLink
  );

  let eBodyChk =
    props.mode === "add"
      ? ""
      : props?.templates?.[props.recordIndex]?.["emailBodyForOriginal"] || "";

  let [emailBodyForOriginal, setEmailBodyForOriginal] = useState(eBodyChk);

  const executeCancel = (options = {}) => {
    props.setPageMode("list");
  };

  const submitRecord = () => {
    let mode = pageMode;
    setLoadingState({
      applyMask: true,
    });
    let tempTemplates = [...props.templates];
    let record = {};

    props.fields.forEach((field, index) => {
      let fieldEle = document.getElementById(field);
      let fieldValue =
        field.type === "checkbox" ? fieldEle.checked : fieldEle.value;
      field === "emailBody" && (fieldValue = emailBody);
      field === "emailBodyContentForPdfLink" && (fieldValue = emailBodyContentForPdfLink);
      field === "emailBodyContentForPassword" && (fieldValue = emailBodyContentForPassword);
      field === "emailBodyOriginalContentForPdfLink" && (fieldValue = emailBodyOriginalContentForPdfLink);
      field === "emailBodyForOriginal" && (fieldValue = emailBodyForOriginal);
      record[field] = fieldValue;
    });

    console.log(record);

    const success = (res) => {
      console.log(tempTemplates);
      console.log(res);

      mode === "add" && tempTemplates.push(res.data);
      mode === "edit" && (tempTemplates[props.recordIndex] = res.data);

      props.setTemplates(tempTemplates);
      setPageModeStatus(true);
      let statusMessage = res.data !== [] ? Utils.USER_INTERACTION_MESSAGES_4_ADMIN_SCREENS.save : "Ok";
      mode === "add" && (statusMessage = Utils.USER_INTERACTION_MESSAGES_4_ADMIN_SCREENS.save);
      mode === "edit" && (statusMessage = Utils.USER_INTERACTION_MESSAGES_4_ADMIN_SCREENS.update);
      setErrorMessage(statusMessage);
      setLoadingState({
        applyMask: false,
      });
      setModalErrorType("info");
      setShowModal(true);

      // props.setPageMode("list");
    };

    const fail = ({ error }) => {
      console.log(error);
      // cost errCode = error.error.
      let errCode = error?.response?.status;
      if (errCode === 400) {
        const errorMessage = error.response ? error.response.data.message :
          Utils.USER_INTERACTION_MESSAGES_4_ADMIN_SCREENS.errorAlt;
        const updatedErrorMessage = errorMessage.slice(7);
        console.log(typeof (updatedErrorMessage));
        console.log(updatedErrorMessage);
        setErrorMessage(updatedErrorMessage);
        setPageModeStatus(false);
        setLoadingState({
          applyMask: false,
        });
        // Display the error modal
        setModalErrorType('error');
        // setShowErrorModal(true);
        setShowModal(true);
      } else {
        console.log(error);
        Utils.handleDefaultError({
          error,
          setLoadingState,
          setCanRedirectToLogin,
          setCanRedirectToError,
          setPageInfo,
        });
      }
    };

    mode === "add" && Utils.addEmailTemplate(record).then(success, fail);
    mode === "edit" &&
      Utils.editEmailTemplate(
        record,
        props.templates[props.recordIndex].id,
      ).then(success, fail);
  };

  const validateForm = () => {
    let tempErrorFields = {};

    REQ_FIELDS.forEach((fieldId) => {
      let field = document.getElementById(fieldId);
      let fieldVal = '';
      if (field) {
        fieldVal =
          field.type === "checkbox" ? field.checked : field.value?.trim() ?? "";
        fieldId === "emailBody" && (fieldVal = emailBody);
        if (fieldVal.length === 0) {
          if (!tempErrorFields[fieldId]) {
            // tempErrorFields[fieldId] = errorMessages[fieldId][0];
            tempErrorFields[fieldId] = Utils.ADMIN_SECTIONS_ERROR_TEMPLATE.replace('{}', fields4ErrorTemplate[fieldId]);
          }
        }
      }
    });
    setErrorFields(tempErrorFields);
    return tempErrorFields;

  }

  const executeSave = (options = {}) => {
    let detailsObj = validateForm();
    // console.log(detailsObj);
    if (Utils.isObjectEmpty(detailsObj)) {
      submitRecord();
    }
  };

  const executeUpdate = (options = {}) => {
    let detailsObj = validateForm();
    // console.log(detailsObj);
    if (Utils.isObjectEmpty(detailsObj)) {
      submitRecord();
    }
  };

  const initiateDelete = (options = {}) => {
    setShowAlert(true);
  };

  let [showAlert, setShowAlert] = useState(false);

  const handleClose = (e) => {
    setShowAlert(false);
  };

  const executeDelete = (e) => {
    setLoadingState({
      applyMask: true,
    });
    let tempTemplates = [...props.templates];

    const delSuccess = (res) => {
      setPageModeStatus(false);
      tempTemplates.splice(props.recordIndex, 1);
      const statusMessage = res.data !== [] ? Utils.USER_INTERACTION_MESSAGES_4_ADMIN_SCREENS.delete : "Ok";
      setErrorMessage(statusMessage);
      setModalErrorType('info');
      // setShowErrorModal(true);
      setShowModal(true);
      setPageModeStatus(true);
      props.setTemplates(tempTemplates);
      setLoadingState({
        applyMask: false,
      });
      setShowAlert(false);
      // props.setPageMode("list");
    };

    const delFail = ({ error }) => {
      console.log(error);
      Utils.handleDefaultError({
        error,
        setLoadingState,
        setCanRedirectToLogin,
        setCanRedirectToError,
        setPageInfo,
      });
    };

    let recordIdToBeDelete = props.templates[props.recordIndex].id;
    // console.log(recordIdToBeDelete);
    Utils.deleteEmailTemplate(recordIdToBeDelete).then(delSuccess, delFail);
  };

  return (
    <>
      <Modal centered show={showAlert} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete confirmation?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>Are you sure to delete this item?</div>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <div className="d-flex certif-delete-confirmation">
              <button onClick={handleClose} className="btn btn-primary btn-sm">
                No
              </button>
              <button
                onClick={executeDelete}
                className="btn btn-primary btn-sm"
              >
                Yes
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
      <CertifAlert
        props={{
          showModal,
          setShowModal,
          modalType: modalErrorType,
          bodyContent: errorMessage,
          backdrop: "static",
          keyboard: false,
          pageModeStatus,
          setPageModeProp: props.setPageMode,
        }}
      />
      {canRender && (
        <div>
          <Error props={{ errorMessageKey }} />
          <div className="table-responsive">
            <table className="table table-borderless">
              <thead className="certif-table-header">
                <tr>
                  <th className="certif-table-header-label">{formHeading}</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody className="certif-table-body">
                <tr className="col-lg-12 col-md-12 col-sm-12">
                  <td className="col-lg-6 col-md-6 col-sm-6">
                    <label>
                      Name
                      <span className="certif-required-indicator">*</span>
                    </label>
                    <div>
                      <input type="text" defaultValue={name} id={"name"} />
                      {errorFields.name && (<p className="error-message" style={{ color: "red" }}>
                        {errorFields.name}
                      </p>)}
                    </div>
                  </td>

                  <td className="col-lg-6 col-md-6 col-sm-6">
                    <label>
                      Language
                      <span className="certif-required-indicator">*</span>
                    </label>
                    <div>
                      <select
                        // onChange={onChangeLanguage}
                        defaultValue={language}
                        id={"language"}
                      >
                        {languageOptions?.map((lang, index) => (
                          <option value={lang.value} key={index}>
                            {lang.displayName}
                          </option>
                        ))}
                      </select>
                      {errorFields.language && (<p className="error-message" style={{ color: "red" }}>
                        {errorFields.language}
                      </p>)}
                    </div>
                  </td>
                </tr>
                <tr className="col-lg-12 col-md-12 col-sm-12">
                  <td className="col-lg-6 col-md-6 col-sm-6">
                    <label>
                      Legal Entity
                      <span className="certif-required-indicator">*</span>
                    </label>
                    <div>
                      <select
                        id={"legalEntity"}
                        defaultValue={legalEntity}
                      // onChange={onChangeLegalEntity}
                      >
                        {/* {legalEntityOptions?.map((legalEntity, index) => (
                          <option value={legalEntity.value} key={index}>
                            {legalEntity.displayName}
                          </option>
                        ))} */}
                        {props.legalEntityDDOptions?.map((sellingOffice, index) => (
                          <option value={sellingOffice.entityId} key={index}>
                            {sellingOffice.entityName}
                          </option>
                        ))}
                      </select>
                      {errorFields.legalEntity && (<p className="error-message" style={{ color: "red" }}>
                        {errorFields.legalEntity}
                      </p>)}
                    </div>
                  </td>
                  {/* <td className="col-lg-6 col-md-6 col-sm-6">
                    <label>
                      Selling office
                      <span className="certif-required-indicator">*</span>
                    </label>
                    <div>
                      <select
                        // onChange={onChangeSellingOffice}
                        defaultValue={sellingOffice}
                        id={"sellingOffice"}
                      >
                        {sellingOfficeOptions?.map((sellingOffice, index) => (
                          <option value={sellingOffice.sellingOfficeId} key={index}>
                            {sellingOffice.sellingOfficeName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </td> */}
                </tr>
                <tr className="col-lg-12 col-md-12 col-sm-12">
                  <td colSpan="2">
                    <label>
                      Email Subject
                      <span className="certif-required-indicator">*</span>
                    </label>
                    <div>
                      <input
                        id="emailSubject"
                        type="text"
                        defaultValue={emailSubject}
                        className="certif-input-field certif-required-field"
                      // onChange={emailSubjectHandler}
                      />
                      {errorFields.emailSubject && (<p className="error-message" style={{ color: "red" }}>
                        {errorFields.emailSubject}
                      </p>)}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan="3">
                    <label>
                      Email Body
                      <span className="certif-required-indicator">*</span>
                    </label>
                    <div>
                      {/* <textarea
                        id="emailBody"
                        type="text"
                        defaultValue={emailBody}
                        className="certif-input-field certif-textarea-input-field certif-admin-textarea-input-field"
                        // onChange={emailBodyHandler}
                      ></textarea> */}

                      {/* <Editor
                        apiKey="qagffr3pkuv17a8on1afax661irst1hbr4e6tbv888sz91jc"
                        id="emailBody"
                        onInit={(evt, editor) => (editorRef.current = editor)}
                        // initialValue="<p>This is the initial content of the editor.</p>"
                        initialValue={emailBody}
                        init={{
                          height: 500,
                          menubar: false,
                          plugins: [
                            "advlist",
                            "autolink",
                            "lists",
                            "link",
                            "image",
                            "charmap",
                            "preview",
                            "anchor",
                            "searchreplace",
                            "visualblocks",
                            "code",
                            "fullscreen",
                            "insertdatetime",
                            "media",
                            "table",
                            "code",
                            "help",
                            "wordcount",
                          ],
                          toolbar:
                            "undo redo | blocks | " +
                            "bold italic forecolor | alignleft aligncenter " +
                            "alignright alignjustify | bullist numlist outdent indent | " +
                            "removeformat | help",
                          content_style:
                            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                        }}
                      /> */}

                      <Editor
                        value={emailBody}
                        id="emailBody"
                        onTextChange={(e) => setEmailBody(e.htmlValue)}
                        style={{ height: "250px" }}
                      />
                      {errorFields.emailBody && (<p className="error-message" style={{ color: "red" }}>
                        {errorFields.emailBody}
                      </p>)}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan="3">
                    <label>
                      Email body content for pdf link
                      {/* <span className="certif-required-indicator">*</span> */}
                    </label>
                    <div>
                      <Editor
                        value={emailBodyContentForPdfLink}
                        id="emailBodyContentForPdfLink"
                        onTextChange={(e) => setEmailBodyContentForPdfLink(e.htmlValue)}
                        style={{ height: "250px" }}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan="3">
                    <label>
                      Email body content for password
                      {/* <span className="certif-required-indicator">*</span> */}
                    </label>
                    <div>
                      <Editor
                        value={emailBodyContentForPassword}
                        id="emailBodyContentForPassword"
                        onTextChange={(e) => setEmailBodyContentForPassword(e.htmlValue)}
                        style={{ height: "250px" }}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan="3">
                    <label>
                      Email body original content for pdf link
                      {/* <span className="certif-required-indicator">*</span> */}
                    </label>
                    <div>
                      <Editor
                        value={emailBodyOriginalContentForPdfLink}
                        id="emailBodyOriginalContentForPdfLink"
                        onTextChange={(e) => setEmailBodyOriginalContentForPdfLink(e.htmlValue)}
                        style={{ height: "250px" }}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan="3">
                    <label>
                      Email Body applicable for Original Certificate
                      {/* <span className="certif-required-indicator">*</span> */}
                    </label>
                    <div>
                      <Editor
                        value={emailBodyForOriginal}
                        id="emailBodyForOriginal"
                        onTextChange={(e) => setEmailBodyForOriginal(e.htmlValue)}
                        style={{ height: "250px" }}
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot className="certif-table-footer">
                <tr>
                  <th>
                    {/* <div className="d-flex">
                      {isSuperAdmin && props.mode === "edit" && (
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={initiateDelete}
                        >
                          Delete
                        </button>
                      )}
                    </div> */}
                  </th>
                  <th></th>
                  <th>
                    <div className="d-flex">
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={executeCancel}
                      >
                        {t("Cancel")}
                      </button>

                      {/* {props.mode === "edit" && (
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={executeUpdate}
                        >
                          Update
                        </button>
                      )} */}

                      {props.mode === "add" && (
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={executeSave}
                        >
                          Save
                        </button>
                      )}
                    </div>
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      )}
    </>
  );
}

export default AddEditTemplate;
