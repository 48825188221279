import { useState, useEffect, useRef, useContext } from "react";

import Utils from "../../../controls/Utils";
import AppContextComponent from "../../../controls/AppContext";
import NavEventMixin from "../../utilpages/NavEventMixin";

import LabelsList from "./LabelsList";
import AddEditLabels from "./AddEditLabels";

function LabelsManagement() {
  let { setLoadingState, setPageInfo, setCanRedirectToLogin, setCanRedirectToError } = useContext(AppContextComponent);

  let [canRender, setCanRender] = useState(false);
  let [pageMode, setPageMode] = useState("list");
  let [templates, setTemplates] = useState([]);
  let [fields, setFields] = useState([
    "code",
    "entext",
    "frtext",
    "description",
  ]);
  let [recordIndex, setRecordIndex] = useState(-1);

  let tempNoOfRecords = 7;

  useEffect(() => {
    setLoadingState({
      applyMask: true,
    });
    const fetchSuccess = (res) => {
      let tempTemplates = [...res.data];
      setTemplates(tempTemplates);
      setCanRender(true);
      setLoadingState({
        applyMask: false,
      });
    };

    const fetchFail = ({ error }) => {
      console.log(error);
      Utils.handleDefaultError({
        error,
        setLoadingState,
        setCanRedirectToLogin,
        setCanRedirectToError,
        setPageInfo,
      });
    };

    Utils.getLabelTemplates().then(fetchSuccess, fetchFail);
  }, []);

  useEffect(() => { }, [fields, templates, pageMode]);

  return (
    <>
      {canRender && (
        <div className="table-responsive">
          {(pageMode === "add" || pageMode === "edit") && (
            <NavEventMixin
              props={{
                pageMode,
                setPageMode,
              }}
            />
          )}
          {pageMode === "list" && (
            <LabelsList
              props={{
                fields,
                templates,
                setPageMode,
                recordIndex,
                setRecordIndex,
              }}
            />
          )}
          {pageMode === "add" && (
            <AddEditLabels
              props={{
                fields,
                templates,
                setTemplates,
                setPageMode,
                mode: "add",
                tempNoOfRecords,
              }}
            />
          )}
          {pageMode === "edit" && (
            <AddEditLabels
              props={{
                fields,
                templates,
                setTemplates,
                setPageMode,
                recordIndex,
                tempNoOfRecords,
                setRecordIndex,
                mode: "edit",
              }}
            />
          )}
        </div>
      )}
    </>
  );
}

export default LabelsManagement;
